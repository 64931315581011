import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import Cancelicon from '../../../assets/images/cross.svg';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { Button, SvgIcon, withStyles, Tooltip, Checkbox } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
// import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { generateQueryParamSting } from '../../../utility-functions';
import { Check } from '@material-ui/icons';
import { Spin } from 'antd';
import Loader from '../../../components/loader/loader';
import Edit from '@material-ui/icons/Edit';
// import  '../mapping.css';
const useStyles = makeStyles({
  cardRoots: {
    // marginLeft: 15,
    //  width: '0%',
    //  maxWidth: 0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
    color: ' #ff6b6b',
  },
  pos: {
    marginBottom: 12,
  },
  lastupdate: {
    fontSize: 14,
    color: '#014B7E',
  },
  subjectName: {
    float: 'right',
    color: '#014B7E',
    fontSize: 14,
  },
  gradeSub: {
    marginTop: '10px',
  },
  gradeName: {
    fontSize: 14,
    color: '#014B7E',
  },
  cardContent: {
    // marginTop: '25px'
  },
  gradeName: {
    marginTop: '16px',
  },
});

const StyledButton = withStyles({
  root: {
    height: '40px',
    color: '#FFFFFF',
    backgroundColor: (props) => props.backgroundcolor,
    '&:hover': {
      backgroundColor: '#FF6B6B',
    },
    padding: '1px',
  },
})(Button);

export default function Subjectdeteils(props) {
  const classes = useStyles();
  const { setAlert } = React.useContext(AlertNotificationContext);
  const bull = <span className={classes.bullet}>•</span>;
  const [activeIndex, setActiveIndex] = React.useState();
  const [gradeSubjectList, setGradeSubjectList] = React.useState([]);
  const [activeData, setActiveData] = React.useState([]);
  const [schoolVersionData, setSchoolVersionData] = React.useState([]);
  const [versionButtonClicked, setVersionButtonClicked] = React.useState(false);
  const [isdeleted, setIsDeleted] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    selectedSchoolVerion,
    selectedSchool,
    selectedModule,
    selectedSchoolId,
    moduleKey,
    selectedSchoolObj,
    cardData,
    getVersion,
    setIsSubjectOpen,
  } = props;
  const { viewMoreList, cancelCard, fetchSchools = () => {} } = props;
  const [id, setid] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [schoolToBranchwiseId, setSchoolToBranchwiseId] = useState();
  const [schoolData, setSchoolData] = useState([]);
  const [checkboxes, setCheckboxes] = useState(
    selectedSchoolVerion?.map((each) => each?.is_school_wise || false)
  );

  const defaultIsSchoolWise = schoolData?.find((school) => school?.is_school_wise);

  const handleChange = async (index, each) => {
    const newCheckboxes = checkboxes.map((checked, i) =>
      i === index ? !checked : false
    );
    setCheckboxes(newCheckboxes);
    const { key, value } = selectedModule || {};
    const queryString = generateQueryParamSting({
      school: selectedSchoolId,
      [key]: value,
      version_id: isdeleted ? id : each?.academic_year,
      [moduleKey]: true,
    });
    const apiURL = `${endpoints.mapping.schoolList}?${queryString}`;
    const response = await axiosInstance.get(apiURL);
    if (response?.data?.status_code === 200) {
      const schoolToBranchId = response?.data?.result[0]?.id;
      setSchoolToBranchwiseId(schoolToBranchId);
    }
  };

  const handleSave = async () => {
    setIsEdit(false);
    const isAnyCheckboxChecked = checkboxes.some((isChecked) => isChecked);

    if (isAnyCheckboxChecked) {
      if (defaultIsSchoolWise !== undefined && schoolToBranchwiseId) {
        if (defaultIsSchoolWise?.id !== schoolToBranchwiseId) {
          const SchoolWisePayload = {
            is_school_wise: true,
          };
          const branchWisePayload = { is_school_wise: false };

          const schoolWiseResponse = await axiosInstance.put(
            `/lesson_plan/update-school-mapping/${schoolToBranchwiseId}/`,
            SchoolWisePayload
          );
          if (schoolWiseResponse?.data?.status_code === 200) {
            setPageLoading(false);
            setAlert('success', schoolWiseResponse?.data?.message);
          } else {
            setAlert('error', 'Failed to update school mapping');
          }

          const branchWiseResponse = await axiosInstance.put(
            `/lesson_plan/update-school-mapping/${defaultIsSchoolWise?.id}/`,
            branchWisePayload
          );
          if (branchWiseResponse?.data?.status_code === 200) {
            setPageLoading(false);
            setAlert('success', branchWiseResponse?.data?.message);
          } else {
            setAlert('error', 'Failed to update school mapping');
          }
          getVersion();
          setIsSubjectOpen(false);
        } else {
          setAlert('error', 'Selected version is already in School Wise version');
        }
      } else if (defaultIsSchoolWise === undefined && schoolToBranchwiseId) {
        const SchoolWisePayload = {
          is_school_wise: true,
        };

        const schoolWiseResponse = await axiosInstance.put(
          `/lesson_plan/update-school-mapping/${schoolToBranchwiseId}/`,
          SchoolWisePayload
        );
        if (schoolWiseResponse?.data?.status_code === 200) {
          setPageLoading(false);
          getVersion();
          setIsSubjectOpen(false);
          setAlert('success', schoolWiseResponse?.data?.message);
        } else {
          setAlert('error', 'Failed to update school mapping');
        }
      } else {
        setAlert('error', 'Selected version is already in School Wise version');
      }
    } else {
      setAlert('error', 'Please select anyone of the checkboxes below');
      setCheckboxes(selectedSchoolVerion?.map((each) => each?.is_school_wise || false));
    }
  };

  const fetchDetails = async (each) => {
    setLoading(true);
    const { id: schoolId } = selectedSchool || {};
    const { key, value } = selectedModule || {};
    const queryString = generateQueryParamSting({
      school: selectedSchoolId,
      [key]: value,
      version_id: isdeleted ? id : each?.academic_year,
      [moduleKey]: true,
    });
    const apiURL = `${endpoints.mapping.schoolList}?${queryString}`;
    await axiosInstance
      .get(apiURL)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          setLoading(false);
          setGradeSubjectList(res?.data?.result);
          setActiveData(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setid(selectedSchoolVerion[0]?.academic_year);
    setActiveIndex();
    setCheckboxes(selectedSchoolVerion?.map((each) => each?.is_school_wise || false));
    setSchoolToBranchwiseId();
    setIsEdit(false);
  }, [selectedSchoolVerion]);

  useEffect(() => {
    if (
      gradeSubjectList &&
      gradeSubjectList.length > 0 &&
      selectedSchoolVerion.length > 0 &&
      id
    ) {
      setActiveData([]);
    }
  }, [selectedSchoolVerion, id, cardData]);

  useEffect(() => {
    if (isdeleted) {
      fetchDetails();
      setVersionButtonClicked(true);
      setIsDeleted(false);
    }
  }, [isdeleted]);

  useEffect(() => {
    setLoading(true);
    const { key, value } = selectedModule || {};
    const queryString = generateQueryParamSting({
      school: selectedSchoolId,
      [key]: value,
      [moduleKey]: true,
    });
    const apiURL = `${endpoints.mapping.schoolList}?${queryString}`;
    axiosInstance
      .get(apiURL)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          setLoading(false);
          setSchoolData(res?.data?.result);
          setSchoolVersionData(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cardData, selectedSchoolObj, isdeleted]);

  const deleteSubject = (schoolMappiingId, subjectId, versionId) => {
    const queryString = generateQueryParamSting({
      instance_id: schoolMappiingId,
      subject_id: String(subjectId),
    });

    axiosInstance
      .delete(`${endpoints.mapping.deleteSubjectV2}?${queryString}`)
      .then((res) => {
        if (res.data.status_code >= 200 && res.data.status_code <= 299) {
          setIsDeleted(true);
          setAlert('success', 'Deleted Succesfully');
        } else {
          setAlert(
            'error',
            `${res.data.description || res.data.message || 'Failed to delete subject.'}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert('error', `${err.message}`);
      });
  };

  const handleVersionwiseGrade = (each, index) => {
    fetchDetails(each);
    setActiveIndex(index);
    setVersionButtonClicked(true);
  };

  const handleToggleEdit = () => {
    setIsEdit((prevIsEdit) => !prevIsEdit);
  };

  useEffect(() => {
    setVersionButtonClicked(false);
  }, [selectedSchoolVerion]);

  return (
    <>
      {pageLoading ? <Loader message='Loading...' /> : null}
      <Card variant='outlined' className={classes.cardRoots}>
        <div className='cancel-icon' style={{ float: 'right', cursor: 'pointer' }}>
          <SvgIcon
            component={() => (
              <img
                style={{ width: '25px', margin: 10 }}
                src={Cancelicon}
                alt='given'
                onClick={cancelCard}
              />
            )}
          />
        </div>
        <CardContent className={classes.cardContent}>
          <div
            className='card-header'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E2E2E2',
            }}
          >
            <Typography className={classes.title} gutterBottom>
              {selectedSchoolObj?.school_name}
            </Typography>
            <div className='card-last-update'>
              <Typography
                className={classes.lastupdate}
                variant='p'
                component='p'
                color='secondary'
              >
                Last Updated On
                <Typography className={classes.lastupdate}>
                  {moment(selectedSchoolObj?.last_update).format('MMM DD YYYY')}
                </Typography>
              </Typography>
            </div>
          </div>
        </CardContent>
        {selectedSchoolVerion.length > 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                minHeight: '20x',
              }}
            >
              <StyledButton
                startIcon={<Edit />}
                onClick={handleToggleEdit}
                style={{
                  fontSize: 12,
                  width: '90px',
                  height: '30px',
                  backgroundColor: 'blue',
                }}
              >
                Edit
              </StyledButton>
              {isEdit ? (
                <StyledButton
                  style={{
                    height: '30px',
                    width: '90px',
                    fontSize: 12,
                    backgroundColor: 'blue',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                  onClick={handleSave}
                >
                  Save
                </StyledButton>
              ) : (
                ''
              )}
            </CardContent>
          </div>
        ) : null}
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          {selectedSchoolVerion
            ?.slice()
            ?.sort((a, b) => a.academic_year - b.academic_year)
            ?.map((each, index) => (
              <div key={index} style={{ marginBottom: isEdit ? '' : '5px' }}>
                {isEdit ? (
                  <Checkbox
                    checked={checkboxes[index]}
                    onChange={() => handleChange(index, each)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                ) : (
                  ''
                )}
                <Tooltip title={`Click to view the data`} arrow>
                  <StyledButton
                    style={{
                      height: '30px',
                      width: '110px',
                      fontSize: 12,
                      backgroundColor: activeIndex === index ? '#FF6B6B' : 'blue',
                      color: 'white',
                    }}
                    onClick={() => {
                      handleVersionwiseGrade(each, index);
                      setid(each?.academic_year);
                    }}
                  >
                    {each?.version_name}{' '}
                    {each?.is_school_wise && <Check style={{ fontSize: 15 }} />}
                  </StyledButton>
                </Tooltip>
              </div>
            ))}
          {loading ? (
            <div className='th-width-100 text-center mt-5 h-100'>
              <Spin tip='Loading...'></Spin>
            </div>
          ) : null}
        </CardContent>
        <div className='card-body'>
          <CardContent>
            {selectedSchoolVerion?.length > 0 ? (
              activeData?.grade_subject_mapping?.length < 1 && versionButtonClicked ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>No data</Typography>
                </div>
              ) : (
                activeData?.grade_subject_mapping?.map((subAndGrade, index) => (
                  <div style={{ borderBottom: '0.25px solid #E2E2E2' }} key={index}>
                    <div
                      className='sub-name-icon'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography component={'h5'}>
                        {subAndGrade?.grade?.grade_name}
                      </Typography>
                      <Typography component={'p'}>
                        {subAndGrade?.subject?.subject_name}
                      </Typography>
                      <IconButton
                        title='Delete'
                        onClick={() => {
                          deleteSubject(activeData?.id, subAndGrade?.id, id);
                        }}
                        size='small'
                        className={classes.subjectName}
                      >
                        <DeleteOutlinedIcon style={{ color: '#ff6b6b' }} />
                      </IconButton>
                    </div>
                  </div>
                ))
              )
            ) : schoolVersionData?.grade_subject_mapping?.length > 0 ? (
              schoolVersionData?.grade_subject_mapping?.map((subAndGrade, index) => (
                <div style={{ borderBottom: '0.25px solid #E2E2E2' }} key={index}>
                  <div
                    className='sub-name-icon'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography component={'h5'}>
                      {subAndGrade?.grade?.grade_name}
                    </Typography>
                    <Typography component={'p'}>
                      {subAndGrade?.subject?.subject_name}
                    </Typography>
                    <IconButton
                      title='Delete'
                      onClick={() => {
                        deleteSubject(schoolVersionData?.id, subAndGrade?.id);
                      }}
                      size='small'
                      className={classes.subjectName}
                    >
                      <DeleteOutlinedIcon style={{ color: '#ff6b6b' }} />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>No data</Typography>
              </div>
            )}
          </CardContent>
        </div>
      </Card>
    </>
  );
}
