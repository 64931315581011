import {
  Divider,
  Grid,
  Radio,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../style';
import React, { useEffect, useState } from 'react';
import OrchidsLogo from '../../../../assets/images/orchidsLogo1.png';
import moment from 'moment';
import DoughnutChart from './doughnut';
import { getArrayValues } from '../../../../utility-functions';
import { constantValue } from '../../.././../getConstant';
import QuestionPreview from './questionPreview';
import QuestionPreview_v2 from './questionPreview_v2';

const Preview = ({
  classes,
  templateFrom,
  currentStep,
  setQuestionPreviewModalOpen,
  dataList,
  isQuestionPaper,
}) => {
  const [graphValues, setGraphValues] = useState({});
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    console.log({ templateFrom, dataList, graphValues, showGraph }, 'from preview');
    // const checkAllFields = templateFrom?.section.some((sec) =>
    //   sec?.question.some((q) => q.bloom || q.difficult || q.type)
    // );
    const checkAllFields = templateFrom?.section.every(
      (section) =>
        section.question &&
        section.question.every(
          (question) => question.bloom && question.difficult && question.type
        )
    );

    const hasQuestions = templateFrom?.section.some(
      (section) => section?.question?.length > 0
    );

    if (checkAllFields && hasQuestions) {
      calculateGraphValues();
      setShowGraph(true);
    } else {
      setShowGraph(false);
    }
  }, [templateFrom, dataList]);

  useEffect(() => {
    console.log(
      { graphValues, templateFrom, currentStep, setQuestionPreviewModalOpen, dataList },
      'alllog preview'
    );
  });

  const calculateGraphValues = () => {
    let bloomCounts = dataList?.bloomList.reduce((acc, type) => {
      acc[type.category_name] = 0;
      return acc;
    }, {});
    let difficultCounts = dataList?.difficultyList.reduce((acc, difficult) => {
      acc[difficult.level_name] = 0;
      return acc;
    }, {});
    let questionTypeCounts = dataList?.typeList.reduce((acc, type) => {
      acc[type.type_name] = 0;
      return acc;
    }, {});
    templateFrom.section.forEach((section) => {
      section.question.forEach((question) => {
        if (question.bloom) {
          bloomCounts[question.bloom.category_name] += 1;
        }
        if (question.difficult) {
          difficultCounts[question.difficult.level_name] += 1;
        }
        if (question.type) {
          questionTypeCounts[question.type.type_name] += 1;
        }
      });
    });
    setGraphValues({ bloomCounts, difficultCounts, questionTypeCounts });
  };

  return (
    <>
      <Grid item sm={12}>
        {showGraph ? (
          <DoughnutChart
            bloomCounts={graphValues?.bloomCounts}
            difficultCounts={graphValues?.difficultCounts}
            questionTypeCounts={graphValues?.questionTypeCounts}
          />
        ) : (
          <></>
        )}
        <Grid
          item
          sm={12}
          className={classes.questionPreviewTableStyle}
          type='button'
          onClick={() => setQuestionPreviewModalOpen(true)}
        >
          {/* <QuestionPreview
            templateFrom={templateFrom}
            currentStep={currentStep}
            isQuestionPaper={isQuestionPaper}
          /> */}
          <QuestionPreview_v2
            templateFrom={templateFrom}
            currentStep={currentStep}
            isQuestionPaper={isQuestionPaper}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(Preview);
