import React, { useEffect, useState } from 'react';
import Layout from '../../../containers/Layout';
import { Breadcrumb, Empty, message, Pagination, Spin, Tabs } from 'antd';
import ClickerQuestionFilters from './filter';
import CreateEditQuestion from './createEditQuestion';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import Question from './question';

const { TabPane } = Tabs;

const ClickerQuestion = () => {
  const [createQuestionmodalData, setCreateQuestionmodalData] = useState({
    show: false,
    edit: {
      id: null,
      show: false,
      question_status: null,
    },
    data: null,
  });
  const [questionStatus, setQuestionStatus] = useState('0');
  const [questionList, setQuestionList] = useState([]);
  const [questionFilter, setQuestionFilter] = useState({});
  const [questionFiltered, setQuestionFiltered] = useState(false);
  const [questionFilterLoading, setQuestionFilterLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [fetchQuestionListFlag, setFetchQuestionListFlag] = useState(false);
  const pageLimit = 15;

  const fetchQuestionList = (params = {}) => {
    params = {
      academic_year: params?.academicYear?.id || questionFilter?.academicYear?.id || null,
      grade: params?.grade?.id || questionFilter?.grade?.id || null,
      subject:
        params?.subject?.subject?.id || questionFilter?.subject?.subject?.id || null,
      board: params?.board || questionFilter?.board || null,
      volume: params?.volume?.id || questionFilter?.volume?.id || null,
      module: params?.module?.id || questionFilter?.module?.id || null,
      chapter: params?.chapter?.id || questionFilter?.chapter?.id || null,
      key_concept: params?.keyConcept?.id || questionFilter?.keyConcept?.id || null,
      question_type: params?.questionType?.id || questionFilter?.questionType?.id || null,
      question_status:
        params?.question_status || questionStatus !== '0' ? questionStatus : null || null,
      page: params?.page || pageNo,
    };

    if (!params?.academic_year) {
      message.error('Please select academic year.');
      return;
    }

    if (!params?.grade) {
      message.error('Please select grade.');
      return;
    }

    if (!params?.subject) {
      message.error('Please select subject.');
      return;
    }

    setQuestionFilterLoading(true);
    axiosInstance
      .get(`${endpoints.clickerQuestion.clickerQuestions}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setQuestionList(result?.data?.result?.results);
          setPageNo(result?.data?.result?.current_page);
          setTotalPage(result?.data?.result?.count);
          setFetchQuestionListFlag(false);
          setQuestionFiltered(true);
        } else {
          setQuestionFiltered(false);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong');
      })
      .finally(() => {
        setQuestionFilterLoading(false);
      });
  };

  useEffect(() => {
    if (questionFilter?.academicYear) {
      fetchQuestionList({
        ...questionFilter,
        question_status: questionStatus === '0' ? null : parseInt(questionStatus),
        page: 1,
      });
    }
  }, [questionStatus]);

  useEffect(() => {
    if (fetchQuestionListFlag) {
      fetchQuestionList();
    }
  }, [fetchQuestionListFlag]);

  const questionData = (
    <div>
      <Spin tip='Loading...' size='large' spinning={questionFilterLoading}>
        <>
          {Array.isArray(questionList) && questionList.length > 0 ? (
            questionList?.map((item, index) => {
              return (
                <Question
                  createQuestionmodalData={createQuestionmodalData}
                  setCreateQuestionmodalData={setCreateQuestionmodalData}
                  currentQuestion={item}
                  setFetchQuestionListFlag={setFetchQuestionListFlag}
                  index={index}
                  currentPage={pageNo}
                  key={index}
                />
              );
            })
          ) : (
            <div
              className='row justify-content-center align-items-center'
              style={{ height: 300 }}
            >
              <div className='col-12 text-center'>
                <Spin
                  tip='Loading...'
                  size='large'
                  spinning={questionFilterLoading}
                ></Spin>
                {questionFilterLoading ? null : (
                  <Empty description={'No question found'} />
                )}
              </div>
            </div>
          )}

          {Array.isArray(questionList) && questionList.length > 0 && (
            <div className='row justify-content-center'>
              <div className='col-12'>
                <div className='text-center'>
                  <Pagination
                    current={pageNo}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    pageSize={pageLimit}
                    total={totalPage}
                    style={{ justifyContent: 'center' }}
                    onChange={(currentPage) => {
                      setPageNo(currentPage);
                      fetchQuestionList({
                        ...questionFilter,
                        page: currentPage,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </Spin>
    </div>
  );
  return (
    <React.Fragment>
      <Layout>
        {/* Breadcrumb */}
        <div className='row pb-3'>
          <div className='col-md-9' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-grey-1 th-16'>
                Clicker Question
              </Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>
                Question Bank
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className='row px-3'>
          <div className='col-md-12 th-bg-white th-br-8 px-1'>
            <div className='th-br-5 '>
              <ClickerQuestionFilters
                createQuestionmodalData={createQuestionmodalData}
                setCreateQuestionmodalData={setCreateQuestionmodalData}
                onFilterQuestionList={fetchQuestionList}
                questionFilterLoading={questionFilterLoading}
                questionFilter={questionFilter}
                setQuestionFilter={setQuestionFilter}
                isFilter={true}
              />
            </div>

            <div className='th-br-5 p-2'>
              {questionFiltered ? (
                <div className=' px-1 th-br-8 th-tabs mt-0'>
                  <Tabs onChange={(e) => setQuestionStatus(e)} type='card'>
                    <TabPane tab='All' key='0'>
                      {questionData}
                    </TabPane>
                    <TabPane tab='Draft' key='1'>
                      {questionData}
                    </TabPane>
                    <TabPane tab='For Review' key='2'>
                      {questionData}
                    </TabPane>
                    <TabPane tab='Published' key='3'>
                      {questionData}
                    </TabPane>
                  </Tabs>
                </div>
              ) : (
                <div
                  className='row justify-content-center align-items-center'
                  style={{ height: 300 }}
                >
                  <div className='col-12 text-center'>
                    <Spin
                      tip='Loading...'
                      size='large'
                      spinning={questionFilterLoading}
                    ></Spin>
                    {questionFilterLoading ? null : (
                      <Empty description={'Please apply filter to view question.'} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {createQuestionmodalData?.show && (
          <CreateEditQuestion
            createQuestionmodalData={createQuestionmodalData}
            setCreateQuestionmodalData={setCreateQuestionmodalData}
            fetchQuestionList={fetchQuestionList}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};

export default ClickerQuestion;
