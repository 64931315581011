import * as React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Typography, Box, Grid, Divider, Tooltip } from '@material-ui/core';
import './doughnut.css';

export default function DoughnutChart({
  bloomCounts,
  difficultCounts,
  questionTypeCounts,
}) {
  const updatedBloomCounts = {};
  const updatedQuestionTypeCounts = {};
  const updatedDifficultCounts = {};

  const bloomLabels = Object.keys(bloomCounts);
  const bloomData = bloomLabels
    .map((label) => bloomCounts[label])
    .filter((value) => value !== 0);
  const bloomsLegend = [];

  Object.keys(bloomCounts).forEach((data, index) => {
    if (bloomCounts[data] > 0) {
      bloomsLegend.push(data);
    }
  });

  const difficultLabels = Object.keys(difficultCounts);
  const difficultData = difficultLabels.map((label) => difficultCounts[label]);
  const filteredDifficultData = difficultData.filter((val) => val !== 0);

  const totalQuestions = Object.values(questionTypeCounts).reduce(
    (total, count) => total + count,
    0
  );

  const bloomBackgroundColor = [
    '#00216A',
    '#0E4297',
    '#0547E1',
    '#3772FB',
    '#6995FC',
    '#8DB9FF',
    '#9BB9FD',
    '#0767DB',
  ];

  const questionTypeBackgroundColors = [
    '#741112',
    '#A21719',
    '#E82124',
    '#ED4D50',
    '#F49092',
  ];

  const difficultBackgroundColors = ['#A545B5', '#C462D3', '#E37FF2', '#FF9CFF'];

  const bloomKeys = Object.keys(bloomCounts);
  for (let i = 0; i < bloomKeys.length; i++) {
    const key = bloomKeys[i];
    const count = bloomCounts[key];
    const color = bloomBackgroundColor[i];
    updatedBloomCounts[key] = {
      count: count,
      color: color,
    };
  }

  const questionTypeCountskeys = Object.keys(questionTypeCounts);
  for (let i = 0; i < questionTypeCountskeys.length; i++) {
    const key = questionTypeCountskeys[i];
    const count = questionTypeCounts[key];
    const color = questionTypeBackgroundColors[i];
    updatedQuestionTypeCounts[key] = {
      count: count,
      color: color,
    };
  }

  const difficultCountskeys = Object.keys(difficultCounts);
  for (let i = 0; i < difficultCountskeys.length; i++) {
    const key = difficultCountskeys[i];
    const count = difficultCounts[key];
    const color = difficultBackgroundColors[i];
    updatedDifficultCounts[key] = {
      count: count,
      color: color,
    };
  }

  const filteredBloomCounts = Object.fromEntries(
    Object.entries(updatedBloomCounts).filter(([key, value]) => value.count !== 0)
  );

  const bloom = {
    labels: bloomsLegend,
    datasets: [
      {
        label: 'Count',
        data: bloomData,
        backgroundColor: Object.values(filteredBloomCounts).map((item) => item.color),
        borderColor: Object.values(filteredBloomCounts).map((item) => item.color),
      },
    ],
  };

  console.log(bloomsLegend, bloomData, filteredBloomCounts, 'Bloom');

  const difficult = {
    labels: difficultLabels,
    datasets: [
      {
        label: 'count',
        data: filteredDifficultData,
        backgroundColor: Object.values(updatedDifficultCounts).map((item) => item.color),
        borderColor: Object.values(updatedDifficultCounts).map((item) => item.color),
        barThickness: 10,
        borderRadius: 50,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          usePointStyle: true, // This will make legend items use the same style as the chart segments
        },
        datalabels: {
          color: 'black',
          anchor: 'end', // Position of the data value on the segment
          align: 'start', // Alignment of the data value within the segment
          formatter: (value, ctx) => {
            const dataset = ctx.chart.data.labels[ctx.dataIndex];
            const total = dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            console.log('value:', value);
            console.log('total:', total);
            console.log('percentage:', percentage);

            return percentage + '%';
          },
        },
      },
    },
    spacing: 10,
    borderRadius: 3,
  };

  const difficultyOptions = {
    indexAxis: 'y', // Set the index axis to 'y' for horizontal bar chart
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          usePointStyle: true, // This will make legend items use the same style as the chart segments
        },
        datalabels: {
          color: 'black',
          anchor: 'end',
          align: 'start',
          font: {
            weight: 'bold',
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
      },
      y: {
        display: false,
        grid: {
          display: false, // Hide the y-axis grid lines
        },
      },
    },
    barThickness: 15,
    maxBarThickness: 20,
  };

  let totalBloomPercentage = 0; // Initialize total percentage.
  const totalCountBloom = Object.values(filteredBloomCounts).reduce(
    (acc, value) => acc + value.count,
    0
  );

  let percentage;

  const percentageList = Object.keys(filteredBloomCounts).map((level, index) => {
    const { count, color } = filteredBloomCounts[level];
    percentage = (count / totalCountBloom) * 100;

    // Ensure individual percentages are rounded to 2 decimal places.
    percentage = parseFloat(percentage.toFixed(2));

    totalBloomPercentage += percentage; // Update the total percentage.

    return { level, percentage, color };
  });

  if (percentageList.length > 0) {
    const lastPercentageIndex = percentageList.length - 1;
    percentageList[lastPercentageIndex].percentage += 100 - totalBloomPercentage;
  }

  // Difficulty Calculations

  const totalCountDifficulty = Object.values(updatedDifficultCounts).reduce(
    (acc, value) => acc + value.count,
    0
  );
  let totalDifficultyPercentage = 0;

  const difficultyCalculation = Object.keys(updatedDifficultCounts)?.map(
    (level, index) => {
      const { count, color } = updatedDifficultCounts[level];

      let percentage = (count / totalCountDifficulty) * 100;
      percentage = parseFloat(percentage.toFixed(2));
      totalDifficultyPercentage += percentage;

      return { level, percentage, color };
    }
  );
  const lastDifficultyIndex = difficultyCalculation.length - 1;
  difficultyCalculation[lastDifficultyIndex].percentage +=
    100 - totalDifficultyPercentage;

  return (
    <Box>
      {/* Blooms Level */}

      <Grid item lg={12} style={{ marginTop: '1%', marginBottom: '2%' }}>
        <Typography className='font-size-14 font-weight-600'>Blooms Level</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '0%',
        }}
      >
        <Grid
          item
          lg={4}
          md={5}
          sm={5}
          xs={5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1%',
          }}
        >
          <Doughnut
            data={bloom}
            options={options}
            style={{ width: '40%', height: '40%' }}
          />
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={7}>
          {percentageList.map(
            ({ level, percentage, color }, index) =>
              percentage > 0 && (
                <div key={index}>
                  <Typography
                    style={{
                      margin: '2px 0',
                      color: color,
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    {level}: {percentage.toFixed(2)}%
                  </Typography>
                </div>
              )
          )}
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '2%' }} />

      {/* Question Type */}

      <Grid item lg={12}>
        <Typography className='font-size-14 font-weight-600'>Question Type</Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div style={{ display: 'flex', alignItems: 'center', margin: '2% 0 0' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
              className='questionTypeCounts'
            >
              {Object.keys(updatedQuestionTypeCounts)
                .filter((level) => updatedQuestionTypeCounts[level].count > 0)
                .map((level, index) => {
                  const bgColor = updatedQuestionTypeCounts[level].color;
                  const count = updatedQuestionTypeCounts[level].count;
                  const total = Object.values(updatedQuestionTypeCounts).reduce(
                    (acc, value) => acc + value.count,
                    0
                  );
                  let percentage = (count / total) * 100;

                  // Ensure individual percentages are rounded to 2 decimal places.
                  percentage = parseFloat(percentage.toFixed(2));

                  return (
                    <div
                      key={level}
                      style={{
                        backgroundColor: bgColor,
                        height: '20px',
                        width: `${(count / totalQuestions) * 100}%`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // padding: '5px',
                        color: 'white',
                        fontWeight: 500,
                        ...(index === 0 ? { borderRadius: '20px 0 0 20px' } : {}),
                        ...(Object.keys(updatedQuestionTypeCounts).filter(
                          (level) => updatedQuestionTypeCounts[level].count > 0
                        ).length ===
                        index + 1
                          ? { borderRadius: '0 20px 20px 0' }
                          : {}),
                      }}
                      className='QuestionTypeBar'
                    >
                      {percentage.toFixed(1) >= 8 ? (
                        `${percentage.toFixed(1)}%`
                      ) : percentage.toFixed(1) < 2 ? (
                        <Tooltip
                          title={`${level} : ${percentage.toFixed(1)}%`}
                          arrow
                          placement='top'
                        >
                          <span>...</span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={`${percentage.toFixed(1)}%`}
                          arrow
                          placement='top'
                        >
                          <span>...</span>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {Object.keys(updatedQuestionTypeCounts).map((level, index) => {
            const { count, color } = updatedQuestionTypeCounts[level];
            const total = Object.values(updatedQuestionTypeCounts).reduce(
              (acc, value) => acc + value.count,
              0
            );
            let percentage = (count / total) * 100;

            if (count > 0) {
              return (
                <div
                  key={index}
                  style={{
                    width: `${(count / totalQuestions) * 100}%`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      color: color,
                      fontWeight: 500,
                      fontSize: '10px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {percentage.toFixed(1) > 2 ? level : ''}
                  </Typography>
                </div>
              );
            }

            return null;
          })}
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />

      {/* Difficulty level */}

      <Grid item lg={12}>
        <Typography className='font-size-14 font-weight-600'>Difficulty Level</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid
          item
          lg={7}
          md={8}
          sm={8}
          xs={6}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Bar
            data={difficult}
            options={difficultyOptions}
            style={{
              width: '100%',
              maxHeight:
                difficult?.labels?.length === 1
                  ? 30
                  : difficult?.labels?.length === 2
                  ? 50
                  : 70,
            }}
          />
        </Grid>
        <Grid item lg={5} md={4} sm={4} xs={6} style={{ marginBottom: '1%' }}>
          {difficultyCalculation?.map(
            ({ level, color, percentage }, index) =>
              percentage > 0 && (
                <div key={index}>
                  <Typography
                    style={{
                      margin: '2px 0',
                      color: color,
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    {level}: {percentage.toFixed(2)}%
                  </Typography>
                </div>
              )
          )}
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '2%' }} />
    </Box>
  );
}
