import React, { useEffect, useState } from 'react';
import { Button, Form, Select, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const QuestionPaperFilter = ({
  isFilter,
  questionFilter,
  setQuestionFilter,
  questionFilterLoading,
  fetchQuestionPaperList,
  createQuestionPaperModalData,
  setCreateQuestionPaperModalData,
}) => {
  const [filteredData, setFilteredData] = useState({
    academicYear: null,
    grade: null,
    subject: null,
  });
  const [academicYearList, setAcademicYearList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const history = useHistory();

  //Default
  useEffect(() => {
    fetchAcademicYearList();
    fetchGradeData();
  }, []);

  //Set filter at index.js
  useEffect(() => {
    if (isFilter) {
      setQuestionFilter({
        ...questionFilter,
        ...filteredData,
      });
    }
  }, [filteredData]);

  //Subject
  useEffect(() => {
    if (filteredData?.grade) {
      fetchSubjectData({
        grade: filteredData?.grade?.id,
      });
    }
  }, [filteredData?.grade]);

  //Academic Year List

  const fetchAcademicYearList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setAcademicYearList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Grade List
  const fetchGradeData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setGradeList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Subject List
  const fetchSubjectData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          setSubjectList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // handle Filter
  const handleFilterChange = (keyname, value) => {

    if (keyname === 'academicYear') {
      setFilteredData({
        ...filteredData,
        academicYear: value ? value.optionsData : null,
        grade: null,
        subject: null,
      });
      return;
    }
    if (keyname === 'grade') {
      setFilteredData({
        ...filteredData,
        grade: value ? value.optionsData : null,
        subject: null,
      });
      return;
    }
    if (keyname === 'subject') {
      setFilteredData({
        ...filteredData,
        subject: value ? value.optionsData : null,
      });
      return;
    }
    setFilteredData({ ...filteredData, [keyname]: value?.optionsData || value });
  };

  const academicYearOptions = academicYearList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.session_year}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.grade_name}
      </Option>
    );
  });

  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.subject?.subject_name}
      </Option>
    );
  });
  return (
    <>
      <Form layout='vertical' className='th-clicker-form py-2'>
        <div className='row align-items-center'>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='AcademicYear' className='' title='Academic Year*'>
              Academic Year *
            </label>
            <Select
              showSearch
              placeholder='Academic Year*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              //   disabled={
              //     !isFilter &&
              //     createQuestionmodalData?.edit?.show &&
              //     createQuestionmodalData?.edit?.id
              //   }
              value={filteredData?.academicYear?.id}
              onChange={(e, row) => handleFilterChange('academicYear', row)}
            >
              {academicYearOptions}
            </Select>
          </div>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='grade' className='' title='Grade*'>
              Grade *
            </label>
            <Select
              showSearch
              placeholder='Select Grade*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              //   disabled={
              //     !isFilter &&
              //     createQuestionmodalData?.edit?.show &&
              //     createQuestionmodalData?.edit?.id
              //   }
              value={filteredData?.grade?.id ?? null}
              onChange={(e, row) => handleFilterChange('grade', row)}
            >
              {gradeOptions}
            </Select>
          </div>
          <div className='col-md-2 col-6 mb-2'>
            <label htmlFor='Subject' className='' title='Subject*'>
              Subject *
            </label>
            <Select
              showSearch
              placeholder='Select Subject*'
              optionFilterProp='children'
              className='w-100'
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              dropdownMatchSelectWidth={false}
              filterOption={(input, options) => {
                return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              //   disabled={
              //     !isFilter &&
              //     createQuestionmodalData?.edit?.show &&
              //     createQuestionmodalData?.edit?.id
              //   }
              value={filteredData?.subject?.id}
              onChange={(e, row) => handleFilterChange('subject', row)}
            >
              {subjectOptions}
            </Select>
          </div>
          <div className='col-md-2 col-6 mt-3'>
            <Button
              // ghost
              // type='default'
              className='th-br-4 w-100 mt-1 th-button'
              loading={questionFilterLoading}
              onClick={() => fetchQuestionPaperList({ ...questionFilter, page: 1 })}
            >
              Filter
            </Button>
          </div>
          <div className='col-md-3 col-6 mt-3'>
            <Button
              type='primary'
              className='th-br-4 w-100 mt-1 float-right'
              icon={<PlusCircleOutlined />}
              onClick={() =>
                // setCreateQuestionPaperModalData({
                //   ...createQuestionPaperModalData,
                //   show: true,
                //   data: null,
                //   edit: {
                //     id: null,
                //     show: false,
                //     question_status: null,
                //   },
                // })
                history.push('/clicker-question/create-question-paper')
              }
            >
              Create Question Paper
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default QuestionPaperFilter;
