import { Card } from 'antd';
import cuid from 'cuid';
import React, { useState } from 'react';
import MyTinyEditor from './../tiny-mce-new/index';
import ReactQuillEditor from '../../../../../components/reactQuill';

const Descriptive = ({
  questionData,
  questionList,
  parentIndex,
  setQuestions,
  comptype,
  childIndex,
}) => {
  const [openEditor, setOpenEditor] = useState(true);
  const [answer, setAnswer] = useState('');

  const handleEditorChange = (content, delta, source, editor) => {
    setAnswer(content);
    handleOptionUpdate(content);
  };

  const handleOptionUpdate = (answer) => {
    let list = [...questionList];

    if (comptype == 'comp') {
      let subQuestions = list[parentIndex].subquestions[childIndex];
      subQuestions.question_answer[0]['answer'] = answer;
      list[parentIndex].subquestions[childIndex] = subQuestions;
      setQuestions(list);
    } else {
      list[parentIndex].question_answer[0]['answer'] = answer;
      setQuestions(list);
    }
  };

  const handleContent = () => {
    let list = [...questionList];
    if (comptype === 'comp') {
      let subQuestions = list[parentIndex].subquestions[childIndex];
      let checkAnswer = subQuestions.question_answer[0]['answer']
        ? subQuestions.question_answer[0]['answer']
        : '';
      return checkAnswer;
    } else {
      return answer;
    }
  };

  return (
    <div>
      {/* <Card bordered={true} className='options-card' style={{ width: '100%' }}> */}
      {/* <div className='row'> */}
      <div className='mt-3'>
        {openEditor && (
          // <MyTinyEditor
          //   id={`answerEditor${cuid()}`}
          //   content={handleContent()}
          //   handleEditorChange={handleEditorChange}
          //   setOpenEditor={setOpenEditor}
          //   placeholder='Answer goes here...'
          // />
          <div className='py-2 w-100 font-weight-normal'>
            <ReactQuillEditor
              value={handleContent()}
              onChange={handleEditorChange}
              placeholder='Answer goes here...'
            />
          </div>
        )}
      </div>
      {/*  </div> */}
      {/* </Card> */}
    </div>
  );
};

export default Descriptive;
