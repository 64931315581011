import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Drawer,
  Row,
  Space,
  Select,
  message,
  Input,
  Breadcrumb,
  Spin,
  Pagination,
  Checkbox,
  Popconfirm,
  Empty,
  Tooltip,
} from 'antd';
import axiosInstance from '../../../../config/axios';
import endpoints from '../../../../config/endpoints';
import Layout from '../../../Layout';
import Question from '../questions';
import { getArrayValues } from '../../../../utility-functions';
import { DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;
const CreateEditQuestionPaper = ({ match }) => {
  const history = useHistory();
  const [boardId, setBoardId] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [volumeList, setVolumeList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [keyConceptList, setKeyConceptList] = useState([]);
  const [questionTypeList, setQuestionTypeList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [createQuestionPaperData, setCreateQuestionPaperData] = useState({
    edit: {
      id: null,
      question_status: null,
    },
    data: {
      title: null,
      description: null,
      academicYear: null,
      grade: null,
      subject: null,
      questions: [],
    },
  });

  const [addQuestionModalData, setAddQuestionModalData] = useState({
    show: false,
    filters: {
      volume: null,
      module: null,
      chapter: null,
      keyConcept: null,
      questionType: null,
    },
    questionList: null,
    selectectedQuestion: [],
    page: { currentPage: 1, pageSize: 15, totalData: null },
    loading: false,
  });

  const fetchBoardId = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.board}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setBoardId(
            result?.data?.result?.filter((el) => el?.board_name === 'CBSE')?.[0]?.id
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  //Default
  useEffect(() => {
    fetchAcademicYearList();
    fetchGradeData();
    fetchVolumeListData();
    fetchQuestionTypeListData();
    fetchBoardId();
  }, []);

  //Edit
  useEffect(() => {
    if (match?.params?.id) {
      getQuestionPaperDetails(match?.params?.id);
      setIsEdit(true);
    }
  }, []);

  //Subject
  useEffect(() => {
    if (createQuestionPaperData?.data?.grade) {
      fetchSubjectData({
        grade: createQuestionPaperData?.data?.grade?.id,
      });
    }
  }, [createQuestionPaperData?.data?.grade]);

  //Module
  useEffect(() => {
    if (
      createQuestionPaperData?.data?.grade &&
      createQuestionPaperData?.data?.subject &&
      addQuestionModalData?.filters?.volume
    ) {
      fetchModuleListData({
        volume: addQuestionModalData?.filters?.volume?.id,
        grade_subject: createQuestionPaperData?.data?.subject?.id,
        board: boardId,
        academic_year: createQuestionPaperData?.data?.academicYear?.id,
      });
    } else {
      setModuleList([]);
      setChapterList([]);
      setKeyConceptList([]);
    }
  }, [
    createQuestionPaperData?.data?.grade,
    createQuestionPaperData?.data?.subject,
    addQuestionModalData?.filters?.volume,
  ]);

  //chapter
  useEffect(() => {
    if (
      createQuestionPaperData?.data?.grade &&
      createQuestionPaperData?.data?.subject &&
      addQuestionModalData?.filters?.volume &&
      addQuestionModalData?.filters?.module &&
      boardId
    ) {
      fetchChapterListData({
        grade_subject: createQuestionPaperData?.data?.subject?.id,
        volume: addQuestionModalData?.filters?.volume?.id,
        lt_module: addQuestionModalData?.filters?.module?.id,
        board: boardId,
      });
    } else {
      setChapterList([]);
      setKeyConceptList([]);
    }
  }, [
    createQuestionPaperData?.data?.grade,
    createQuestionPaperData?.data?.subject,
    addQuestionModalData?.filters?.volume,
    addQuestionModalData?.filters?.module,
    boardId,
  ]);

  //Key Concept
  useEffect(() => {
    if (
      createQuestionPaperData?.data?.grade &&
      createQuestionPaperData?.data?.subject &&
      addQuestionModalData?.filters?.volume &&
      addQuestionModalData?.filters?.module &&
      addQuestionModalData?.filters?.chapter
    ) {
      fetchKeyConceptListData({
        chapter: addQuestionModalData?.filters?.chapter?.id,
      });
    } else {
      setKeyConceptList([]);
    }
  }, [
    createQuestionPaperData?.data?.grade,
    createQuestionPaperData?.data?.subject,
    addQuestionModalData?.filters?.volume,
    addQuestionModalData?.filters?.module,
    addQuestionModalData?.filters?.chapter,
  ]);

  //Question List
  useEffect(() => {
    if (addQuestionModalData?.show) {
      fetchQuestionList({
        academicYear: createQuestionPaperData?.data?.academicYear,
        grade: createQuestionPaperData?.data?.grade,
        subject: createQuestionPaperData?.data?.subject,
        page: 1,
      });
    }
  }, [addQuestionModalData?.show, addQuestionModalData?.filters]);

  //

  //Academic Year List
  const fetchAcademicYearList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setAcademicYearList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Grade List
  const fetchGradeData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setGradeList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Subject List
  const fetchSubjectData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          setSubjectList(res?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Volume List
  const fetchVolumeListData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`, {
        headers: {
          'x-api-key': 'vikash@12345#1231',
        },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setVolumeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Module List
  const fetchModuleListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setModuleList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Chapter List
  const fetchChapterListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.chapters}`, { params: { ...params } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setChapterList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Key Concept
  const fetchKeyConceptListData = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.keyConceptTableData}`, { params: { ...params } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setKeyConceptList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Question Type
  const fetchQuestionTypeListData = () => {
    axiosInstance
      .get(`${endpoints.clickerQuestion.questionType}`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setQuestionTypeList(res?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Question Paper Details
  const getQuestionPaperDetails = (paperId) => {
    axiosInstance
      .get(endpoints.clickerQuestion.clickerQuestionsPaper, {
        params: { qp_id: paperId },
      })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          setCreateQuestionPaperData({
            edit: {
              id: paperId,
              question_status: res?.data?.result?.results[0]?.paper_status,
            },
            data: {
              title: res?.data?.result?.results[0]?.title,
              description: res?.data?.result?.results[0]?.description,
              academicYear: res?.data?.result?.results[0]?.academic_year[0],
              grade: res?.data?.result?.results[0]?.grade[0],
              subject: {
                id: res?.data?.result?.results[0]?.subject[0].id,
                subject: {
                  id: res?.data?.result?.results[0]?.subject[0].subject_id,
                  subject_name: res?.data?.result?.results[0]?.subject[0]?.subject_name,
                },
              },
              questions: res?.data?.result?.results[0]?.questions,
            },
          });
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || 'Something went wrong');
      });
  };

  //Question List
  const fetchQuestionList = (params = {}) => {
    setAddQuestionModalData((prevState) => ({ ...prevState, loading: true }));
    params = {
      academic_year:
        params?.academicYear?.id || createQuestionPaperData?.academicYear?.id || null,
      grade: params?.grade?.id || createQuestionPaperData?.grade?.id || null,
      subject:
        params?.subject?.subject?.id ||
        createQuestionPaperData?.subject?.subject?.id ||
        null,
      board: params?.board || boardId || null,
      volume: params?.volume?.id || addQuestionModalData?.filters?.volume?.id || null,
      module: params?.module?.id || addQuestionModalData?.filters?.module?.id || null,
      chapter: params?.chapter?.id || addQuestionModalData?.filters?.chapter?.id || null,
      key_concept:
        params?.keyConcept?.id || addQuestionModalData?.filters?.keyConcept?.id || null,
      question_type:
        params?.questionType?.id ||
        addQuestionModalData?.filters?.questionType?.id ||
        null,
      // question_status:
      //   params?.question_status || questionStatus !== '0' ? questionStatus : null || null,
      page: params?.page || addQuestionModalData?.page?.currentPage,
      page_size: addQuestionModalData?.page?.pageSize,
      question_status: 3,
    };

    if (!params?.academicYear && !createQuestionPaperData?.data?.academicYear) {
      setAddQuestionModalData({ ...addQuestionModalData, show: false });
      message.error('Please select academic year.');
      return;
    }
    if (!params?.grade && !createQuestionPaperData?.data?.grade) {
      setAddQuestionModalData({ ...addQuestionModalData, show: false });
      message.error('Please select grade.');
      return;
    }
    if (!params?.subject && !createQuestionPaperData?.data?.subject) {
      setAddQuestionModalData({ ...addQuestionModalData, show: false });
      message.error('Please select subject.');
      return;
    }

    axiosInstance
      .get(`${endpoints.clickerQuestion.clickerQuestions}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setAddQuestionModalData((prevState) => ({
            ...prevState,
            loading: false,
            questionList: result.data?.result?.results,
            page: {
              ...addQuestionModalData.page,
              totalData: result.data?.result?.count,
              currentPage: params?.page || addQuestionModalData?.page?.currentPage,
              pageSize: addQuestionModalData?.page?.pageSize,
            },
          }));
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || 'Something went wrong');
      })
      .finally(() => {
        setAddQuestionModalData((prevState) => ({ ...prevState, loading: false }));
      });
  };

  // handle Filter
  const handleFilterChange = (keyname, value) => {
    if (keyname === 'title') {
      setCreateQuestionPaperData({
        ...createQuestionPaperData,
        data: {
          ...createQuestionPaperData?.data,
          title: value ? value : null,
        },
      });
      return;
    }
    if (keyname === 'description') {
      setCreateQuestionPaperData({
        ...createQuestionPaperData,
        data: {
          ...createQuestionPaperData?.data,
          description: value ? value : null,
        },
      });
      return;
    }
    if (keyname === 'academicYear') {
      setCreateQuestionPaperData({
        ...createQuestionPaperData,
        data: {
          ...createQuestionPaperData?.data,
          academicYear: value ? value.optionsData : null,
          grade: null,
          subject: null,
        },
      });
      return;
    }
    if (keyname === 'grade') {
      setCreateQuestionPaperData({
        ...createQuestionPaperData,
        data: {
          ...createQuestionPaperData?.data,
          grade: value ? value.optionsData : null,
          subject: null,
        },
      });
      return;
    }
    if (keyname === 'subject') {
      setCreateQuestionPaperData({
        ...createQuestionPaperData,
        data: {
          ...createQuestionPaperData?.data,
          subject: value ? value.optionsData : null,
        },
      });
      return;
    }
    setCreateQuestionPaperData({
      ...createQuestionPaperData,
      data: {
        ...createQuestionPaperData?.data,
        [keyname]: value?.optionsData || value,
      },
    });
  };
  // handle Filter Drawer
  const handleFilterChangeDrawer = (keyname, value) => {
    if (keyname === 'volume') {
      setAddQuestionModalData({
        ...addQuestionModalData,
        filters: {
          ...addQuestionModalData?.filters,
          volume: value ? value.optionsData : null,
          module: null,
          chapter: null,
          keyConcept: null,
        },
      });
      return;
    }
    if (keyname === 'module') {
      setAddQuestionModalData({
        ...addQuestionModalData,
        filters: {
          ...addQuestionModalData?.filters,
          module: value ? value.optionsData : null,
          chapter: null,
          keyConcept: null,
        },
      });
      return;
    }
    if (keyname === 'chapter') {
      setAddQuestionModalData({
        ...addQuestionModalData,
        filters: {
          ...addQuestionModalData?.filters,
          chapter: value ? value.optionsData : null,
          keyConcept: null,
        },
      });
      return;
    }
    if (keyname === 'keyConcept') {
      setAddQuestionModalData({
        ...addQuestionModalData,
        filters: {
          ...addQuestionModalData?.filters,
          keyConcept: value ? value.optionsData : null,
        },
      });
      return;
    }
    if (keyname === 'keyConcept') {
      setAddQuestionModalData({
        ...addQuestionModalData,
        filters: {
          ...addQuestionModalData?.filters,
          questionType: value ? value.optionsData : null,
        },
      });
      return;
    }
    setAddQuestionModalData({
      ...addQuestionModalData,
      filters: {
        ...addQuestionModalData?.filters,
        [keyname]: value?.optionsData || value,
      },
    });
  };

  const handleSelectecQuestion = (item) => {
    let tempSelectedQuestion = addQuestionModalData?.selectectedQuestion || [];
    if (getArrayValues(tempSelectedQuestion, 'id')?.includes(item.id)) {
      tempSelectedQuestion = tempSelectedQuestion.filter((qId) => qId?.id !== item?.id);
    } else {
      tempSelectedQuestion = [...tempSelectedQuestion, item];
    }
    setAddQuestionModalData({
      ...addQuestionModalData,
      selectectedQuestion: [...tempSelectedQuestion],
    });
  };

  const handleAddQuestionToQuestionPaper = () => {
    setCreateQuestionPaperData({
      ...createQuestionPaperData,
      data: {
        ...createQuestionPaperData?.data,
        questions: addQuestionModalData?.selectectedQuestion,
      },
    });
    handleClearDrawerData();
  };

  const handleClearDrawerData = () => {
    setAddQuestionModalData({
      show: false,
      filters: {
        volume: null,
        module: null,
        chapter: null,
        keyConcept: null,
        questionType: null,
      },
      questionList: null,
      selectectedQuestion: [],
      page: { currentPage: 1, pageSize: 15, totalData: null },
      loading: false,
    });
  };

  const handleRemoveQuestionFromQuestionpaper = (item) => {
    let tempSelectedQuestion = createQuestionPaperData?.data?.questions;
    tempSelectedQuestion = tempSelectedQuestion.filter((qId) => qId?.id !== item?.id);
    setCreateQuestionPaperData({
      ...createQuestionPaperData,
      data: { ...createQuestionPaperData?.data, questions: tempSelectedQuestion },
    });
  };

  const checkvalidation = () => {
    if (!createQuestionPaperData?.data?.academicYear) {
      message.error('Please enter academic year');
      return false;
    }
    if (!createQuestionPaperData?.data?.grade) {
      message.error('Please enter grade');
      return false;
    }
    if (!createQuestionPaperData?.data?.subject) {
      message.error('Please enter subject');
      return false;
    }
    if (!createQuestionPaperData?.data?.title) {
      message.error('Please enter title');
      return false;
    }
    if (!createQuestionPaperData?.data?.description) {
      message.error('Please enter instructions');
      return false;
    }
    if (createQuestionPaperData?.data?.questions?.length < 1) {
      message.error('Please enter question');
      return false;
    }

    return true;
  };

  const createQuestionPaper = (status) => {
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      title: createQuestionPaperData?.data?.title,
      description: createQuestionPaperData?.data?.description,
      academic_year: createQuestionPaperData?.data?.academicYear?.id,
      grade: createQuestionPaperData?.data?.grade?.id,
      subject: createQuestionPaperData?.data?.subject?.subject?.id,
      paper_status: status,
      total_questions: createQuestionPaperData?.data?.questions?.length,
      total_marks: createQuestionPaperData?.data?.questions.reduce(
        (sum, question) => sum + question.question_mark,
        0
      ),
      // total_duration: 30, //
      question_ids: getArrayValues(createQuestionPaperData?.data?.questions, 'id'),
      questions_data: createQuestionPaperData?.data?.questions.map((each) => {
        return { id: each?.id, question_mark: each?.question_mark };
      }),
    };
    axiosInstance
      .post(endpoints.clickerQuestion.clickerQuestionsPaper, payload)
      .then((response) => {
        if (response.data.status_code === 200) {
          message.success(response.data.message);
          history.push('/clicker-question/question-paper');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editQuestionPaper = () => {
    let validate = checkvalidation();
    if (!validate) {
      return;
    }
    let payload = {
      id: match?.params?.id,
      title: createQuestionPaperData?.data?.title,
      description: createQuestionPaperData?.data?.description,
      academic_year: createQuestionPaperData?.data?.academicYear?.id,
      grade: createQuestionPaperData?.data?.grade?.id,
      subject: createQuestionPaperData?.data?.subject?.subject?.id,
      paper_status: createQuestionPaperData?.edit?.question_status,
      total_questions: createQuestionPaperData?.data?.questions?.length,
      total_marks: createQuestionPaperData?.data?.questions.reduce(
        (sum, question) => sum + question.question_mark,
        0
      ),
      // total_duration: 30, //
      question_ids: getArrayValues(createQuestionPaperData?.data?.questions, 'id'),
      questions_data: createQuestionPaperData?.data?.questions,
    };
    axiosInstance
      .put(endpoints.clickerQuestion.clickerQuestionsPaper, payload)
      .then((response) => {
        if (response.data.status_code === 200) {
          message.success(response.data.message);
          history.push('/clicker-question/question-paper');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const academicYearOptions = academicYearList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.session_year}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.grade_name}
      </Option>
    );
  });
  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.subject?.subject_name}
      </Option>
    );
  });
  const volumeOptions = volumeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.volume_name}
      </Option>
    );
  });
  const moduleOptions = moduleList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.lt_module_name}
      </Option>
    );
  });
  const chapterOptions = chapterList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.chapter_name}
      </Option>
    );
  });
  const keyConceptOptions = keyConceptList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.topic_name}
      </Option>
    );
  });
  const questionTypeOptions = questionTypeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id} optionsData={el}>
        {el?.category_name}
      </Option>
    );
  });

  const questionData = (
    <div>
      <Spin tip='Loading...' size='large' spinning={addQuestionModalData?.loading}>
        {addQuestionModalData?.questionList?.length > 0 ? (
          <>
            {addQuestionModalData?.questionList?.map((item, index) => {
              return (
                <Row align='middle' key={index}>
                  <Col md={1}>
                    <Checkbox
                      id={`question${item?.id}`}
                      checked={getArrayValues(
                        addQuestionModalData?.selectectedQuestion,
                        'id'
                      )?.includes(item?.id)}
                      onChange={() => handleSelectecQuestion(item)}
                    />
                  </Col>
                  <Col md={23}>
                    <label htmlFor={`question${item?.id}`} className='d-block'>
                      <Question currentQuestion={item} index={index} currentPage={1} />
                    </label>
                  </Col>
                </Row>
              );
            })}
            <div className='row justify-content-center'>
              <div className='col-12'>
                <div className='text-center'>
                  <Pagination
                    current={addQuestionModalData?.page?.currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    pageSize={addQuestionModalData?.page?.pageSize}
                    total={addQuestionModalData?.page?.totalData}
                    style={{ justifyContent: 'center' }}
                    onChange={(currentPage) => {
                      fetchQuestionList({
                        page: currentPage,
                        ...addQuestionModalData?.filters,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Empty description='No questions found' className='mt-3' />
          </>
        )}
      </Spin>
    </div>
  );
  return (
    <>
      <Layout>
        {/* Breadcrumb */}
        <div className='row pb-3'>
          <div className='col-md-9' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-grey-1 th-16'>
                Clicker Question
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className='th-grey-1 th-16 th-pointer'
                onClick={() => {
                  history.goBack();
                }}
              >
                Question Paper
              </Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>
                {match?.params?.id ? 'Update' : 'Create'} Question Paper
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className='th-bg-grey th-br-8 px-3 mb-4'>
          <Row gutter={[8, 8]} className='th-bg-white shadow-sm th-br-8 p-3'>
            <Col md={5}>
              <label htmlFor='AcademicYear' className='' title='Academic Year*'>
                Academic Year *
              </label>
              <Select
                showSearch
                placeholder='Academic Year*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                disabled={isEdit}
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                value={createQuestionPaperData?.data?.academicYear?.id}
                onChange={(e, row) => handleFilterChange('academicYear', row)}
              >
                {academicYearOptions}
              </Select>
            </Col>
            <Col md={5}>
              <label htmlFor='grade' className='' title='Grade*'>
                Grade *
              </label>
              <Select
                disabled={isEdit}
                showSearch
                placeholder='Select Grade*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                value={createQuestionPaperData?.data?.grade?.id ?? null}
                onChange={(e, row) => handleFilterChange('grade', row)}
              >
                {gradeOptions}
              </Select>
            </Col>
            <Col md={5}>
              <label htmlFor='Subject' className='' title='Subject*'>
                Subject *
              </label>
              <Select
                disabled={isEdit}
                showSearch
                placeholder='Select Subject*'
                optionFilterProp='children'
                className='w-100'
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownMatchSelectWidth={false}
                filterOption={(input, options) => {
                  return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                value={createQuestionPaperData?.data?.subject?.id}
                onChange={(e, row) => handleFilterChange('subject', row)}
              >
                {subjectOptions}
              </Select>
            </Col>

            <Col md={9}>
              <label htmlFor='Subject' className='' title='Subject*'>
                Title *
              </label>
              <Input
                placeholder='Question Paper Title'
                value={createQuestionPaperData?.data?.title}
                showCount
                maxLength={200}
                onChange={(e) => handleFilterChange('title', e.target.value)}
              />
            </Col>

            <Col md={24}>
              <label htmlFor='Description' className='' title='Title*'>
                Instruction *
              </label>
              <TextArea
                placeholder='Question Paper Instruction'
                rows={3}
                showCount
                maxLength={500}
                value={createQuestionPaperData?.data?.description}
                onChange={(e) => handleFilterChange('description', e.target.value)}
              />
            </Col>

            <Col md={24}>
              {Array.isArray(createQuestionPaperData?.data?.questions) &&
              createQuestionPaperData?.data?.questions?.length > 0 ? (
                createQuestionPaperData?.data?.questions?.map((item, index) => {
                  return (
                    <Row key={index} align={'middle'}>
                      <Col md={23}>
                        <Question currentQuestion={item} index={index} />
                      </Col>
                      <Col md={1}>
                        <Popconfirm
                          title='Are you sure to delete this question?'
                          zIndex={1500}
                          placement='topRight'
                          onConfirm={() =>
                            handleRemoveQuestionFromQuestionpaper(item, index)
                          }
                          // onCancel={cancel}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Tooltip
                            placement='topRight'
                            title={'Remove question from question paper'}
                          >
                            <DeleteOutlined className='text-danger float-right th-20' />
                          </Tooltip>
                        </Popconfirm>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Empty description='Question goes here' className='mt-3' />
              )}
            </Col>

            <Col md={24}>
              <div className='d-flex justify-content-end'>
                <Button
                  onClick={() =>
                    setAddQuestionModalData({
                      ...addQuestionModalData,
                      show: true,
                      selectectedQuestion: createQuestionPaperData?.data?.questions,
                      filters: {
                        academicYear: createQuestionPaperData?.data?.academicYear,
                        grade: createQuestionPaperData?.data?.grade,
                        subject: createQuestionPaperData?.data?.subject,
                      },
                    })
                  }
                >
                  Add Question
                </Button>
              </div>
            </Col>
          </Row>

          <Row
            className='row align-items-center justify-content-end pr-5'
            style={{
              height: '58px',
              background: '#dee2e6',
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              position: 'absolute',
            }}
          >
            <>
              <Button
                className='mr-4'
                onClick={() => {
                  history.goBack();
                }}
              >
                Go Back
              </Button>
              {match?.params?.id ? (
                <div className=''>
                  <Button onClick={() => editQuestionPaper()}>
                    Update Question Paper
                  </Button>
                </div>
              ) : (
                <>
                  <div className=' mr-4'>
                    <Button onClick={() => createQuestionPaper(2)}>
                      Create Question Paper for Review
                    </Button>
                  </div>

                  <div className=''>
                    <Button onClick={() => createQuestionPaper(1)}>
                      Create Question Paper as Draft
                    </Button>
                  </div>
                </>
              )}
            </>
          </Row>
        </div>

        <Drawer
          title={'Add Question from Question Bank'}
          destroyOnClose={true}
          maskClosable={false}
          width='70%'
          onClose={() =>
            setAddQuestionModalData({
              show: false,
              filters: {
                volume: null,
                module: null,
                chapter: null,
                keyConcept: null,
                questionType: null,
              },
              questionList: null,
              selectectedQuestion: [],
              page: { currentPage: 1, pageSize: 15, totalData: null },
              loading: false,
            })
          }
          open={addQuestionModalData?.show}
          footer={
            <>
              <Space className='float-right'>
                <Button
                  type='default'
                  onClick={() => {
                    handleClearDrawerData();
                  }}
                >
                  Close
                </Button>
                <Button
                  disabled={!addQuestionModalData?.selectectedQuestion?.length}
                  type='primary'
                  onClick={() => {
                    handleAddQuestionToQuestionPaper();
                  }}
                >
                  Add to Question Paper
                </Button>
              </Space>
            </>
          }
          zIndex={1400}
        >
          <div className='th-bg-grey p-2 th-br-8  mb-4'>
            <Row gutter={[8, 8]}>
              <Col md={8}>
                <label htmlFor='Volume' className='' title='Volume*'>
                  Volume
                </label>
                <Select
                  showSearch
                  placeholder='Select Volume'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={addQuestionModalData?.filters?.volume?.id}
                  onChange={(e, row) => handleFilterChangeDrawer('volume', row)}
                >
                  {volumeOptions}
                </Select>
              </Col>
              <Col md={8}>
                <label htmlFor='Module' className='' title='Module*'>
                  Module
                </label>
                <Select
                  showSearch
                  allowClear
                  placeholder='Select Module'
                  optionFilterProp='children'
                  className='w-100'
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={addQuestionModalData?.filters?.module?.id}
                  onChange={(e, row) => handleFilterChangeDrawer('module', row)}
                >
                  {moduleOptions}
                </Select>
              </Col>
              <Col md={8}>
                <label htmlFor='Chapter' className='' title='Chapter*'>
                  Chapter
                </label>
                <Select
                  showSearch
                  placeholder='Select Chapter'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={addQuestionModalData?.filters?.chapter?.id}
                  onChange={(e, row) => handleFilterChangeDrawer('chapter', row)}
                >
                  {chapterOptions}
                </Select>
              </Col>
              <Col md={8}>
                <label htmlFor='KeyConcept' className='' title='Key Concept*'>
                  Key Concept
                </label>
                <Select
                  showSearch
                  placeholder='Select Key Concept'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={addQuestionModalData?.filters?.keyConcept?.id}
                  onChange={(e, row) => handleFilterChangeDrawer('keyConcept', row)}
                >
                  {keyConceptOptions}
                </Select>
              </Col>
              <Col md={8}>
                <label htmlFor='QuestionType' className='' title='Question Type *'>
                  Question Type
                </label>
                <Select
                  placeholder='Question Type'
                  optionFilterProp='children'
                  className='w-100'
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={addQuestionModalData?.filters?.questionType?.id}
                  onChange={(e, row) => handleFilterChangeDrawer('questionType', row)}
                >
                  {questionTypeOptions}
                </Select>
              </Col>
            </Row>
          </div>
          {questionData}
        </Drawer>
      </Layout>
    </>
  );
};

export default CreateEditQuestionPaper;
