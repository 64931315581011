import React, { useState, useRef, useEffect, useContext } from 'react';
import { Modal, Select, Form, message, Button } from 'antd';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import DNDFileUpload from '../../../components/dnd-file-upload';
import FileSaver from 'file-saver';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Loading from '../../../components/loader/loader';

const { Option } = Select;

const BulkUpload = ({
  academicYearList,
  gradeList,
  boardList,
  selectedAcademicYearId,
  selectedGradeId,
  selectedSubjectId,
}) => {
  const formRef = useRef();
  const { setAlert } = useContext(AlertNotificationContext);
  const [AcademicYear, setAcademicYear] = useState();
  const [grade, setGrade] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState();
  const [board, setBoard] = useState();
  const [file, setFile] = useState(null);
  const [isUploading, setUploading] = useState(false);

  const fileConf = {
    fileTypes: 'application/vnd.ms-excel',
    types: 'xls',
  };

  const allTypeQuestions = 100;
  const templateFiles = {
    [allTypeQuestions]: {
      templateFile:
        'https://omrsheet.s3.ap-south-1.amazonaws.com/prod/assessment_config_files/Bulk+Upload+Questions+Template.xls',
      // 'https://d2r9gkgplfhsr2.cloudfront.net/prod/assessment_config_files/assessment_single_choice.xlsx',
      url: endpoints.createQuestionBulk.bulkUpload,
      label: '',
      fileConf: {
        fileTypes: 'application/vnd.ms-excel',
        types: 'xls',
      },
      templateFileHeaders: [
        'chapter',
        'keyconcept',
        'question',
        'option1',
        'option2',
        'option3',
        'option4',
        'option5',
        'correct_answer',
        'question_level',
        'question_type',
        'question_categories',
        'comprehension question id',
        'parent comprehension question ',
      ],
    },
  };

  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
          // if (selectedSubjectId) {
          //   formRef.current.setFieldsValue({
          //     subject: result?.data?.result?.results?.filter(
          //       (el) => el?.id == selectedSubjectId
          //     )[0]?.subject?.subject_name,
          //   });
          // }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const downloadchapterkeys = () => {
    if (AcademicYear && grade && subject) {
      axiosInstance
        .get(
          `${endpoints.questionBank.chapterKeyConcept}?session_year=${AcademicYear}&grade_subject_mapping=${subject}`,
          {
            responseType: 'arraybuffer',
          }
        )
        .then((result) => {
          // setLoading(false);
          if (result.status === 200) {
            const blob = new Blob([result.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            FileSaver.saveAs(blob, 'records.xls');
          } else {
            message.error(result.data.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          message.error(error.message);
        });
    } else {
      message.error('please select the fields');
    }
  };

  const DownloadDataFile = () => {
    axiosInstance
      .get(`${endpoints.createQuestionBulk.downloadDataFile}`, {
        responseType: 'blob',
      })
      .then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, 'question_data_template.xls');
        } else {
          message.error(result.data.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const downloadSampleFile = (url, fileNameStr) => {
    const fileName = fileNameStr || url.split('/').splice(-1)[0];
    function anchorDwnld(href) {
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 150);
    }
    anchorDwnld(url);
  };

  const handleUpload = () => {
    if (!AcademicYear) {
      message.error('Please choose AcademicYear');
    }
    const payLoad = {
      file: {
        value: file,
        label: 'File',
        validationText: 'Please choose file to upload.',
      },
      grade_subject_mapping: {
        value: subject,
        label: 'Grade subject mapping',
        validationText: 'Please choose subject.',
      },
    };

    const formData = new FormData();

    const isValid = Object.entries(payLoad).every(([key, valueObj]) => {
      formData.set(key, valueObj.value);
      if (!valueObj.value) {
        message.error(valueObj.validationText);
        return false;
      }
      return true;
    });

    if (isValid && AcademicYear) {
      setUploading(true);
      axiosInstance
        .post(`${endpoints.createQuestionBulk.bulkUpload}`, formData, {
          responseType: 'blob',
        })
        .then((response) => {
          console.log('Response received:', response);
          if (response?.status === 200) {
            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);
            message.success('All Questions are uploaded successfully');
            downloadSampleFile(href, 'assessment_questions.csv');
            setFile(null);
          } else if (response?.status === 201) {
            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);
            message.error(
              'One or More questions are not uploaded, Please check error logs'
            );
            downloadSampleFile(href, 'assessment_questions.csv');
            setFile(null);
          } else if (response?.status === 202) {
            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);
            message.error('No questions are uploaded, Please check error logs');
            downloadSampleFile(href, 'assessment_questions.csv');
            setFile(null);
          }
        })
        .catch((error) => {
          const fileReader = new FileReader();
          fileReader.onload = function (event) {
            const text = event.target.result;
            message.error(text ? JSON.parse(text).message : 'Something went wrong');
          };
          fileReader.readAsText(error.response.data);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const academicYearOptions = academicYearList?.map((option) => {
    return (
      <Option id={option?.id} value={option?.session_year}>
        {option?.session_year}
      </Option>
    );
  });

  const gradeOptions = gradeList?.map((option) => {
    return (
      <Option id={option?.id} value={option?.id}>
        {option?.grade_name}
      </Option>
    );
  });

  const subjectOptions = subjectList?.map((option) => {
    return (
      <Option id={option?.id} value={option?.id}>
        {option?.subject?.subject_name}
      </Option>
    );
  });

  const boardOptions = boardList?.map((option) => {
    return (
      <Option id={option?.id} value={option?.id}>
        {option?.board_name}
      </Option>
    );
  });

  const handleAcademicYearChange = (e) => {
    setGrade();
    setSubject();
    setSubjectList([]);
    setBoard();
    formRef.current.setFieldsValue({
      grade: null,
      subject: null,
      board: null,
    });
    if (e) {
      setAcademicYear(e);
    } else {
      setAcademicYear();
    }
  };

  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubject();
    setBoard();
    formRef.current.setFieldsValue({
      subject: null,
      board: null,
    });
    if (e) {
      setGrade(e);
      fetchSubjectList({ grade: e });
    } else {
      setGrade();
    }
  };

  const handleSubjectChange = (e) => {
    setBoard();
    formRef.current.setFieldsValue({
      board: null,
    });
    if (e) {
      setSubject(e);
    } else {
      setSubject();
    }
  };

  const handleBoardChange = (e) => {
    if (e) {
      setBoard(e);
    } else {
      setBoard();
    }
  };

  // useEffect(() => {
  //   if (selectedGradeId) {
  //     fetchSubjectList({ grade: selectedGradeId });
  //   }
  // }, []);

  return (
    <>
      {isUploading ? <Loading message='uploading...' /> : null}
      <div className='row'>
        <div className='col-12'>
          <Form ref={formRef} layout='vertical'>
            <div className='row align-item-center'>
              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'>
                <Form.Item name='academic_year' label='Academic Year*'>
                  <Select
                    showSearch
                    placeholder='Select Academic Year*'
                    optionFilterProp='children'
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleAcademicYearChange(e);
                    }}
                  >
                    {academicYearOptions}
                  </Select>
                </Form.Item>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'>
                <Form.Item name='grade' label='Grade*'>
                  <Select
                    showSearch
                    placeholder='Select Grade*'
                    optionFilterProp='children'
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleGradeChange(e);
                    }}
                  >
                    {gradeOptions}
                  </Select>
                </Form.Item>
              </div>

              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-12'>
                <Form.Item name='subject' label='Subject*'>
                  <Select
                    showSearch
                    placeholder='Select Subject*'
                    optionFilterProp='children'
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleSubjectChange(e);
                    }}
                  >
                    {subjectOptions}
                  </Select>
                </Form.Item>
              </div>

              {/* <div className='col-sm-3 col-6'>
                <Form.Item name='board' label='Board*'>
                  <Select
                    showSearch
                    allowClear
                    placeholder='Select Board*'
                    optionFilterProp='children'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e) => {
                      handleBoardChange(e);
                    }}
                  >
                    {boardOptions}
                  </Select>
                </Form.Item>
              </div> */}
              <br />
              {/* <div className='row align-item-center'> */}
              <div className='col-sm-12 col-md-5 col-lg-3 col-xl-3 col-12'>
                <Form.Item>
                  <Button
                    type='primary'
                    className='th-br-4 w-100'
                    onClick={DownloadDataFile}
                  >
                    Download ID's
                  </Button>
                </Form.Item>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 col-12'>
                <Form.Item>
                  <Button
                    type='primary'
                    className='th-br-4 w-100'
                    onClick={downloadchapterkeys}
                  >
                    Download Chapter ID
                  </Button>
                </Form.Item>
              </div>
              <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 col-12'>
                <Form.Item>
                  <Button
                    type='primary'
                    className='th-br-4 w-100'
                    onClick={() => {
                      window.location.href =
                        '/assets/download-format/QuestionV2_Upload_Template.xls';
                    }}
                  >
                    Download Question Template
                  </Button>
                </Form.Item>
              </div>
            </div>
            {/* </div> */}
          </Form>
          <br />
        </div>

        <div className='row align-item-center justify-content-center     mt-5'>
          <div className='col-sm-10 col-6'>
            <DNDFileUpload
              value={file}
              handleChange={(e) => {
                setFile(e);
              }}
              fileType={fileConf.fileTypes}
              typeNames={fileConf.types}
            />
          </div>
          <div className='col-sm-2 col-6'>
            <Button
              type='primary'
              className='th-br-4 w-100'
              disabled={!file}
              onClick={handleUpload}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkUpload;
