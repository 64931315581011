import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { message, Popconfirm, Select, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import QuestionDetailsModal from './questionDetailsModal';

const Question = ({
  createQuestionmodalData,
  setCreateQuestionmodalData,
  currentPage,
  currentQuestion,
  index,
  setFetchQuestionListFlag,
}) => {
  //eslint-disable-next-line
  const [boardId, setBoardId] = useState('');
  const [questionViewModal, setQuestionViewModal] = useState(false);

  const questionFilteredData = {
    academicYear: currentQuestion?.academic_year[0] || null,
    grade: currentQuestion?.grade[0]?.grade_name || null,
    subject: currentQuestion?.subject[0]?.subject_name || null,
    board: boardId || null,
    volume: currentQuestion?.volume[0]?.volume_name || null,
    module: currentQuestion?.module[0]?.lt_module_name || null,
    chapter: currentQuestion?.chapter[0]?.chapter_name || null,
    keyConcept: currentQuestion?.key_concept[0]?.topic_name || null,
    questionType: currentQuestion?.question_type_name || null,
    questionMarks: currentQuestion?.question_mark || null,
  };

  // Get Edit Question Details
  const getEditQuestionData = async (id) => {
    await axiosInstance
      .get(`${endpoints.clickerQuestion.clickerQuestions}`, { params: { id } })
      .then((res) => {
        if (res?.data?.status_code === 200) {
          let data = res?.data?.result?.results?.[0];
          let modifiedData = {
            show: true,
            edit: { show: true, id: data?.id, question_status: data?.question_status },
            data: {
              question: data?.question,
              options: data?.options.map((each) => {
                return { ...each, answer: each?.text };
              }),
              filteredData: {
                academicYear: data?.academic_year[0],
                grade: data?.grade[0],
                subject: {
                  id: data?.subject[0].id,
                  subject: {
                    id: data?.subject[0].subject_id,
                    subject_name: data?.subject[0]?.subject_name,
                  },
                },
                // board: null,
                volume: data?.volume[0],
                module: data?.module[0],
                chapter: data?.chapter[0],
                keyConcept: data?.key_concept[0],
                questionType: {
                  id: data?.question_type,
                  category_name: data?.question_type_name,
                },
                questionMarks: data?.question_mark,
              },
            },
          };
          setCreateQuestionmodalData({ ...createQuestionmodalData, ...modifiedData });
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  //Delete Question
  const handleQuestionDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `${endpoints.clickerQuestion.clickerQuestions}`,
        { params: { question_id: id } }
      );
      message.success(response?.data?.message || 'Question deleted successfully');
      setFetchQuestionListFlag(true);
    } catch (error) {
      message.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  //Update question status
  const handleQuestionStatusUpdate = async (id, question_status) => {
    let payload = {
      id,
      question_status,
    };
    await axiosInstance
      .put(endpoints.clickerQuestion.clickerQuestions, payload)
      .then((res) => {
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          setFetchQuestionListFlag(true);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || 'Something went wrong');
      });
  };

  const handleViewQuestion = () => {
    setQuestionViewModal(true);
  };
  const handleCloseViewQuestion = () => {
    setQuestionViewModal(false);
  };

  return (
    <>
      <div
        className='row p-2 mb-3 th-br-8 th-pointer'
        style={{ outline: '1px solid #f3f3f3' }}
      >
        <div className='col-md-12 px-0 d-flex justify-content-between'>
          <div className='pb-2'>
            Q {(currentPage - 1) * 15 + index + 1}.{' '}
            <Tag>{questionFilteredData?.questionType}</Tag>
            <Tag
              color={
                currentQuestion?.question_status === 1
                  ? 'yellow'
                  : currentQuestion?.question_status === 2
                  ? 'blue'
                  : currentQuestion?.question_status === 3
                  ? 'green'
                  : 'green'
              }
              className='th-pointer th-12'
            >
              {currentQuestion?.question_status === 1
                ? 'Draft'
                : currentQuestion?.question_status === 2
                ? 'Review'
                : currentQuestion?.question_status === 3
                ? 'Published'
                : currentQuestion?.question_status}
            </Tag>
            <span className='th-12'>
              {questionFilteredData?.grade} | {questionFilteredData?.subject} |{' '}
              {questionFilteredData?.volume} | {questionFilteredData?.module} |{' '}
              {questionFilteredData?.chapter} | {questionFilteredData?.keyConcept}
            </span>
          </div>
          <div className='text-right'>
            <Popconfirm
              title='Are you sure to update status of this question?'
              onConfirm={() =>
                handleQuestionStatusUpdate(
                  currentQuestion?.id,
                  currentQuestion?.question_status === 1
                    ? 2
                    : currentQuestion?.question_status === 2
                    ? 3
                    : currentQuestion?.question_status === 3
                    ? 2
                    : null
                )
              }
              okText='Yes'
              cancelText='No'
              placement='topRight'
            >
              {/* draft - 1, review -2, publish -3, */}
              {/* status 1 => move to review from draft */}
              {/* status 2 => move to Publish from review */}
              {/* status 3 => move to Review from publish */}
              <Tag
                icon={<ReloadOutlined />}
                color={
                  currentQuestion?.question_status === 1
                    ? 'yellow'
                    : currentQuestion?.question_status === 2
                    ? 'blue'
                    : currentQuestion?.question_status === 3
                    ? 'green'
                    : 'green'
                }
                className='th-pointer th-12'
              >
                Move to{' '}
                {currentQuestion?.question_status === 1
                  ? 'Review'
                  : currentQuestion?.question_status === 2
                  ? 'Publish'
                  : currentQuestion?.question_status === 3
                  ? 'Review'
                  : null}
              </Tag>
            </Popconfirm>

            <Popconfirm
              title='Are you sure to delete this question?'
              onConfirm={() => handleQuestionDelete(currentQuestion?.id)}
              okText='Yes'
              cancelText='No'
              placement='topRight'
            >
              <Tag icon={<DeleteOutlined />} color='error' className='th-pointer th-12'>
                Delete
              </Tag>
            </Popconfirm>

            <Tag
              icon={<EditOutlined />}
              color='processing'
              onClick={() => {
                getEditQuestionData(currentQuestion?.id);
              }}
              className='th-pointer th-12'
            >
              Edit
            </Tag>
          </div>
        </div>
        <div
          className='col-md-12 pl-0 border-right th-bg-blue-1 th-br-8'
          style={{ maxHeight: '200px', overflowY: 'auto' }}
        >
          <div className=' row p-2 ' onClick={handleViewQuestion}>
            {/* <span className='pr-2'>Q {(currentPage - 1) * 15 + index + 1}.</span>{' '} */}
            <div className='th-question-box '>
              {ReactHtmlParser(currentQuestion?.question)}
            </div>{' '}
          </div>
        </div>
      </div>
      {questionViewModal && (
        <QuestionDetailsModal
          questionViewModal={questionViewModal}
          handleCloseViewQuestion={handleCloseViewQuestion}
          currentQuestion={currentQuestion}
          index={index}
        />
      )}
    </>
  );
};

export default Question;
