import { Button, Form, Input, Select, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { Cancel } from '@material-ui/icons';

const UploadVideo = ({
  uploadFlag,
  setUploadFlag,
  resetDrawer,
  setResetDrawer,
  editRecord,
}) => {
  const videoFormRef = useRef();
  const { Option } = Select;
  const { Dragger } = Upload;
  const [gradeList, setGradeList] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const allowedFiles = ['.mp3', '.mp4'];

  useEffect(() => {
    fetchGradeList();
  }, []);

  useEffect(() => {
    if (uploadFlag) {
      uploadVideoFile();
    }
  }, [uploadFlag]);

  useEffect(() => {
    if (resetDrawer) {
      resetDrawerValue();
    }
  }, [resetDrawer]);

  useEffect(() => {
    if (editRecord) {
      setGradeId(editRecord.grade_id);
      setSubjectId(editRecord.subject_id);

      videoFormRef.current.setFieldsValue({
        grade: editRecord.grade_id,
        subject: editRecord.subject_id,
        searchvalue: editRecord.file_name,
      });

      fetchSubjectList({ grade: editRecord.grade_id });
    }
  }, [editRecord]);

  const draggerProps = {
    showUploadList: false,
    disabled: false,
    accept: allowedFiles.join(),
    multiple: false,
    onRemove: () => {
      setSelectedFile(null);
    },
    onDrop: (e) => {
      const file = e.dataTransfer.files;
      setSelectedFile(null);
      if (file[0]?.name.length > 100) {
        return;
      }
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      const size = file[0]?.size;
      if (allowedFiles.includes(type)) {
        if (size <= 500 * 1024 * 1024) {
          setSelectedFile(...file);
        } else {
          return;
        }
      } else {
        message.error(`Please upload only ${allowedFiles.toString()} file`);
      }
      return false;
    },
    beforeUpload: (...file) => {
      setSelectedFile(null);
      if (file[0]?.name.length > 100) {
        message.error('File name must be less then 100 character');
        return;
      }
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      const size = file[0]?.size;
      if (allowedFiles.includes(type)) {
        if (size <= 500 * 1024 * 1024) {
          setSelectedFile(...file[1]);
        } else {
          message.error('File size must be less than 500MB');
        }
      } else {
        message.error(`Please upload only ${allowedFiles.toString()} file`);
      }
      return false;
    },
    selectedFile,
  };

  const fetchGradeList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setGradeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axiosInstance.post(
        `${endpoints.lessonPlan.qrFileUpload}`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const uploadVideoFile = async () => {
    if (!gradeId) {
      message.error('please select grade');
      setUploadFlag(false);
      return;
    }

    if (!subjectId) {
      message.error('please select subject');
      setUploadFlag(false);
      return;
    }

    if (!selectedFile && !editRecord) {
      message.error('Please upload a video file');
      setUploadFlag(false);
      return;
    }

    setUploading(true);

    try {
      let fileUploadResponse = null;

      if (selectedFile) {
        fileUploadResponse = await uploadFile(selectedFile);
      }

      const payload = {
        file_name: fileUploadResponse?.file_name,
        grade_id: gradeId,
        subject_id: subjectId,
        file_path: fileUploadResponse.data,
      };

      let result;
      if (editRecord) {
        result = await axiosInstance.put(
          `${endpoints.lessonPlan.plannerVideos}${editRecord.id}/`,
          payload
        );
      } else {
        result = await axiosInstance.post(endpoints.lessonPlan.plannerVideos, payload);
      }

      if (result?.status === 200) {
        message.success(
          editRecord ? 'File updated successfully' : 'File uploaded successfully'
        );
        setResetDrawer(true);
        resetDrawerValue();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setUploading(false);
      setUploadFlag(false);
    }
  };

  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubjectId();
    videoFormRef.current.setFieldsValue({
      subject: null,
    });
    if (e) {
      setGradeId(e);
      fetchSubjectList({ grade: e });
    } else {
      setGradeId(null);
    }
  };

  const handleSubjectChange = (e) => {
    if (e) {
      setSubjectId(e);
    } else {
      setSubjectId();
    }
  };

  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.grade_name}
      </Option>
    );
  });

  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.subject?.id} id={el?.subject?.id} value={el?.subject?.id}>
        {el?.subject?.subject_name}
      </Option>
    );
  });

  const resetDrawerValue = () => {
    videoFormRef.current.resetFields();
    setGradeId(null);
    setSubjectId(null);
    setSelectedFile(null);
  };

  return (
    <React.Fragment>
      <Form ref={videoFormRef} layout='vertical'>
        <Form.Item name='grade' label='Grade*'>
          <Select
            showSearch
            placeholder='Select Grade*'
            optionFilterProp='children'
            className=''
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            dropdownMatchSelectWidth={false}
            filterOption={(input, options) => {
              return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(e) => {
              handleGradeChange(e);
            }}
            disabled={editRecord || uploadFlag || uploading}
          >
            {gradeOptions}
          </Select>
        </Form.Item>

        <Form.Item name='subject' label='Subject*'>
          <Select
            showSearch
            placeholder='Select Subject*'
            optionFilterProp='children'
            className=''
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            dropdownMatchSelectWidth={false}
            filterOption={(input, options) => {
              return options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(e) => {
              handleSubjectChange(e);
            }}
            disabled={editRecord || uploadFlag || uploading}
          >
            {subjectOptions}
          </Select>
        </Form.Item>

        <div className='d-flex justify-content-center pt-2 w-100'>
          <Dragger
            {...draggerProps}
            className={`th-br-4 th-bg-white d-flex justify-content-center th-width-100`}
            style={{
              background: '#fff6f6',
            }}
            disabled={uploadFlag || uploading}
          >
            {selectedFile ? (
              <div className='row align-items-center' style={{ height: '100px' }}>
                <div className='col-12 text-wrap' style={{ wordBreak: 'break-all' }}>
                  <span className='th-fw-500 th-16'>
                    {selectedFile?.name} <Cancel className='pb-0' />
                  </span>
                </div>
              </div>
            ) : (
              <>
                <p className='pt-2'>
                  {editRecord ? 'Upload new file (optional)' : 'Drag And Drop Files Here'}{' '}
                  <br /> or
                </p>
                <Button
                  className='th-primary pb-2 mt-0 th-bg-white th-br-4'
                  style={{ border: '1px solid #1b4ccb' }}
                >
                  Browse Files
                </Button>
              </>
            )}
          </Dragger>
        </div>
        <span>
          <b>Note:</b> Please upload only {allowedFiles.toString()} file and size must be
          less than 500MB.
        </span>
      </Form>
    </React.Fragment>
  );
};

export default UploadVideo;
