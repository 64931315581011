import React, { useEffect, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, useTheme, SvgIcon } from '@material-ui/core';
import unfiltered from '../../../assets/images/unfiltered.svg';
// import Box from '@material-ui/core/Box';
import useStyles from './useStyles';
import moment from 'moment';
import Subjectdeteils from './subject-deteils';
import { Pagination } from '@material-ui/lab';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { generateQueryParamSting } from '../../../utility-functions';
import Loader from '../../../components/loader/loader';

const PeriodCard = (props) => {
  const [isSubjectOpen, setIsSubjectOpen] = React.useState(false);
  const [viewMoreList, setViewMoreList] = React.useState([]);
  const [selectedSchoolObj, setSelectedSchool] = React.useState({});
  const {
    // schoolList,
    fetchSchools,
    selectedSchool,
    selectedModule,
    cardData,
    loading,
    pageNo,
    setPageNo,
    totalCount,
    pageSize,
    getVersion,
  } = props;
  const [selectedSchoolVerion, setSelectedSchoolVerion] = React.useState();
  const [schoolId, setSelectedSchoolId] = React.useState(null);
  const [moduleKey, setModuleKey] = React.useState(null);

  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();

  const openCard = (school) => {
    setIsSubjectOpen(true);
    // setViewMoreList(school);
    setSelectedSchool(school);
    setSelectedSchoolVerion(school?.school_versions);
    setSelectedSchoolId(school?.school_id);
    setModuleKey(Object.keys(school).filter((key) => school[key] === true));
  };

  const cancelCard = () => {
    setIsSubjectOpen(!isSubjectOpen);
  };

  const handlePagination = (e, page) => {
    setPageNo(page);
  };

  return (
    <>
      {loading ? <Loader message='Loading...' /> : null}
      <Grid
        spacing={2}
        container
        direction={isMobile ? 'column-reverse' : 'row'}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item md={isSubjectOpen ? 6 : 12} xs={12}>
          <Grid container spacing={2}>
            {cardData && cardData?.length === 0 ? (
              <Grid container spacing={2} justify='center'>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ textAlign: 'center', marginTop: '20px' }}
                >
                  <div className={classes.periodDataUnavailable}>
                    <SvgIcon component={() => <img src={unfiltered} alt='crash' />} />
                    <Typography variant='h6' color='secondary'>
                      NO DATA FOUND
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid item md={12} xs={12}>
                <span style={{ color: '#00346A', fontSize: '15px' }}>
                  <span
                    style={{
                      background: '#FEFBE8',
                      height: '30px',
                      width: '50px',
                      border: '1px solid lightgray',
                      margin: '10px',
                      padding: '2px 10px',
                      borderRadius: '10px',
                    }}
                  />
                  Assessment
                </span>
                <span style={{ color: '#00346A', fontSize: '15px' }}>
                  <span
                    style={{
                      background: '#FFDBDB',
                      height: '30px',
                      width: '50px',
                      border: '1px solid lightgray',
                      margin: '10px',
                      padding: '2px 10px',
                      borderRadius: '10px',
                    }}
                  />
                  Ebook
                </span>
                <span style={{ color: '#00346A', fontSize: '15px' }}>
                  <span
                    style={{
                      background: '#FFE2C5',
                      height: '30px',
                      width: '50px',
                      border: '1px solid lightgray',
                      margin: '10px',
                      padding: '2px 10px',
                      borderRadius: '10px',
                    }}
                  />
                  Periods
                </span>
                <span style={{ color: '#00346A', fontSize: '15px' }}>
                  <span
                    style={{
                      background: '#ffa0a0',
                      height: '30px',
                      width: '50px',
                      border: '1px solid lightgray',
                      margin: '10px',
                      padding: '2px 10px',
                      borderRadius: '10px',
                    }}
                  />
                  IBook
                </span>
              </Grid>
            )}
            {/* <Grid item xs={isSubjectOpen ? 10 : 12}  style={{display: isSubjectOpen ? 'flex': 'flex', flexWrap: 'wrap'}}> */}
            {cardData?.length !== 0 &&
              cardData.map((list, index) => {
                return (
                  <Grid item sm={isSubjectOpen ? 6 : 4} xs={12}>
                    <Paper
                      className={classes.root}
                      style={{
                        backgroundColor: list?.is_assessment
                          ? '#FEFBE8'
                          : list?.is_ebook
                          ? '#FFDBDB'
                          : list?.is_lesson_plan
                          ? '#FFE2C5'
                          : list?.is_ibook
                          ? '#ffa0a0'
                          : '',
                      }}
                    >
                      <div className='row d-flex justify-content-between'>
                        <Typography
                          className={classes.title}
                          variant='h5'
                          component='p'
                          color='primary'
                        >
                          {list?.school_name}
                        </Typography>
                      </div>
                      <div>
                        {list?.school_versions.length > 0 ? (
                          <Typography
                            className='th-16'
                            variant='p'
                            component='p'
                            color='secondary'
                          >
                            {`Version: ${list.school_versions
                              .sort((a, b) => {
                                return a.version_name.localeCompare(b.version_name);
                              })
                              .map((version) =>
                                version.version_name.replace('Version ', '')
                              )
                              .join(', ')}`}
                          </Typography>
                        ) : (
                          ''
                        )}
                      </div>
                      <br />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <Typography
                            className={classes.title}
                            variant='p'
                            component='p'
                            color='secondary'
                          >
                            Last Updated On
                          </Typography>
                          <Typography
                            className={classes.content}
                            variant='p'
                            component='p'
                            color='secondary'
                          >
                            {moment(list.last_update).format('MMM DD YYYY')}
                            {/* {new Date(list.updated_at || undefined).shortDate('-')} */}
                          </Typography>
                        </div>
                        <Button
                          variant='contained'
                          // style={{ color: 'white' }}
                          color='primary'
                          // className="custom_button_master"
                          size='small'
                          onClick={() => openCard(list)}
                        >
                          VIEW MORE
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
          {/* <Grid xs={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(totalCount / pageSize)}
              color='primary'
              page={pageNo}
              style={{ paddingLeft: '45%' }}
            />
          </Grid> */}
        </Grid>
        {/* </Grid> */}
        {isSubjectOpen && (
          <Grid item xs={12} md={6}>
            <Subjectdeteils
              selectedSchoolVerion={selectedSchoolVerion}
              selectedSchoolObj={selectedSchoolObj}
              cancelCard={cancelCard}
              selectedSchool={selectedSchool}
              selectedSchoolId={schoolId}
              selectedModule={selectedModule}
              moduleKey={moduleKey}
              cardData={cardData}
              getVersion={getVersion}
              setIsSubjectOpen={setIsSubjectOpen}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PeriodCard;
