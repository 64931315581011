import React, { useState, useEffect } from 'react';
import MyTinyEditor from '../../assesment/assessment-new/create-question-paper-new/tiny-mce-new';
import { Radio, Checkbox } from 'antd';
import cuid from 'cuid';
import ReactQuillEditor from '../../../components/reactQuill';

const TrueFalse = ({
  currentQuestion,
  isSubQuestion,
  handleQuestionChanges,
  subQuestionIndex,
  isEditQuestion,
}) => {
  const [trueFalseQuestion, setTrueFalseQuestion] = useState({
    question: currentQuestion?.question_answer[0]?.question,
    answer: currentQuestion?.question_answer[0]?.answer,
    options: currentQuestion?.question_answer[0]?.options,
  });
  const [questionUpdated, setQuestionUpdated] = useState(isEditQuestion);
  const handleTrueFalseContent = () => {
    // if (isSubQuestion) {
    //   return;
    // } else {
    return trueFalseQuestion?.question;
    // }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    // setQuestionUpdated(false);
    setTrueFalseQuestion({ ...trueFalseQuestion, question: content });
    if (isSubQuestion) {
      handleQuestionChanges(
        { ...trueFalseQuestion, question: content },
        subQuestionIndex
      );
    } else {
      handleQuestionChanges({ ...trueFalseQuestion, question: content });
    }
    // setTimeout(() => {
    //   setQuestionUpdated(true);
    // }, 100);
  };

  const handleOptionChange = (e, index) => {
    setQuestionUpdated(false);
    let updatedOptions = trueFalseQuestion?.options?.map((item) => {
      const key = Object.keys(item)[0];
      return {
        [key]: {
          ...item[key],
          isChecked: false,
        },
      };
    });

    updatedOptions[index][`option${index + 1}`].isChecked = true;
    setTrueFalseQuestion({
      ...trueFalseQuestion,
      answer: [`option${index + 1}`],
      options: updatedOptions,
    });
    setTimeout(() => {
      setQuestionUpdated(true);
    }, 100);
  };

  useEffect(() => {
    if (questionUpdated) {
      if (isSubQuestion) {
        handleQuestionChanges(trueFalseQuestion, subQuestionIndex);
      } else {
        handleQuestionChanges(trueFalseQuestion);
      }
    }
  }, [questionUpdated]);
  return (
    <div className='row'>
      <div className='col-12'>
        <div className='th-16 th-fw-600 th-black pb-2'>
          {isSubQuestion ? `Sub-Question ${subQuestionIndex + 1}` : 'Question'}
        </div>
        <div className='th-editor'>
          {/* <MyTinyEditor
            id={`Truefalse${cuid()}`}
            content={handleTrueFalseContent()}
            handleEditorChange={handleEditorChange}
            setOpenEditor={true}
            placeholder='Question goes here...'
          /> */}
          <div className='py-2 w-100 font-weight-normal'>
            <ReactQuillEditor
              value={handleTrueFalseContent()}
              onChange={handleEditorChange}
              placeholder='Question goes here...'
            />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='p-2 mt-2 th-br-4' style={{ border: '1px solid #d9d9d9' }}>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <label htmlFor='answer' className='text-success th-fw-500'>
                Select Answer
              </label>
            </div>
            <div className='col-md-6 col-12 text-right'></div>
          </div>
          <Radio.Group style={{ width: '100%' }}>
            <div className='row'>
              {trueFalseQuestion?.options
                ?.map((el, index) => el[`option${index + 1}`])
                .map((item, index) => (
                  <div className='col-6' key={index}>
                    <Checkbox
                      id='rightanswer'
                      name={item.title}
                      onChange={(e) => handleOptionChange(e, index)}
                      checked={item.isChecked}
                    >
                      <span className='text-capitalize'>
                        {item.title ? item?.title : item?.optionValue}
                      </span>
                    </Checkbox>
                  </div>
                ))}
            </div>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default TrueFalse;
