import React from 'react';
import {
  Divider,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../style';
import OrchidsLogo from '../../../../assets/images/orchidsLogo1.png';
import moment from 'moment';
import { getArrayValues } from '../../../../utility-functions';
const ChooseTemplate = ({ classes, templateFrom, currentStep }) => {
  return (
    <>
      <div className='row bg-light p-2'>
        <div className='col-6 d-flex align-items-center'>
          <div className='mr-3 bg-white br-10 rounded-6'>
            <img src={OrchidsLogo} alt='OrchidsLogo' className='rounded-6 image' />
          </div>
          <div>
            <div className='school-name'>Orchids International School</div>
            <div className='text-muted'>Powered By Eduvate</div>
          </div>
        </div>
        <div className='col-6 d-flex flex-column align-items-end'>
          <div className='p-2 rounded mb-2 w-75 d-flex bg-custom'>
            <div>Name:</div>
          </div>
          <div className='p-2 rounded mb-2 w-75 d-flex bg-custom'>
            <div>ERP:</div>
          </div>
        </div>
      </div>
      <div className='row p-2 mb-1 pl-4 pr-4 justify-content-between bg-custom'>
        <div className='d-flex flex-row'>
          <div className='font-weight-bold'> {templateFrom?.grade?.grade_name}</div>
        </div>
        <div className='d-flex flex-row'>
          <div className='font-weight-bold mr-1'>Subject:</div>
          <div>{templateFrom?.subject?.subject?.subject_name}</div>
        </div>
        <div className='d-flex flex-row'>
          <div className='font-weight-bold mr-1'>Duration:</div>
          <div>{templateFrom?.duration} Min</div>
        </div>
        <div className='d-flex flex-row'>
          <div className='font-weight-bold mr-1'>Total Marks:</div>
          <div> {templateFrom?.total_marks}</div>
        </div>
      </div>
      <div className='row p-2 mb-2 pl-4 pr-4 bg-custom border-bottom-custom pb-0'>
        <div className='d-flex flex-row align-items-center'>
          <div className='font-weight-bold mr-1'>Date:</div>
          <div style={{ fontSize: '13px' }}>
            {moment(new Date()).format('DD.MM.YYYY')}
          </div>
        </div>
      </div>
      <div className='mb-1 rounded d-flex flex-column pl-4 pr-4 p-1 bg-custom'>
        <div className='font-weight-bold'>General Instructions</div>
        <p style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
          {templateFrom?.instruction}
        </p>
      </div>
      <TableContainer style={{ backgroundColor: '#fff' }}></TableContainer>
      {templateFrom?.section?.map((eachSection, sectionIndex) => {
        const sectionTotalMarks = eachSection.question.reduce(
          (total, question) => total + parseFloat(question.marks || 0),
          0
        );
        const sectionQuestion = Math.ceil(sectionTotalMarks / eachSection?.totalQuestion);
        return (
          <div
            key={sectionIndex}
            style={{
              width: '100%',
              margin: '10px 0',
              border: '1px solid #F2F2F2',
            }}
          >
            <div className='py-2 px-4 rounded bg-custom'>
              <div className='d-flex align-items-center position-relative'>
                <div className='font-weight-bold bold text-center flex-fill'>
                  {eachSection?.header.replace(/\d+/, (match) =>
                    String.fromCharCode(64 + parseInt(match))
                  )}
                </div>
                <div className='font-weight-bold position-absolute section-mark'>
                  {eachSection?.totalQuestion && sectionQuestion
                    ? `Marks: ${eachSection?.totalQuestion} × ${sectionQuestion}M = ${sectionTotalMarks}M`
                    : '0M'}
                </div>
              </div>
              <div>{eachSection?.description}</div>
            </div>
            <div style={{ padding: '10px 10px' }}>
              <TableContainer className={classes.chooseTemplateTableContainer}>
                <Table size='small' className={classes.chooseTemplateTable}>
                  <TableHead className={classes.chooseTemplateTableHead}>
                    <TableRow className={classes.chooseTemplateTableRow}>
                      <TableCell className={classes.chooseTemplateCell}>Qns.</TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Blooms Level
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Difficulty Level
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Q. Type
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>
                        Q. Sub Type
                      </TableCell>
                      <TableCell className={classes.chooseTemplateCell}>Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eachSection?.question?.map((eachQuestion, questionIndex) => {
                      return (
                        <TableRow
                          key={questionIndex}
                          className={classes.chooseTemplateTableRow}
                        >
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {questionIndex + 1}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.bloom?.category_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.difficult?.level_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.type?.type_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.subType?.type_name}
                          </TableCell>
                          <TableCell
                            align='center'
                            className={classes.chooseTemplateCell}
                          >
                            {eachQuestion?.marks}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default withStyles(styles)(ChooseTemplate);
