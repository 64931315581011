import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, useTheme, IconButton, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';
import useStyles from './useStyles';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import '../../lesson-plan-view/lesson.css';
import downloadAll from '../../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import { useHistory } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const PeriodCard = ({
  period,
  setPeriodDataForView,
  setViewMoreData,
  setViewMore,
  viewMore,
  filterDataDown,
  setLoading,
  index,
  periodColor,
  setPeriodColor,
  setSelectedIndex,
  setEditperiod,
  loading,
}) => {
  const themeContext = useTheme();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();

  const [downloadProgress, setDownloadProgress] = useState(0);

  const downloadFile = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error downloading file:', error);
      return null;
    }
  };
  const handleBulkDownload = async () => {
    setLoading(true);
    let downloadedFiles = 0;
    let totalFiles = 0;

    try {
      const result = await axiosInstance.get(
        `${endpoints.lessonPlan.periodCardData}?lesson_plan_id=${period.id}&config=True`
      );

      if (result.data.status_code === 200 && result.data.result?.length > 0) {
        const zip = new JSZip();
        const files = result.data.result;
        const downloadPromises = [];

        // Count total files
        totalFiles = files.reduce((acc, item) => acc + (item.media_file?.length || 0), 0);

        if (totalFiles === 0) {
          setAlert('error', 'No files to download');
          setLoading(false);
          return;
        }

        // Create folders and add files to zip
        files.forEach((item) => {
          if (item.media_file && item.media_file.length > 0) {
            item.media_file.forEach((fileUrl) => {
              const fileName = fileUrl.split('/').pop();
              const folder = zip.folder(item.document_type);

              downloadPromises.push(
                downloadFile(endpoints.erps3 + fileUrl).then((blob) => {
                  if (blob) {
                    folder.file(fileName, blob);
                    downloadedFiles++;
                    // Update progress
                    const progress = Math.round((downloadedFiles / totalFiles) * 100);
                    setDownloadProgress(progress);
                    setAlert('info', `Downloading: ${progress}%`);
                  }
                })
              );
            });
          }
        });

        // Wait for all downloads to complete
        await Promise.all(downloadPromises);

        // Generate and download zip file with the new naming convention
        const content = await zip.generateAsync({ type: 'blob' });

        // Create the file name using filterDataDown
        const gradeName = filterDataDown?.grade?.grade_name || '';
        const subjectName = filterDataDown?.subject?.subject?.subject_name || '';
        const keyConceptName = filterDataDown?.keyconcept?.topic_name || '';

        // Clean up the names to remove any special characters and spaces
        const cleanFileName = `${gradeName}_${subjectName}_${keyConceptName}`
          .replace(/[^a-zA-Z0-9_]/g, '_') // Replace special characters with underscore
          .replace(/_+/g, '_') // Replace multiple underscores with single underscore
          .toLowerCase();

        saveAs(content, `${cleanFileName}.zip`);

        setAlert('success', 'Files downloaded successfully');
      } else {
        setAlert('error', 'No data available');
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert('error', 'Error downloading files');
    } finally {
      setLoading(false);
      setDownloadProgress(0);
    }
  };
  const handleViewMore = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${endpoints.lessonPlan.periodCardData}?lesson_plan_id=${period.id}&config=True`
      )
      .then((result) => {
        console.log(result, 'res1');
        if (result.data.status_code === 200) {
          if (result.data.result?.length == 0) {
            setAlert('error', 'No Data');
          }
          setLoading(false);
          setViewMore(true);
          setViewMoreData(result.data.result);
          setPeriodDataForView(period);
          setSelectedIndex(index);
          setPeriodColor(true);
        } else {
          setLoading(false);
          setViewMore(false);
          setViewMoreData({});
          setPeriodDataForView();
          setAlert('error', result.data.message);
          setSelectedIndex(-1);
          setPeriodColor(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setViewMore(false);
        setViewMoreData({});
        setPeriodDataForView();
        setAlert('error', error.message);
        setSelectedIndex(-1);
        setPeriodColor(true);
      });
  };

  return (
    <Paper
      className={periodColor ? classes.selectedRoot : classes.root}
      style={isMobile ? { margin: '0rem auto' } : { margin: '0rem auto -1.1rem auto' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <Typography
              className={classes.title}
              variant='p'
              component='p'
              color='primary'
            >
              {period.period_name}
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classes.content}
              variant='p'
              component='p'
              color='secondary'
              noWrap
            >
              {filterDataDown?.chapter?.chapter_name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.textRight}>
          <IconButton
            color='primary'
            onClick={handleBulkDownload}
            disabled={loading}
            title='Download All Files'
          >
            <GetAppIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} />
        <Grid item xs={6}>
          <Box>
            <Typography
              className={classes.title}
              variant='p'
              component='p'
              color='secondary'
            >
              Last Updated On
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classes.content}
              variant='p'
              component='p'
              color='secondary'
            >
              {new Date(period.updated_at).toLocaleDateString('in')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.textRight}>
          {!periodColor && (
            <Button
              variant='contained'
              style={{ color: 'white' }}
              color='primary'
              className='custom_button_master modifyDesign'
              size='small'
              onClick={handleViewMore}
              disabled={loading}
            >
              VIEW MORE
            </Button>
          )}
        </Grid>
      </Grid>
      {downloadProgress > 0 && downloadProgress < 100 && (
        <Box
          position='absolute'
          bottom={0}
          left={0}
          width={`${downloadProgress}%`}
          height={2}
          bgcolor='primary.main'
        />
      )}
    </Paper>
  );
};

export default PeriodCard;
