import ENVCONFIG from './config';

const {
  s3: { BUCKET: s3BUCKET, ERP_BUCKET, Ibook_CDN },
} = ENVCONFIG;

export default {
  auth: {
    login: '/central-admin/user-sign-in/',
  },
  gloabSearch: {
    getUsers: '/erp_user/global-search/',
    singleUser: '/erp_user/',
  },
  school: {
    listSchool: '/central-admin/school_list/',
    addSchool: '/central-admin/school_registration/',
    updateSchool: '/central-admin/update_school/',
    activateSchool: '/central-admin/school_activated/',
    deactivateSchool: '/central-admin/school_deactivated_reactive/',
    deleteSchool: '/central-admin/school_delete/',
    verifyDomain: '/central-admin/verify_domain_name/',
  },
  user: {
    getRoles: '/central-admin/roles/',
    createUsers: '/central-admin/employee-view/',
    viewUsers: '/central-admin/employee-view/',
    centralAdminCommon: '/central-admin/',
    userProfile: '/central-admin/user-profile/',
    changePassword: '/central-admin/change-password/',
    updateProfile: '/central-admin/user-profile/',
    getUserLevel: '/central-admin/user_level_list/',
    addUserLevel: '/central-admin/user_level_create/',
    getUserLevelV2: '/central-admin/user_level_list_v2/',
    addDesignationInEdit: '/central-admin/create-designation/',
  },
  zoom: {
    listLicense: '/central-admin/list_zoom_license/',
    createLicense: '/central-admin/add_zoom_license/',
    assignLicense: '/central-admin/active_school/',
    assignedLicense: '/central-admin/active_school_zoom_license/',
    editAssignLicense: '/central-admin/distribute_zoom_licence/',
  },
  mapping: {
    schoolList: '/lesson_plan/school-mapping/',
    schoolListDropdown: '/lesson_plan/school-list/',
    gradelist: '/lesson_plan/list-grade/',
    subjectList: '/lesson_plan/list-grade-subject-mapping/',
    deleteSubject: '/lesson_plan/<school_id>/delete-school-mapping-subject/', // ?subject_id=26
    deleteSubjectV2: '/lesson_plan/delete-school-mapping-subject-v2/',
  },
  masterManagement: {
    subjects: '/lesson_plan/list-subject/',
    createSubject: '/lesson_plan/create-subject/',
    updateSubject: '/lesson_plan/update-subject/',
    grades: '/lesson_plan/list-grade/',
    board: '/central-admin/boards/',
    createboard: '/central-admin/boards/',
    module: '/lesson_plan/lt-module/',
    createUpdatemodule: '/lesson_plan/lt-module-create-update/',
    createGrade: '/lesson_plan/create-grade/',
    updateGrade: '/lesson_plan/update-grade/',
    gradesDrop: '/erp_user/grade/',
    gradeSubjectMappingList: '/lesson_plan/list-grade-subject-mapping/',
    boardMappingList: '/central-admin/boards/',
    // sectionsTable: '/erp_user/grades-section-list/',
    // createSection: '/erp_user/create-section/',
    // updateSection: '/erp_user/update-section/',
    // sections: '/erp_user/sectionmapping/',
    academicYear: '/lesson_plan/list-session/',
    versionDataOld: '/central-admin/cnt-mpng-enable/',
    versionData: '/lesson_plan/version-content/',
    updateAcademicYear: '/lesson_plan/update-session/',
    createAcademicYear: '/lesson_plan/create-session/',
    // messageTypeTable: '/communication/communicate-type/',
    // updateMessageType: '/communication/',
    createTopic: '/assessment/topic/',
    updateTopic: '/assessment/',
    volumes: '/lesson_plan/list-volume/',
    createVolumes: '/lesson_plan/create-volume/',
    updateVolumes: '/lesson_plan/update-volume/',
    chapters: '/lesson_plan/chapter/',
    createChapters: '/lesson_plan/chapter/',
    updateChapters: '/lesson_plan/',
    gradeSubjectMapping: '/lesson_plan/list-grade-subject-mapping/',
    createGradeSubjectMapping: '/lesson_plan/create-grade-subject-mapping/',
    updateGradeSubjectMapping: '/lesson_plan/update-grade-subject-mapping/',
    periods: '/lesson_plan/period/',
    createPeriods: '/lesson_plan/period/',
    updatePeriod: '/lesson_plan/',
    chapterTable: '/lesson_plan/chapters-list/',
    keyConceptTableData: '/assessment/topic/',
    moduleList: '/lesson_plan/ciriculam/lt_module/',
    moduleListUpdate: '/lesson_plan/ciriculam/lt_module/',
    periodData: '/lesson_plan/ciriculam/period/',
    periodUpdate: '/lesson_plan/ciriculam/period/',
    topicUpdate: '/assessment/ciriculam/topic/',
    chapterUpdate: '/lesson_plan/ciriculam/chapter/',
    chapterData: '/lesson_plan/ciriculam/chapter/',
    keyConceptData: '/assessment/ciriculam/topic/',
  },
  centralManagement: {
    schoolList: '/lesson_plan/school-list/',
    schoolModule: '/central-admin/school-module-mapping/',
    activateModule: '/central-admin/activate-module/',
    removeModule: '/central-admin/remove-module/',
    volumeChapter: '/v2/assessment/chapter-volume-details/',
  },
  lessonPlan: {
    periodData: '/lesson_plan/chapter-period/',
    periodCardData: '/lesson_plan/lesson/',
    academicYearList: '/lesson_plan/list-session/',
    volumeList: '/lesson_plan/list-volume/',
    gradeList: '/lesson_plan/list-grade/',
    gradeSubjectMappingList: '/lesson_plan/list-grade-subject-mapping/',
    chapterList: '/lesson_plan/chapter/',
    bulkDownload: '/lesson_plan/bulk_download/',
    overviewSynopsis: '/lesson_plan/list-lesson-overview/',
    lessonFeedback: '/lesson_plan/lessonplan-feedback-list/',
    plannerVideos: '/central-admin/qr-code/',
    qrFileUpload: '/central-admin/qr-file-upload/',
  },
  questionBank: {
    grades: '/lesson_plan/list-grade/',
    subjects: '/lesson_plan/list-grade-subject-mapping/',
    topics: '/assessment/topic/',
    examType: '/assessment/question_type/',
    questionData: '/assessment/question-list/',
    questionPaperPreview: `/assessment/<question-paper-id>/qp-questions-list/`,
    questionPaper: '/assessment/question-paper-details/',
    downloadReport: '/assessment/question-report/',
    viewMoreData: '/assessment/question-details/',
    uploadFile: '/assessment/upload-question-file/',
    removeFile: '/assessment/delete-s3file/',
    deleteQuestion: '/assessment/publish-question/',
    multiPublishQuestion: '/assessment/publish-multiple-questions/',
    chapterKeyConcept: '/assessment/chapter-topic-details/',
  },
  assementQP: {
    assementFilter: '/assessment/list-question-paper/',
    assementFilterData: '/assessment/list-question-paper/',
    assementSubjectMap: '/lesson_plan/list-grade-subject-mapping/',
    assementViewmore: '/assessment/<question-paper-id>/qp-questions-list/',
    editQuestionPaper: '/assessment/<question-paper-id>/update-question-paper/',
    publishQuestionPaper: `/assessment/<question-paper-id>/update-status-question-paper/`,
    bloomsDifficultyTypeSubType: '/v2/assessment/bloom-filters-details/',
    bloomsDifficultyTypeSubTypeAccordingSubjectChapter:
      '/v2/assessment/bloom-filters-v2/',
    qpTemplate: '/v2/assessment/auto-paper-template/',
    autoqp: '/v2/assessment/auto-question-paper/',
    listAutoQp: '/v2/assessment/auto-question-paper/',
    questionPaperStatusChange: '/v2/assessment/update-auto-qp-status/',
    regenerateQuestion: '/v2/assessment/random-question-generation/',
    regenerateManualQuestion: '/v2/assessment/filter-questions/',
    bulkTemplateDataDownload: '/v2/assessment/bulk-template-data-download/',
    bulkQuestionFormatDownload: '/v2/assessment/bulk-upload-response/',
    bulkQuestionFormatUpload: '/v2/assessment/bulk-upload-qv2-template/',
  },
  createQuestionApis: {
    topicList: '/assessment/topic/',
    questionType: '/assessment/question_type/',
    createQuestion: '/assessment/create-question/',
    createQuestionv1: '/assessment/v1/create-question/',
  },
  createQuestionBulk: {
    BulkUploadTrueOrFalse: '/assessment/bulk_upload_tf/',
    BulkUploadSingleChoiceQuestion: '/assessment/upload-sc-questions/',
    FillBlankUploadQuestion: '/assessment/fill_bulk_upload/',
    AllTypeQuestions: '/assessment/upload-all-questions/',
    downloadDataFile: '/v2/assessment/question-v2-data-download/',
    bulkUpload: '/v2/assessment/bulk-upload-questions-v2/',
  },

  ebook: {
    ebook: '/ebook/ebook/',
    EbookUser: '/ebook/ebook_user/',
    AnnotateEbook: '/ebook/ebook_user_status/',
    academicYearList: '/lesson_plan/list-session/',
    gradeSubjectMappingList: '/lesson_plan/list-grade-subject-mapping/',
    gradeList: '/lesson_plan/list-grade/',
    undoEbookApi: '/ebook/undo_ebook/',
    volumes: '/lesson_plan/list-volume/',
  },
  ibook: {
    ibook: '/ibook/list-all-books/',
    addbook: '/ibook/add-book/',
    bookList: '/ibook/central-admin-books-chapters-list/',
    addChapter: '/ibook/add-chapter/',
    allChapterList: '/ibook/list-all-chapter/',
    editbook: '/ibook/',
    editChapter: '/ibook/',
  },
  periods: {
    createUpdatePeriodName: '/lesson_plan/period_update_delete/',
    questionList: `lesson_plan/list-question-paper/`,
  },
  clickerQuestion: {
    questionType: '/assessment_v2/question-paper-categories/',
    fileUpload: '/assessment_v2/upload-media-file/',
    clickerQuestions: '/assessment_v2/question/',
    clickerQuestionsPaper: '/assessment_v2/question-paper/',
  },
  s3: s3BUCKET,
  erps3: ERP_BUCKET,
  ibookCDN: Ibook_CDN,
};
