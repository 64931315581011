import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Layout from '../Layout/index';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import SubjectCard from './subjects-card/index';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import Createnew from './createNew';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { Pagination } from '@material-ui/lab';

import { generateQueryParamSting } from '../../utility-functions';
// import { message } from 'antd';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    boxSizing: 'border-box',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btn: {
    margin: 3,
  },
  clrBtn: {
    margin: 3,
    color: 'rgb(140, 140, 140) !important',
    //  !important
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const modulesArray = [
  { id: 'lesson-plan', label: 'Periods', key: 'is_lesson_plan', value: true },
  { id: 'assessment', label: 'Assessment', key: 'is_assessment', value: true },
  { id: 'ebook', label: 'Ebook', key: 'is_ebook', value: true },
  { id: 'ibook', label: 'Ibook', key: 'is_ibook', value: true },
];

const Mapping = () => {
  const themeContext = useTheme();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();
  const theme = useTheme();
  const [schoolMappingList, setSchoolMappingList] = React.useState([]);
  const [schools, setSchools] = React.useState([]);
  const [selectedSchool, setSchool] = React.useState(null);
  const [modules] = React.useState(modulesArray);
  const [selectedModule, selectModule] = React.useState([]);
  const [resetFlag, setResetFlag] = useState(false);
  const [sessionYear, setSessionYear] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [pageNo, setPageNo] = React.useState(1);
  const pageSize = 12;

  const getSchoolListDropdown = () => {
    axiosInstance
      .get(endpoints.mapping.schoolListDropdown)
      .then((res) => {
        setSchools(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getVersion = async () => {
    setLoading(true);
    const { id: schoolId } = selectedSchool || {};
    const { key, value } = selectedModule || {};
    const queryString = generateQueryParamSting({
      school: schoolId,
      [key]: value,
      page_size: pageSize,
      page: pageNo,
    });
    await axiosInstance
      .get(`${endpoints.masterManagement.versionData}?${queryString}`)
      .then((result) => {
        if (result?.data?.status_code == 200) {
          setLoading(false);
          setCardData(result?.data?.result?.result);
          setTotalCount(result?.data?.result?.count);
          setPageNo(result?.data?.result?.current_page);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    getVersion();
  }, []);

  const clear = (clear) => {
    selectModule([]);
    setSchool(clear);
  };

  const handleModuleChange = (event, value) => {
    setPageNo(1);
    if (value) {
      selectModule(value);
    } else {
      selectModule('');
    }
  };
  const handleSchoolChange = (event, value) => {
    setPageNo(1);
    selectModule([]);
    setResetFlag(true);
    setSessionYear(null);
    if (value) {
      setSchool(value);
    } else {
      setSchool('');
    }
  };

  const handleResetData = () => {
    selectModule([]);
    setResetFlag(true);
    setSessionYear(null);
    setSchool('');
  };

  const handlePagination = (e, page) => {
    setPageNo(page);
  };

  // const callFilter = () => {
  //         const {id: schoolId} = selectedSchool||{}
  //         const { key, value }= selectedModule||{}
  //         const queryString = generateQueryParamSting({school:schoolId, [key]:value })
  //         const apiURL = `${endpoints.mapping.schoolList}?${queryString}`
  //         axiosInstance.get(apiURL).then(res => {
  //             setSchoolMappingList(res.data.result)
  //         }).catch(err => {
  //             console.log(err)
  //         })

  // }
  useEffect(() => {
    // getschoolMappingList();
    getVersion();
  }, [selectedSchool, selectedModule, pageNo]);

  useEffect(() => {
    getSchoolListDropdown();
    // getschoolMappingList();
  }, []);

  return (
    <Layout>
      <>
        <div style={{ margin: 20 }}>
          <CommonBreadcrumbs componentName='Content Mapping' />
          <form className={classes.root} noValidate autoComplete='off'>
            <Grid container spacing={2} alignItems='center' justify='flex-start'>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={`${classes.formControl} select-form`}>
                  <Autocomplete
                    value={selectedSchool}
                    id='tags-outlined'
                    className='dropdownIcon'
                    options={schools}
                    getOptionLabel={(option) => option.school_name}
                    filterSelectedOptions
                    size='small'
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='School' />
                    )}
                    onChange={handleSchoolChange}
                    getOptionSelected={(option, value) => value && option.id == value.id}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={`${classes.formControl} select-form`}>
                  <Autocomplete
                    options={modules}
                    getOptionLabel={(option) => option.label}
                    value={selectedModule}
                    id='tags-outlined'
                    className='dropdownIcon'
                    filterSelectedOptions
                    size='small'
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' label='Module' />
                    )}
                    onChange={handleModuleChange}
                    getOptionSelected={(option, value) => value && option.id == value.id}
                  />
                </FormControl>
              </Grid>

              <Grid container spacing={2} style={{ paddingLeft: '10px' }}>
                <Grid item md={1} xs={4}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={getVersion}
                    // onClick={() => handleFilter()}
                    className={classes.btn}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid item md={2} xs={4}>
                  <Button
                    variant='contained'
                    color='default'
                    onClick={() => clear(null)}
                    className={classes.clrBtn}
                  >
                    Clear All
                  </Button>
                </Grid>
                <Grid item md={2} xs={4}>
                  <Createnew
                    schools={schools}
                    selectedSchool={selectedSchool}
                    handleSchoolChange={handleSchoolChange}
                    modules={modules}
                    selectedModule={selectedModule}
                    handleModuleChange={handleModuleChange}
                    setSchool={setSchool}
                    selectModule={selectModule}
                    resetFlag={resetFlag}
                    setResetFlag={setResetFlag}
                    sessionYear={sessionYear}
                    setSessionYear={setSessionYear}
                    handleResetData={handleResetData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
          <SubjectCard
            schoolList={schoolMappingList}
            selectedSchool={selectedSchool}
            selectedModule={selectedModule}
            cardData={cardData}
            setCardData={setCardData}
            loading={loading}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            pageSize={pageSize}
            getVersion={getVersion}
          />
        </div>
        <Grid xs={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
          <Pagination
            onChange={handlePagination}
            count={Math.ceil(totalCount / pageSize)}
            color='primary'
            page={pageNo}
            style={{ paddingLeft: '45%' }}
          />
        </Grid>
      </>
    </Layout>
  );
};

export default Mapping;
