/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../../Layout';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import endpoints from '../../../config/endpoints';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import axiosInstance from '../../../config/axios';
// import EditSubject from './edit-subject'
import Loading from '../../../components/loader/loader';
import AddUserDialog from './adduserlevel';
import RestoreIcon from '@material-ui/icons/Restore';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90vw',
    width: '95%',
    margin: '20px auto',
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  container: {
    maxHeight: '100%',
    maxWidth: '90vw',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
}));

const columns = [
  {
    id: 'User Level',
    label: 'User Level',
    minWidth: 100,
    align: 'left',
    labelAlign: 'left',
  },
  {
    id: 'User Level Name',
    label: 'User Level Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'User Level Description',
    label: 'User Level Description',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'User Level Designation',
    label: 'User Level Designation',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'Edit',
    label: 'Edit',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'Delete',
    label: 'Restore/Delete',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
];

const UserLevelTable = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [pageNo, setPageNo] = useState(1);
  const [userData, setUserData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [opt, setOpt] = useState(false);
  const [limit, setLimit] = useState(5);
  const [goBackFlag, setGoBackFlag] = useState(false);
  const [licenseList, setLicenseList] = useState([]);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedata, SetDeleteData] = useState('');

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleEditSubject = (user) => {
    setOpenDialog(true);
    setEditData(user);
    setIsEdit(true);
  };

  useEffect(() => {
    getUserLevel_V2();
  }, [pageNo]);

  const getUserLevel_V2 = () => {
    axiosInstance
      .get(`${endpoints.user.getUserLevelV2}?page=${pageNo}&page_size=${limit}`)
      .then((result) => {
        console.log(result?.data, 'datauser');
        setUserData(result?.data?.result?.results);
        setTotalCount(result?.data?.result?.count);
      })
      .catch((error) => {
        setAlert('error', 'failed to fetch');
      });
  };

  const handleDelete = (user) => {
    setDeleteModal(true);
    SetDeleteData(user);
  };

  const confirmDelete = () => {
    axiosInstance
      .delete(`/central-admin/${deletedata?.id}/user_level/`)
      .then((result) => {
        console.log(result);
        setAlert('success', 'Deleted Successfully');
        setDeleteModal(false);
        // getUserLevel();
        getUserLevel_V2();
      })
      .catch((error) => {
        setDeleteModal(false);
        setAlert('error', 'Failed to Delete');
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
    setIsEdit(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
    getUserLevel_V2();
  };

  const RestoreLevel = (user) => {
    const data = { is_delete: false };
    axiosInstance
      .put(`/central-admin/${user?.id}/user_level/`, data)
      .then((result) => {
        console.log(result);
        setAlert('success', 'Restored Successfully');
        getUserLevel_V2();
      })
      .catch((error) => {
        setAlert('error', 'Failed to Delete');
      });
  };

  const handlePagination = (e, page) => {
    setPageNo(page);
  };

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div>
          <div style={{ width: '95%', margin: '20px auto' }}>
            <CommonBreadcrumbs
              componentName='User Management'
              childComponentName='User Level'
            />
          </div>
        </div>

        <Grid item xs={12} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
          <Button
            startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
            variant='contained'
            color='primary'
            size='small'
            style={{ color: 'white' }}
            title='Add License'
            onClick={handleClickOpen}
          >
            Add User Level
          </Button>
        </Grid>

        <Paper className={`${classes.root} view_user_table_wrapper`}>
          <TableContainer
            className={`table view_user_table table-shadow ${classes.container}`}
          >
            <Table stickyHeader aria-label='sticky table'>
              <TableHead className='view_groups_header'>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.columnHeader}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData &&
                  userData?.map((user, index) => {
                    return (
                      <TableRow hover license='checkbox' tabIndex={-1} key={index}>
                        <TableCell className={classes.tableCell}>{user?.id}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {user?.level_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {user?.description}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {user?.designation?.map((item, index) => (
                            <React.Fragment key={index}>
                              {item.designation}
                              {index !== user.designation.length - 1 && ' / '}{' '}
                            </React.Fragment>
                          ))}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <IconButton
                            onClick={() => handleEditSubject(user)}
                            title='Edit Subject'
                          >
                            <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {user?.is_delete ? (
                            <IconButton
                              title='Restore User Level'
                              onClick={(e) => {
                                RestoreLevel(user);
                              }}
                            >
                              <RestoreIcon style={{ color: '#fe6b6b' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={(e) => {
                                handleDelete(user);
                              }}
                              title='Delete User Level'
                            >
                              <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid xs={12} md={12} style={{ textAlign: 'center', marginTop: '5px' }}>
          <Pagination
            onChange={handlePagination}
            count={Math.ceil(totalCount / limit)}
            color='primary'
            page={pageNo}
            style={{ paddingLeft: '40%' }}
          />
        </Grid>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='draggable-dialog-title'
        >
          <DialogTitle
            style={{ cursor: 'move', color: '#014b7e' }}
            id='draggable-dialog-title'
          >
            Delete Level
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{`Confirm Delete Level ${deletedata?.level_name} ?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} className='labelColor cancelButton'>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={confirmDelete}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
      <AddUserDialog
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        openDialog={openDialog}
        isEdit={isEdit}
        editData={editData}
      />
    </>
  );
};

export default UserLevelTable;
