import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  withStyles,
  Box,
  IconButton,
  Divider,
  ButtonGroup,
  Icon,
  FormControl,
  Select,
  Tooltip,
  Chip,
  SvgIcon,
} from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import unfiltered from '../../../../assets/images/unfiltered.svg';
import styles from './createTemplate.style';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  LoopOutlined,
  RemoveCircleOutline,
  Send,
  ShuffleSharp,
  BorderColorSharp,
  Visibility,
  CachedOutlined,
  Add,
} from '@material-ui/icons';
// import "../../cssStyles/page3.css";
// import { Modal } from "../../../../utility/ui";
// import useWindowDimensions from "../../../../utility/customHooks/useWindowDimensions";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Modal } from "@mui/material";
import Modal from '../../../../components/commonModal';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { getArrayValues } from '../../../../utility-functions';
import QuestionView from './questionView';
import Loading from '../../../../components/loader/loader';
import { Spin } from 'antd';

const CreateSection = ({
  classes,
  dataList,
  templateFrom,
  setTemplateFrom,
  questionCountConfig,
  goToStep,
  onChange,
  currentStep,
  checkNextPage,
  totalMarks,
  questionHistory,
  regenerate,
  regenerateQuestion,
  regenerateQuestionManual,
  changeQuestionGenerate,
  handlePaperForward,
  handlePaperBackward,
  handleExportOptions,
  handleIncrement,
  handleDecrement,
  history,
  isPreviousPageTemplate,
}) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [eachQuestionPreview, setEachQuestionPreview] = useState({
    open: false,
    data: null,
  });
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState({
    open: false,
    sectionIndex: null,
    questionIndex: null,
  });
  const [regenerateQuestionModalOpen, setRegenerateQuestionModalOpen] = useState({
    open: false,
    sectionIndex: null,
    questionIndex: null,
  });
  const [
    regenerateManualQuestionModalOpen,
    setRegenerateManualQuestionModalOpen,
  ] = useState({
    open: false,
    sectionIndex: null,
    questionIndex: null,
    selectedData: null,
    filterData: null,
    allData: {
      totalCount: null,
      totalPage: null,
      currentPage: 1,
      // pageSize:10,
      data: null,
    },
  });
  const [loading, setLoading] = useState(false);

  // const { width } = useWindowDimensions();
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));

  let questionNumbering = 0;
  const [myQuestionConfigData, setMyQuestionConfigData] = useState(null);

  useEffect(() => {
    if (questionCountConfig && questionCountConfig?.data) {
      setMyQuestionConfigData(
        questionCountConfig.data?.map((item) => {
          return JSON.parse(item);
        })
      );
    }
  }, [questionCountConfig]);

  useEffect(() => {
    console.log(
      {
        dataList,
        templateFrom,
        currentStep,
        totalMarks,
        questionHistory,
        history,
        eachQuestionPreview,
        deleteQuestionModalOpen,
        regenerateQuestionModalOpen,
        questionCountConfig,
      },
      'alllog page3'
    );
  });

  const getRegenerateManualQuestion = (data = {}) => {
    let params = {
      chapter: data?.chapter?.id,
      grade_subject_mapping: templateFrom?.subject?.id,
      question_categories: data?.bloom?.id,
      question_level: data?.difficult?.id,
      question_type: data?.type?.id,
      question_type__sub_type: data?.subType?.id,
      question_id: getArrayValues(
        getArrayValues(
          getArrayValues(templateFrom?.section, 'question')?.flat(),
          'question'
        ),
        'id'
      )
        ?.filter((each) => each)
        ?.join(','),
      page: data?.page
        ? data?.page
        : regenerateManualQuestionModalOpen?.allData?.currentPage,
    };
    setLoading(true);
    axiosInstance
      .get(`${endpoints?.assementQP?.regenerateManualQuestion}`, {
        params: { ...params },
      })
      .then((responce) => {
        if (responce.data.status_code === 200) {
          setRegenerateManualQuestionModalOpen({
            ...regenerateManualQuestionModalOpen,
            open: true,
            sectionIndex: data?.sectionIndex,
            questionIndex: data?.questionIndex,
            selectedData: null,
            filterData: data,
            allData: {
              ...regenerateManualQuestionModalOpen?.allData,
              totalCount: responce.data.result.count,
              totalPage: Math.ceil(responce.data.result.count / 10),
              currentPage: data?.page
                ? data?.page
                : regenerateManualQuestionModalOpen?.allData?.currentPage,
              // pageSize:10,
              data: responce.data.result.results,
            },
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const isEdit = () => {
    return templateFrom.hasOwnProperty('id');
  };

  let eachQuestionPreviewModal = null;
  if (eachQuestionPreview.open) {
    eachQuestionPreviewModal = (
      <Modal
        open={eachQuestionPreview.open}
        // click={() =>
        //   setEachQuestionPreview({
        //     ...eachQuestionPreview,
        //     data: null,
        //     open: false,
        //   })
        // }
        large
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <>
          <div>
            <div
              style={{
                backgroundColor: 'rgb(31 124 237)',
                padding: '5px 15px',
              }}
            >
              <Grid container spacing={1} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography
                    className='font-size-16 font-weight-600'
                    style={{ color: 'white' }}
                  >
                    Preview Question
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    style={{ color: 'white' }}
                    onClick={() =>
                      setEachQuestionPreview({
                        ...eachQuestionPreview,
                        data: null,
                        open: false,
                      })
                    }
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: '15px' }}>
              {eachQuestionPreview?.data?.question && (
                <QuestionView
                  question={eachQuestionPreview?.data?.question}
                  showAns={
                    !eachQuestionPreview?.data?.question?.sub_questions?.length > 0
                  }
                />
              )}
              {eachQuestionPreview?.data?.question?.sub_questions?.length > 0 ? (
                <>
                  {eachQuestionPreview?.data?.question?.sub_questions?.map(
                    (eachSub_questions, sub_questionsIndex) => {
                      return (
                        <>
                          <Typography
                            className='font-size-13 font-weight-600 mt-3 mb-2'
                            // style={{ marginTop: '15px' }}
                          >
                            Sub Question {sub_questionsIndex + 1} :
                          </Typography>
                          <QuestionView question={eachSub_questions} showAns={true} />
                        </>
                      );
                    }
                  )}
                </>
              ) : (
                <></>
              )}

              <Grid
                container
                spacing={2}
                justify='space-between'
                style={{
                  marginTop: '40px',
                  textAlign: 'center',
                  fontSize: '13px',
                }}
              >
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#C41D7F',
                      backgroundColor: '#FFF0F6',
                      border: '1px solid #FFADD2',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.bloom?.category_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#D46B08',
                      backgroundColor: '#FFF7E6',
                      border: '1px solid #FFD591',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.difficult?.level_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={4} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#0958D9',
                      backgroundColor: '#E6F4FF',
                      border: '1px solid #91CAFF',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.type?.type_name}</span>
                  </div>
                </Grid>
                <Grid item md={4} sm={9} xs={8}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#389E0D',
                      backgroundColor: '#F6FFED',
                      border: '1px solid #B7EB8F',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>{eachQuestionPreview?.data?.subType?.type_name}</span>
                  </div>
                </Grid>
                <Grid item md={2} sm={3} xs={4}>
                  <div
                    style={{
                      padding: '4px 10px',
                      color: '#08979C',
                      backgroundColor: '#E6FFFB',
                      border: '1px solid #87E8DE',
                      borderRadius: '10px',
                      // width:"100%"
                    }}
                  >
                    <span>
                      {eachQuestionPreview?.data?.marks}{' '}
                      {parseInt(eachQuestionPreview?.data?.marks) > 1 ? 'marks' : 'mark'}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  let deleteQuestionModal = null;
  if (deleteQuestionModalOpen?.open) {
    deleteQuestionModal = (
      <Modal
        open={deleteQuestionModalOpen?.open}
        click={() =>
          setDeleteQuestionModalOpen({
            open: false,
            sectionIndex: null,
            questionIndex: null,
          })
        }
        small={!isMobile && 768}
        medium={isMobile && 768}
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <div style={{ padding: '15px' }}>
          <Typography variant='body1' style={{ margin: '0 0 15px' }}>
            Are you sure to delete this question?
          </Typography>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'transparent' }}
                onClick={() =>
                  setDeleteQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  })
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  handleDecrement(
                    deleteQuestionModalOpen?.sectionIndex,
                    deleteQuestionModalOpen?.questionIndex
                  );
                  setDeleteQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  });
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
  let regenerateQuestionModal = null;
  if (regenerateQuestionModalOpen?.open) {
    regenerateQuestionModal = (
      <Modal
        open={regenerateQuestionModalOpen?.open}
        click={() =>
          setRegenerateQuestionModalOpen({
            open: false,
            sectionIndex: null,
            questionIndex: null,
          })
        }
        small={!isMobile && 768}
        medium={isMobile && 768}
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <div style={{ padding: '15px' }}>
          <Typography variant='body1' style={{ margin: '0 0 15px' }}>
            Are you sure to regenerate this question?
          </Typography>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'transparent' }}
                onClick={() =>
                  setRegenerateQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  })
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  regenerateQuestion({
                    question:
                      templateFrom?.section[regenerateQuestionModalOpen?.sectionIndex]
                        ?.question[regenerateQuestionModalOpen?.questionIndex],
                    sectionIndex: regenerateQuestionModalOpen?.sectionIndex,
                    questionIndex: regenerateQuestionModalOpen?.questionIndex,
                  });
                  setRegenerateQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                  });
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
  let regenerateManualQuestionModal = null;
  if (regenerateManualQuestionModalOpen?.open) {
    regenerateManualQuestionModal = (
      <Modal
        open={regenerateManualQuestionModalOpen?.open}
        click={() =>
          setRegenerateManualQuestionModalOpen({
            open: false,
            sectionIndex: null,
            questionIndex: null,
            selectedData: null,
            filterData: null,
            allData: {
              totalCount: null,
              totalPage: null,
              currentPage: 1,
              // pageSize:10,
              data: null,
            },
          })
        }
        // small={!isMobile && 768}
        // medium={isMobile && 768}
        large
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <div style={{ padding: '15px' }}>
          <Grid container spacing={2}>
            <Grid item style={{ borderBottom: '1px solid' }}>
              <Typography style={{ fontSize: 16, fontWeight: 600 }}>
                Select Question
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            // wrap='nowrap'
            alignItems='center'
            spacing={1}
            style={{ marginTop: '20px', marginBottom: '15px' }}
          >
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {templateFrom?.grade?.grade_name}
              </Typography> */}
              <Chip
                label={templateFrom?.grade?.grade_name}
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {templateFrom?.subject?.subject?.subject_name}
              </Typography> */}
              <Chip
                label={templateFrom?.subject?.subject?.subject_name}
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {getArrayValues(templateFrom?.volume, 'volume_name')?.join(', ')}
              </Typography> */}
              <Chip
                label={getArrayValues(templateFrom?.volume, 'volume_name')?.join(', ')}
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {regenerateManualQuestionModalOpen.filterData?.chapter?.chapter_name}
              </Typography> */}
              <Chip
                label={
                  regenerateManualQuestionModalOpen.filterData?.chapter?.chapter_name
                }
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {regenerateManualQuestionModalOpen.filterData?.bloom?.category_name}
              </Typography> */}
              <Chip
                label={regenerateManualQuestionModalOpen.filterData?.bloom?.category_name}
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {regenerateManualQuestionModalOpen.filterData?.difficult?.level_name}
              </Typography> */}
              <Chip
                label={
                  regenerateManualQuestionModalOpen.filterData?.difficult?.level_name
                }
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {regenerateManualQuestionModalOpen.filterData?.type?.type_name}
              </Typography> */}
              <Chip
                label={regenerateManualQuestionModalOpen.filterData?.type?.type_name}
                color='success'
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Typography>|</Typography>
            </Grid>
            <Grid item>
              {/* <Typography className={classes.regenerateManualQuestionModalFiltertext}>
                {regenerateManualQuestionModalOpen.filterData?.subType?.type_name}
              </Typography> */}
              <Chip
                label={regenerateManualQuestionModalOpen.filterData?.subType?.type_name}
                color='success'
                variant='outlined'
              />
            </Grid>
          </Grid>
          {!loading && (
            <Grid container spacing={1} direction='column'>
              {regenerateManualQuestionModalOpen?.allData?.data?.length > 0 ? (
                regenerateManualQuestionModalOpen?.allData?.data?.map((each, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <div
                        className={`${classes.manualQuestion} ${
                          regenerateManualQuestionModalOpen?.selectedData?.id === each?.id
                            ? classes.manualQuestionSelected
                            : ''
                        }`}
                        onClick={() => {
                          setRegenerateManualQuestionModalOpen({
                            ...regenerateManualQuestionModalOpen,
                            selectedData: each,
                          });
                        }}
                      >
                        <Grid container justify='space-between' wrap='no-wrap'>
                          <Grid item xs={9} md={10}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: each?.question_answer?.length
                                  ? each?.question_answer[0]?.question
                                  : null,
                              }}
                              style={{ marginLeft: '0%' }}
                              // className={[classes.generatedQuestionDiv,typeof(val?.question)==="string"?classes.noDataQuestion:null]}
                              className={`${classes.generatedQuestionDiv} ${
                                typeof each?.question === 'string'
                                  ? classes.noDataQuestion
                                  : ''
                              }`}
                            />
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <Grid
                              container
                              direction='row'
                              alignItems='center'
                              spacing={1}
                              wrap='nowrap'
                              justify='flex-end'
                            >
                              <Grid item>ID: {each.id}</Grid>
                              <Grid item>
                                <Visibility
                                  style={{
                                    width: 20,
                                    height: 20,
                                    verticalAlign: 'center',
                                  }}
                                  onClick={() => {
                                    setEachQuestionPreview({
                                      ...eachQuestionPreview,
                                      data: {
                                        ...regenerateManualQuestionModalOpen.filterData,
                                        question: each,
                                      },
                                      open: true,
                                    });
                                  }}
                                ></Visibility>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <>
                  {' '}
                  <Grid item md={12} xs={12}>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <SvgIcon component={() => <img src={unfiltered} alt='crash' />} />
                      <Typography variant='h6' color='secondary'>
                        NO QUESTION FOUND
                      </Typography>
                    </div>
                  </Grid>
                </>
              )}
              {regenerateManualQuestionModalOpen?.allData?.data?.length > 0 && (
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Pagination
                      showFirstButton
                      showLastButton
                      size='medium'
                      color='primary'
                      page={regenerateManualQuestionModalOpen?.allData?.currentPage}
                      count={regenerateManualQuestionModalOpen?.allData?.totalPage}
                      onChange={(event, value) => {
                        getRegenerateManualQuestion({
                          ...regenerateManualQuestionModalOpen?.filterData,
                          page: value,
                        });
                        // handleAllTemplatePagination(event, value)
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {loading ? (
            <Grid container spacing={1} direction='column' justify='center'>
              <Spin loading='loading' style={{ padding: '20px' }} />
            </Grid>
          ) : null}
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'transparent' }}
                onClick={() =>
                  setRegenerateManualQuestionModalOpen({
                    open: false,
                    sectionIndex: null,
                    questionIndex: null,
                    selectedData: null,
                    filterData: null,
                    allData: {
                      totalCount: null,
                      totalPage: null,
                      currentPage: 1,
                      // pageSize:10,
                      data: null,
                    },
                  })
                }
              >
                Cancel
              </Button>
            </Grid>
            {regenerateManualQuestionModalOpen?.allData?.data?.length > 0 && (
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    if (!regenerateManualQuestionModalOpen.selectedData) {
                      setAlert('error', 'Please select any question');
                      return;
                    }
                    regenerateQuestionManual({
                      question: regenerateManualQuestionModalOpen.selectedData,
                      questionIndex: regenerateManualQuestionModalOpen?.questionIndex,
                      sectionIndex: regenerateManualQuestionModalOpen?.sectionIndex,
                    });
                    setRegenerateManualQuestionModalOpen({
                      open: false,
                      sectionIndex: null,
                      questionIndex: null,
                      selectedData: null,
                      filterData: null,
                      allData: {
                        totalCount: null,
                        totalPage: null,
                        currentPage: 1,
                        // pageSize:10,
                        data: null,
                      },
                    });
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Modal>
    );
  }
  return (
    <>
      <Grid item container justify='space-between'>
        <Grid item container style={{ width: 'fit-content' }}>
          {currentStep === 3 || !templateFrom?.questionGenerate ? (
            <>
              <strong className='font-size-14'>Create Question Paper</strong>
            </>
          ) : (
            <Grid
              item
              // lg={5}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <IconButton
                size='small'
                disabled={templateFrom?.currentPaper === 1}
                onClick={handlePaperBackward}
              >
                <ArrowBackIos fontSize='small' />
              </IconButton>
              <Typography className='font-size-14'>
                {templateFrom?.currentPaper} / {questionHistory?.length}
              </Typography>
              <IconButton
                size='small'
                disabled={templateFrom?.currentPaper === questionHistory?.length}
                onClick={handlePaperForward}
              >
                <ArrowForwardIos fontSize='small' />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Typography
            className='font-size-12'
            style={{ fontWeight: 600, alignSelf: 'flex-end' }}
          >
            {'Total Marks Added: ' + totalMarks + ' / ' + templateFrom?.total_marks}
          </Typography>
        </Grid>
      </Grid>
      {history.location?.state?.data?.previousURL ===
        '/auto-assessment/question-paper' && (
        <Grid
          item
          style={{
            padding: '1%',
            backgroundColor: '#F6F6F6',
            boxShadow: '0 0px 4px rgba(0, 0, 0, 0.25)',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Grid
            container
            style={{
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <Grid item style={{ marginRight: '1%' }}>
              <IconButton onClick={() => goToStep(1)} style={{ padding: 0 }}>
                <BorderColorSharp color='black' style={{ width: 15, height: 15 }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className='font-size-12'>
                {'Title:  ' + templateFrom?.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              alignItems: 'center',
              // spacing: 21,
            }}
          >
            <Grid item lg={1} md={2} sm={2} xs={2}>
              <Typography className='font-size-12'>
                {templateFrom?.grade?.grade_name}
              </Typography>
            </Grid>
            <Grid item lg={1} md={2} sm={2} xs={2} className='subject'>
              <Typography className='font-size-12'>
                {templateFrom?.subject?.subject?.subject_name}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={7} className='chapter'>
              <Typography className='font-size-12'>
                {typeof templateFrom?.volume === 'object' ? (
                  templateFrom?.volume.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.volume
                        .map((each) => each?.volume_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.volume
                          .slice(0, 2)
                          .map((each) => each?.volume_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom?.volume.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.volume.map((each) => each?.volume_name).join(', ')
                  )
                ) : (
                  ''
                )}
                {' - '}
                {typeof templateFrom?.chapter === 'object' ? (
                  templateFrom?.chapter.length > 2 ? (
                    <Tooltip
                      title={templateFrom?.chapter
                        .map((each) => each?.chapter_name)
                        .join(', ')}
                      placement='top'
                    >
                      <span>
                        {templateFrom?.chapter
                          .slice(0, 2)
                          .map((each) => each?.chapter_name)
                          .join(', ')}{' '}
                        <span style={{ color: 'grey' }}>
                          +{templateFrom.chapter.length - 2} more
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    templateFrom?.chapter.map((each) => each?.chapter_name).join(', ')
                  )
                ) : (
                  ''
                )}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={3}
              sm={3}
              xs={4}
              className='duration'
              style={{ marginLeft: '2%' }}
            >
              <Typography className='font-size-12'>
                {'Total Duration: ' + templateFrom?.duration}
              </Typography>
            </Grid>
            <Grid item lg={2} md={3} sm={3} xs={4} className='total_marks'>
              <Typography className='font-size-12'>
                {'Total Marks: ' + templateFrom?.total_marks}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                color='primary'
                size='small'
                variant='contained'
                onClick={() => goToStep(1)}
              >
                Edit
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      )}

      {templateFrom?.section?.map((sec, i) => {
        const tempQuestionIndex = 0;
        const sectionTotalMarks = sec.question.reduce(
          (total, question) => total + parseFloat(question.marks || 0),
          0
        );

        return (
          <div className='mt-3'>
            <Grid
              item
              key={i}
              container
              style={{
                padding: '1%',
                border: '1.5px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid item container style={{ alignItems: 'center' }}>
                <Grid item style={{ marginRight: '2%' }}>
                  <IconButton onClick={() => goToStep(2)} style={{ padding: 0 }}>
                    <BorderColorSharp color='black' style={{ width: 18, height: 18 }} />
                  </IconButton>
                </Grid>
                <Grid item lg={1} md={2} sm={1} xs={2}>
                  {sec?.header.length > 10 ? (
                    <Tooltip title={sec?.header} placement='top'>
                      <Typography className='font-size-12'>
                        {sec?.header
                          ? sec?.header.slice(0, 10) + '...'
                          : 'Section ' + (i + 1)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography className='font-size-12'>
                      {sec?.header ? sec?.header : 'Section ' + (i + 1)}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={8}
                  sm={6}
                  xs={8}
                  style={{ backgroundColor: '#F5F5F5', padding: 3 }}
                >
                  <Typography className='font-size-12' style={{ marginLeft: 2 }}>
                    {sec?.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={3}
                  sm={2}
                  xs={4}
                  style={{
                    marginLeft: '1%',
                    display: 'flex',
                    justify: 'center',
                  }}
                  className={classes.totalQuestion}
                >
                  <Typography className='font-size-12'>
                    Total Question: {sec.totalQuestion}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={2}
                  style={{
                    display: 'flex',
                    justify: 'center',
                  }}
                >
                  <Typography className='font-size-12'>{`Added Marks: ${sectionTotalMarks}`}</Typography>
                </Grid>
              </Grid>
              <Divider style={{ width: '100%', marginTop: '1%' }} />
              <br />

              {sec?.question?.map((val, index) => {
                questionNumbering = questionNumbering + 1;
                return (
                  <Grid
                    item
                    key={index}
                    container
                    spacing={1}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 5,
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid item>
                      <Typography
                        className='font-size-12'
                        style={{ whiteSpace: 'nowrap', minWidth: '37px' }}
                      >{`Q. ${questionNumbering}`}</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      style={{
                        padding: '0',
                        width: '100%',
                        border: '1.5px solid rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justify: 'space-between',
                      }}
                    >
                      {/* <Grid item xs={12}>
                        <Typography className='font-size-12'>kjcdaiunciudc</Typography>
                      </Grid> */}
                      {val?.question && (
                        <Grid
                          item
                          container
                          alignItems='center'
                          xs={12}
                          style={{
                            fontSize: 13,
                            // backgroundColor: '#F4F5F9',
                            padding: '0.7%',
                            // display: 'flex',
                            // flexDirection: 'row',
                            // alignItems: 'center',
                            // justifyContent: 'space-between',
                          }}

                          // onClick={() => {
                          //   if (typeof val?.question !== 'string') {
                          //     setEachQuestionPreview({
                          //       ...eachQuestionPreview,
                          //       data: val,
                          //       open: true,
                          //     });
                          //   } else {
                          //     setAlert('error', 'Question not present.');
                          //   }
                          // }}
                        >
                          <Grid
                            container
                            direction='row'
                            wrap='nowrap'
                            justify='space-between'
                            alignItems='center'
                            item
                            xs={12}
                            style={{
                              backgroundColor: '#F4F5F9',
                            }}
                            className={classes.generatedQuestion}
                            onClick={() => {
                              if (typeof val?.question !== 'string') {
                                setEachQuestionPreview({
                                  ...eachQuestionPreview,
                                  data: val,
                                  open: true,
                                });
                              } else {
                                setAlert('error', 'Question not present.');
                              }
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.question?.question_answer?.length
                                  ? val?.question?.question_answer[0]?.question
                                  : val?.question
                                  ? val?.question
                                  : null,
                              }}
                              style={{ marginLeft: '0%' }}
                              // className={[classes.generatedQuestionDiv,typeof(val?.question)==="string"?classes.noDataQuestion:null]}
                              className={`${classes.generatedQuestionDiv} ${
                                typeof val?.question === 'string'
                                  ? classes.noDataQuestion
                                  : ''
                              }`}
                            />
                            <span style={{ marginLeft: '1%' }}>
                              <Visibility style={{ fontSize: 18, color: 'grey' }} />
                            </span>
                          </Grid>
                          {/* <Grid item xs={1}></Grid> */}
                          {/* <Grid
                          container
                          item
                          direction='row'
                          alignItems='center'
                          justify='space-between'
                          xs={2}
                        >
                          <Typography
                            className='font-size-12'
                            style={{ marginRight: '1%' }}
                          >
                            Marks
                          </Typography>
                          <TextField
                            className={[classes.number, classes.textField]}
                            label=''
                            margin='dense'
                            style={{ width: '40%', margin: '0' }}
                            required
                            variant='outlined'
                            value={templateFrom?.section[i]?.question[index]?.marks || ''}
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            onChange={(e) => {
                              // let myTemplateForm = { ...templateFrom };
                              // const updatedSection = [...myTemplateForm.section];
                              // updatedSection[i].question[index].marks =
                              //   e.target.value;

                              // setTemplateFrom({
                              //   ...myTemplateForm,
                              //   section: updatedSection,
                              // });
                              onChange('marks', i, index, e.target.value);
                            }}
                          />
                        </Grid> */}
                        </Grid>
                      )}
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justify: 'space-evenly',
                          padding: '0% 0% 1% 1%',
                        }}
                      >
                        {!isPreviousPageTemplate() ? (
                          <Grid item lg={4} md={3} sm={5} xs={2}>
                            <Autocomplete
                              className={[classes.autoCompleate]}
                              options={dataList?.chapterList || []}
                              getOptionLabel={(option) => option?.chapter_name}
                              onChange={(e, newValue) => {
                                onChange('chapter', i, index, newValue);
                              }}
                              getOptionSelected={(option, value) =>
                                option?.id === value?.id
                              }
                              value={
                                templateFrom?.section[i]?.question[index]?.chapter || {}
                              }
                              size='small'
                              variant='outlined'
                              margin='dense'
                              disabled={
                                templateFrom?.questionGenerate
                                // ||
                                // templateFrom?.selectTemplate === 2
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  style={{
                                    width: '95%',
                                  }}
                                  className={[classes.chapterField, classes.textField]}
                                  sx={{ input: { color: 'red' } }}
                                  placeholder='Chapters'
                                  variant='outlined'
                                  margin='dense'
                                />
                              )}
                            />
                          </Grid>
                        ) : null}
                        <Grid item lg={4} md={3} sm={5} xs={2}>
                          <Autocomplete
                            className={[classes.autoCompleate]}
                            // options={dataList?.bloomList || []}
                            options={
                              isPreviousPageTemplate()
                                ? dataList?.bloomList
                                : dataList?.bloomList?.filter((each) =>
                                    myQuestionConfigData
                                      ? getArrayValues(
                                          getArrayValues(
                                            myQuestionConfigData?.filter(
                                              (item) =>
                                                item?.chapter?.id ===
                                                templateFrom?.section[i]?.question[index]
                                                  ?.chapter?.id
                                            ),
                                            'bloom'
                                          ),
                                          'id'
                                        ).includes(each.id)
                                      : false
                                  ) || []
                            }
                            getOptionLabel={(option) => option.category_name}
                            onChange={(e, newValue) => {
                              onChange('bloom', i, index, newValue);
                            }}
                            getOptionSelected={(option, value) =>
                              option?.id === value?.id
                            }
                            value={templateFrom?.section[i]?.question[index]?.bloom || {}}
                            size='small'
                            variant='outlined'
                            margin='dense'
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                style={{
                                  width: '95%',
                                }}
                                className={[classes.bloomsField, classes.textField]}
                                sx={{ input: { color: 'red' } }}
                                placeholder='Blooms'
                                variant='outlined'
                                margin='dense'
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={3} sm={5} xs={2}>
                          <Autocomplete
                            className={[classes.autoCompleate]}
                            options={
                              isPreviousPageTemplate()
                                ? dataList?.difficultyList
                                : dataList?.difficultyList?.filter((each) =>
                                    myQuestionConfigData
                                      ? getArrayValues(
                                          getArrayValues(
                                            myQuestionConfigData?.filter(
                                              (item) =>
                                                item?.chapter?.id ===
                                                  templateFrom?.section[i]?.question[
                                                    index
                                                  ]?.chapter?.id &&
                                                item?.bloom?.id ===
                                                  templateFrom?.section[i]?.question[
                                                    index
                                                  ]?.bloom?.id
                                            ),
                                            'difficult'
                                          ),
                                          'id'
                                        ).includes(each.id)
                                      : false
                                  ) || []
                            }
                            getOptionLabel={(option) => option.level_name}
                            onChange={(e, newValue) => {
                              onChange('difficult', i, index, newValue);
                            }}
                            getOptionSelected={(option, value) =>
                              option?.id === value?.id
                            }
                            value={
                              templateFrom?.section[i]?.question[index]?.difficult || {}
                            }
                            size='small'
                            variant='outlined'
                            margin='dense'
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                style={{
                                  width: '95%',
                                  // backgroundColor: "#FADBFF",
                                }}
                                className={[classes.difficultyField, classes.textField]}
                                placeholder='Difficulty'
                                variant='outlined'
                                margin='dense'
                                // value={val.difficult || ""}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={3} sm={5} xs={2}>
                          <Autocomplete
                            className={[classes.autoCompleate]}
                            options={
                              isPreviousPageTemplate()
                                ? dataList?.typeList
                                : dataList?.typeList?.filter((each) =>
                                    myQuestionConfigData
                                      ? getArrayValues(
                                          getArrayValues(
                                            myQuestionConfigData?.filter(
                                              (item) =>
                                                item?.chapter?.id ===
                                                  templateFrom?.section[i]?.question[
                                                    index
                                                  ]?.chapter?.id &&
                                                item?.bloom?.id ===
                                                  templateFrom?.section[i]?.question[
                                                    index
                                                  ]?.bloom?.id &&
                                                item?.difficult?.id ===
                                                  templateFrom?.section[i]?.question[
                                                    index
                                                  ]?.difficult?.id
                                            ),
                                            'type'
                                          ),
                                          'id'
                                        ).includes(each.id)
                                      : false
                                  ) || []
                            }
                            getOptionLabel={(option) => option.type_name}
                            // disableClearable
                            onChange={(e, newValue) => {
                              onChange('type', i, index, newValue);
                            }}
                            getOptionSelected={(option, value) =>
                              option?.id === value?.id
                            }
                            value={templateFrom?.section[i]?.question[index]?.type || {}}
                            size='small'
                            variant='outlined'
                            margin='dense'
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                style={{
                                  width: '95%',
                                  // backgroundColor: "#FFDADA",
                                }}
                                className={[classes.typeField, classes.textField]}
                                placeholder='Type'
                                variant='outlined'
                                margin='dense'
                                // value={val.type || ""}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={4} md={3} sm={5} xs={2}>
                          <Tooltip
                            title={
                              templateFrom?.section[i]?.question[index]?.subType
                                ?.type_name || ''
                            }
                            placeholder='top'
                          >
                            <Autocomplete
                              className={[classes.autoCompleate]}
                              options={
                                isPreviousPageTemplate()
                                  ? templateFrom?.section[i].question[index]?.type
                                    ? templateFrom?.section[i]?.question[index]?.type
                                      ? dataList.subTypeList.filter(
                                          (each) =>
                                            each.sub_type_id ===
                                            templateFrom?.section[i]?.question[index]
                                              ?.type?.id
                                        )
                                      : dataList?.subTypeList
                                    : []
                                  : dataList?.subTypeList?.filter((each) =>
                                      myQuestionConfigData
                                        ? getArrayValues(
                                            getArrayValues(
                                              myQuestionConfigData?.filter(
                                                (item) =>
                                                  item?.chapter?.id ===
                                                    templateFrom?.section[i]?.question[
                                                      index
                                                    ]?.chapter?.id &&
                                                  item?.bloom?.id ===
                                                    templateFrom?.section[i]?.question[
                                                      index
                                                    ]?.bloom?.id &&
                                                  item?.difficult?.id ===
                                                    templateFrom?.section[i]?.question[
                                                      index
                                                    ]?.difficult?.id &&
                                                  item?.type?.id ===
                                                    templateFrom?.section[i]?.question[
                                                      index
                                                    ]?.type?.id
                                              ),
                                              'subType'
                                            ),
                                            'id'
                                          ).includes(each.id)
                                        : false
                                    ) || []
                              }
                              getOptionLabel={(option) => option.type_name}
                              onChange={(e, newValue) => {
                                onChange('subType', i, index, newValue);
                              }}
                              getOptionSelected={(option, value) =>
                                option?.id === value?.id
                              }
                              value={
                                templateFrom?.section[i]?.question[index]?.subType || {}
                              }
                              size='small'
                              variant='outlined'
                              margin='dense'
                              disabled={
                                templateFrom?.questionGenerate ||
                                templateFrom?.selectTemplate === 2
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  style={{
                                    width: '95%',
                                  }}
                                  className={[classes.typeField, classes.textField]}
                                  placeholder='Sub Type: Select'
                                  variant='outlined'
                                  margin='dense'
                                  name='subType'
                                  // value={val.subType || ''}
                                />
                              )}
                              components={{
                                ClearIndicator: (props) => (
                                  <div style={{ paddingRight: '25px' }}>
                                    {props.children}{' '}
                                  </div>
                                ),
                              }}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={5}
                          xs={2}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justify: 'space-evenly',
                          }}
                        >
                          <Typography
                            className='font-size-12'
                            style={{ marginRight: '1%' }}
                          >
                            Marks
                          </Typography>
                          <TextField
                            className={[classes.number, classes.textField]}
                            label=''
                            margin='dense'
                            style={{ width: '40%' }}
                            required
                            variant='outlined'
                            value={templateFrom?.section[i]?.question[index]?.marks || ''}
                            disabled={
                              false
                              // templateFrom?.questionGenerate ||
                              // templateFrom?.selectTemplate === 2
                            }
                            onChange={(e) => {
                              // let myTemplateForm = { ...templateFrom };
                              // const updatedSection = [...myTemplateForm.section];
                              // updatedSection[i].question[index].marks =
                              //   e.target.value;

                              // setTemplateFrom({
                              //   ...myTemplateForm,
                              //   section: updatedSection,
                              // });
                              onChange('marks', i, index, e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={1}>
                          <IconButton
                            style={{
                              color: `${
                                templateFrom?.questionGenerate ||
                                templateFrom?.selectTemplate === 2
                                  ? ''
                                  : 'red'
                              }`,
                              padding: '0',
                            }}
                            disabled={
                              templateFrom?.questionGenerate ||
                              templateFrom?.selectTemplate === 2
                            }
                            onClick={() =>
                              setDeleteQuestionModalOpen({
                                open: true,
                                sectionIndex: i,
                                questionIndex: index,
                              })
                            }
                          >
                            <RemoveCircleOutline style={{ width: 18, height: 18 }} />
                          </IconButton>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={1}>
                          <IconButton
                            style={{
                              color: `${
                                (currentStep === 4 && !templateFrom?.questionGenerate) ||
                                !val?.question
                                  ? ''
                                  : 'red'
                              }`,
                              padding: '0',
                            }}
                            disabled={
                              (currentStep === 4 && !templateFrom?.questionGenerate) ||
                              !val?.question
                            }
                            onClick={() =>
                              setRegenerateQuestionModalOpen({
                                open: true,
                                sectionIndex: i,
                                questionIndex: index,
                              })
                            }
                          >
                            <CachedOutlined style={{ width: 18, height: 18 }} />
                          </IconButton>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1} xs={1}>
                          <IconButton
                            style={{
                              color: `${
                                (currentStep === 4 && !templateFrom?.questionGenerate) ||
                                !val?.chapter ||
                                !val?.bloom ||
                                !val?.difficult ||
                                !val.type ||
                                !val?.subType ||
                                !val?.question
                                  ? ''
                                  : 'red'
                              }`,
                              padding: '0',
                            }}
                            disabled={
                              (currentStep === 4 && !templateFrom?.questionGenerate) ||
                              !val?.chapter ||
                              !val?.bloom ||
                              !val?.difficult ||
                              !val.type ||
                              !val?.subType ||
                              !val?.question
                            }
                            onClick={() => {
                              // getRegenerateManualQuestion(templateFrom?.section[i]?.question[index])
                              getRegenerateManualQuestion({
                                ...val,
                                sectionIndex: i,
                                questionIndex: index,
                              });
                            }}
                          >
                            <Add style={{ width: 18, height: 18 }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item container justify='center'>
                <Grid item>
                  <Button
                    variant='outlined'
                    color='primary'
                    style={{ height: '30px', padding: '0 10px !important' }}
                    disabled={
                      templateFrom?.questionGenerate || templateFrom?.selectTemplate === 2
                    }
                    onClick={() => handleIncrement(i)}
                  >
                    Add question
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <br />
          </div>
        );
      })}
      <Grid container spacing={1} xs={12} alignItems='flex-end' justify='flex-end'>
        <Grid item>
          <Button
            variant='outlined'
            size='small'
            color='primary'
            style={{ height: '30px', padding: '0 10px !important' }}
            onClick={() => goToStep(2)}
          >
            Back
          </Button>
        </Grid>
        {history.location?.state?.data?.previousURL ===
        '/auto-assessment/question-paper' ? (
          <>
            <Grid item style={{ paddingRight: '0' }}>
              {currentStep === 4 && templateFrom?.questionGenerate && (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ height: '30px', padding: '0 10px !important' }}
                  startIcon={<LoopOutlined />}
                  onClick={() => {
                    changeQuestionGenerate();
                  }}
                >
                  Regenerate
                </Button>
              )}
              {/* templateFrom?.questionGenerate && !templateFrom?.section?.filter(eachSection => eachSection?.question?.filter(eachQuestion => !eachQuestion?.question)?.length)?.length */}
              {currentStep === 3 && (
                <Button
                  variant='contained'
                  color='primary'
                  style={{ width: '120%', height: '30px', padding: '0 10px !important' }}
                  size='small'
                  startIcon={<LoopOutlined />}
                  onClick={() => checkNextPage(currentStep)}
                >
                  Generate
                </Button>
              )}
            </Grid>
            <Grid item>
              {currentStep === 4 && templateFrom?.questionGenerate && (
                <Grid container spacing={0}>
                  <Grid item>
                    <select
                      className={classes.publishSelect}
                      name='exportType'
                      value={templateFrom?.exportType}
                      onChange={(e) => onChange('exportType', null, null, e.target.value)}
                    >
                      {/* <option className={classes.publishSelectOption} value='Publish'>
                        Publish
                      </option> */}
                      {/* <option
                        className={classes.publishSelectOption}
                        value='Save Template'
                      >
                        Save Template
                      </option> */}
                      {/* <option
                        className={classes.publishSelectOption}
                        value='Publish & Save Template'
                      >
                        Publish & Save Template
                      </option> */}
                      <option className={classes.publishSelectOption} value='Draft'>
                        create paper for draft
                      </option>
                      <option className={classes.publishSelectOption} value='Review'>
                        create paper for review
                      </option>
                      <option
                        className={classes.publishSelectOption}
                        value='Review & Save Template'
                      >
                        create paper for review and save template
                      </option>
                    </select>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className={classes.publishButton}
                      onClick={() => handleExportOptions()}
                    >
                      <Send />
                    </Button>
                  </Grid>
                </Grid>
              )}
              {currentStep === 4 && !templateFrom?.questionGenerate && (
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ height: '30px', padding: '0 10px !important' }}
                  startIcon={<LoopOutlined />}
                  onClick={() => regenerate()}
                >
                  Confirm Criteria
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <Grid item>
            <Button
              variant='contained'
              size='small'
              color='primary'
              style={{ height: '30px', padding: '0 10px !important' }}
              onClick={() => checkNextPage(currentStep)}
            >
              {/* {isEdit()?"Edit Template":"Create Template"} */}
              {isEdit() ? 'Update Template' : 'Save Template'}
              {/* Save Template */}
            </Button>
          </Grid>
        )}
      </Grid>
      {eachQuestionPreviewModal}
      {deleteQuestionModal}
      {regenerateQuestionModal}
      {regenerateManualQuestionModal}
    </>
  );
};

export default withStyles(styles)(CreateSection);
