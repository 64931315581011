import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  IconButton,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
} from '@material-ui/core';
import Layout from '../../Layout';
// import { Paper } from "../../../utility/ui";
import styles from './style';
// import { constantValue } from "../../../utility/helper/getConstant";
import { constantValue } from '../../../getConstant';
// import { useAlert } from "../../../utility/customHooks";
// import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Page1 from './createTemplate/page1';
import Page2 from './createTemplate/page2';
import Page3 from './createTemplate/page3';
import Preview from './preview';
// import checkObjectsEqual from "../../../utility/helper/checkObjectEqual";
import {
  checkObjectsEqual,
  getArrayValues,
  generateCsv,
} from '../../../utility-functions/index';
// import useWindowDimensions from "../../../utility/customHooks/useWindowDimensions";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import QuestionPreview from './preview/questionPreview';
import QuestionPreview_V2 from './preview/questionPreview_v2';
import {
  Close,
  Equalizer,
  Schedule,
  AccessTime,
  SignalCellularAlt,
  Receipt,
  NextWeekOutlined,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper } from '@mui/material';
import Modal from '../../../components/commonModal';
import DurationIcon from '../../../assets/images/duration.png';
import DifficultyIcon from '../../../assets/images/difficulty.png';
import MarksIcon from '../../../assets/images/marks.png';
import QuestionCountIcon from '../../../assets/images/questionCount.png';
// import { scales } from "chart.js";
import ChooseTemplate from './preview/chooseTemplate';
import './general.scss';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import templatePreviewImage from '../../../assets/images/template_preview.png';
import chooseTemplate from './preview/chooseTemplate';
import Loading from '../../../components/loader/loader';

const getDefaultValue = () => {
  return {
    title: '',
    academic_year: null,
    grade: null,
    subject: null,
    volume: [],
    chapter: [],
    access_usr_lvl: [],
    selectTemplate: 1,
    total_marks: null,
    duration: null,
    instruction: '',
    questionGenerate: false,
    currentPaper: 1,
    exportType: 'Draft',
    template_id: null,
    section: [
      {
        header: '',
        description: '',
        totalQuestion: null,
        question: [
          {
            chapter: null,
            bloom: null,
            difficult: null,
            type: null,
            typeID: null,
            subType: null,
            marks: 0,
          },
        ],
      },
      {
        header: '',
        description: '',
        totalQuestion: null,
        question: [
          {
            chapter: null,
            bloom: null,
            difficult: null,
            type: null,
            typeID: null,
            subType: null,
            marks: 0,
          },
        ],
      },
    ],
  };
};

const CreateTemplate = ({ classes }) => {
  const history = useHistory();
  const [templateFrom, setTemplateFrom] = useState(getDefaultValue());
  const [questionHistory, setQuestionHistory] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [academicYearList, setAcademicYearList] = useState(null);
  const [gradeList, setGradeList] = useState(null);
  const [subjectList, setSubjectList] = useState(null);
  const [volumeList, setVolumeList] = useState([]);
  const [chapterListPage1, setChapterListPage1] = useState([]);
  const [chapterListPage3, setChapterListPage3] = useState([]);
  const [accessLabelList, setAccessLabelList] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [bloomList, setBloomList] = useState([]);
  const [difficultyList, setDifficultyList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [subTypeList, setSubTypeList] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [questionPreviewModalOpen, setQuestionPreviewModalOpen] = useState(false);

  const [allTemplates, setAllTemplates] = useState([]);
  const [chooseTemplateModalOpen, setChooseTemplateModalOpen] = useState(false);
  const [chooseTemplateTab, setChooseTemplateTab] = useState(1);
  const [choosenTemplate, setChoosenTemplate] = useState(null);
  const [questionCountConfig, setQuestionCountConfig] = useState(null);
  const [loading, setLoading] = useState(false);

  //   const alert = useAlert();
  const { setAlert } = useContext(AlertNotificationContext);
  //   const { width } = useWindowDimensions();
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));

  const [hoverStates, setHoverStates] = useState(null);
  const [bool, setBool] = useState(true);
  const [alltemplatePageData, setAllTemplatePageData] = useState({
    currentPage: 1,
    pageSize: 6,
    totalPage: null,
    totalCount: null,
  });

  const [
    templateQuestionPaperTitleModalOpen,
    setTemplateQuestionPaperTitleModalOpen,
  ] = useState({ open: false, data: null });

  const [
    bulkUploadQuestionCriteriaModalOpen,
    setBulkUploadQuestionCriteriaModalOpen,
  ] = useState({ open: false, data: { selectedFiles: null } });

  const handleMouseEnter = (index) => {
    setHoverStates(index);
  };

  const handleMouseLeave = (index) => {
    setHoverStates(null);
  };

  let sectionCount = 0;

  function getSteps() {
    return ['Basic Details', 'Section Details', 'Generate Question Paper', 'For Review'];
  }
  const steps = getSteps();

  useEffect(() => {
    let data = history.location?.state?.data;
    if (data) {
      setCurrentStep(data?.currentStep ? data?.currentStep : 1);
      setTemplateFrom(
        data?.tempTemplateFrom ? JSON.parse(data?.tempTemplateFrom) : getDefaultValue()
      );
      if (
        data?.tempTemplateFrom &&
        JSON.parse(data?.tempTemplateFrom)?.template_id &&
        JSON.parse(data?.tempTemplateFrom)?.duration === null
      ) {
        getTemplateDetails(
          JSON.parse(data?.tempTemplateFrom)?.template_id,
          data?.tempTemplateFrom ? JSON.parse(data?.tempTemplateFrom) : getDefaultValue()
        );
      }
      setQuestionHistory(
        data?.tempQuestionHistory ? JSON.parse(data?.tempQuestionHistory) : []
      );
    }
  }, [history.location.state]);

  useEffect(() => {
    getAcademicYear();
    getGradeList();
    getSubjectList();
    getVolumeList();
    getChapterList();
    getAccessLabelList();
    // getBloomsDifficultyTypeList();
    // getBloomsDifficultyTypeList1();
  }, []);
  useEffect(() => {
    getSubjectList();
  }, [templateFrom.grade]);
  useEffect(() => {
    if (!isPreviousPageTemplate()) {
      if (templateFrom.subject && templateFrom.chapter.length && currentStep !== 1) {
        getBloomsDifficultyTypeList1(
          templateFrom?.subject?.id,
          getArrayValues(templateFrom?.chapter, 'id')?.join(',')
        );
      }
    } else {
      getBloomsDifficultyTypeList();
    }
  }, [templateFrom.subject, templateFrom.chapter, currentStep]);
  // useEffect(() => {
  //   getVolumeList();
  // }, [templateFrom.academic_year, templateFrom.subject]);
  useEffect(() => {
    getChapterList();
  }, [templateFrom.academic_year, templateFrom.subject, templateFrom.volume]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      let locationState = location?.state?.data;
      if (bool) {
        if (
          locationState &&
          locationState?.type === 'clear data' &&
          !checkObjectsEqual(
            templateFrom,
            locationState.tempTemplateFrom
              ? JSON.parse(locationState.tempTemplateFrom)
              : getDefaultValue()
          )
        ) {
          const message =
            'You are about to clear all the data of this page. All changes will be lost. Do you really want to clear?';
          return window.confirm(message);
        }
        if (locationState && locationState?.type === 'clear partial data') {
          return;
        }
        if (locationState && locationState?.type === 'clear partial data') {
          return;
        }
        if (
          locationState &&
          locationState?.type !== 'next' &&
          !checkObjectsEqual(
            templateFrom,
            locationState.tempTemplateFrom
              ? JSON.parse(locationState.tempTemplateFrom)
              : getDefaultValue()
          )
        ) {
          const message =
            'You are about to leaving this page. All changes will be lost. Do you really want to leave without saving?';
          return window.confirm(message);
        }
      } else {
        setBool(true);
      }
    });

    return () => {
      unblock();
    };
  }, [history, history.location.state, templateFrom, checkObjectsEqual]);

  const isPreviousPageTemplate = () => {
    if (history.location?.state?.data?.previousURL === '/auto-assessment/template') {
      return true;
    } else {
      return false;
    }
  };

  const handleChooseTemplatesModal = () => {
    console.log('modal open close');
    if (!chooseTemplateModalOpen) {
      setChooseTemplateModalOpen(true);
      getAllTemplate();
      console.log('modal open ');
      return;
    }
    if (chooseTemplateModalOpen && choosenTemplate === null) {
      setTemplateFrom({
        ...templateFrom,
        selectTemplate: 1,
      });
      setChooseTemplateModalOpen(false);
      setChoosenTemplate(null);
      return;
    }
    if (chooseTemplateModalOpen && choosenTemplate !== null) {
      setTemplateFrom({
        ...templateFrom,
        selectTemplate: 1,
      });
      setChooseTemplateModalOpen(false);
      setChoosenTemplate(null);
      return;
    }
    setChooseTemplateModalOpen(false);
    setChoosenTemplate(null);
    console.log('modal closed');
  };

  const handleAllTemplatePagination = (event, value) => {
    setAllTemplatePageData({
      ...alltemplatePageData,
      currentPage: value,
    });
    // getAllTemplate(value)
  };

  useEffect(() => {
    getAllTemplate(alltemplatePageData.currentPage);
  }, [alltemplatePageData.currentPage]);
  const getAllTemplate = (pageNo) => {
    // setAllTemplates([...constantValue.alltemplates]);
    axiosInstance
      .get(`${endpoints.assementQP.qpTemplate}`, {
        params: {
          page: pageNo !== undefined ? pageNo : alltemplatePageData.currentPage,
          page_size: alltemplatePageData.pageSize,
        },
      })
      .then((res) => {
        console.log(res, 'qpTemplate res');
        if (res.data.status_code === 200) {
          setAllTemplatePageData({
            ...alltemplatePageData,
            // currentPage:pageNo,
            totalCount: res.data.result.count,
            totalPage: Math.ceil(res.data.result.count / alltemplatePageData.pageSize),
          });
          //       title: '',
          // academic_year: null,
          // grade: null,
          // subject: null,
          // volume: [],
          // chapter: [],
          // selectTemplate: 1,
          // total_marks: null,
          // duration: null,
          // instruction: '',
          // questionGenerate: false,
          // currentPaper: 1,
          // exportType: 'Publish',
          console.log('tempAllTemplate');
          let tempAllTemplate = res.data.result.results.map((each) => {
            let tempEachAllTemplate = {
              ...each,
              academic_year: each.academic_year
                ? {
                    id: each.academic_year?.id,
                    session_year: each.academic_year?.year,
                  }
                : null,
              subject: each.subjects?.length
                ? {
                    id: each.subjects[0].grade_subject_mapping,
                    subject: each.subjects[0],
                  }
                : null,
              volume: each.volume === '' ? [] : each.volume,
              chapter: each.chapter === '' ? [] : each.chapter,
              section: each.questions_config,
              // subject: each.subjects[0],
              duration: each.total_duration,
              instruction: each.instructions,
            };
            delete tempEachAllTemplate.questions_config;
            delete tempEachAllTemplate.subjects;
            delete tempEachAllTemplate.total_duration;
            // console.log(subjectList.length&&subjectList?.filter(eachSubject=>eachSubject.subject.id===each.subjects[0].id)[0],"subbbbb");
            return { ...tempEachAllTemplate };
          });
          console.log(tempAllTemplate, 'tempAllTemplate');
          setAllTemplates(tempAllTemplate);
        }
      })
      .catch((err) => {
        console.log(err, 'qpTemplate err');
      });
  };
  const getTemplateDetails = (id, templateData) => {
    if (id) {
      axiosInstance
        .get(`${endpoints.assementQP.qpTemplate}`, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            let tempTemplateFrom = templateData;
            tempTemplateFrom.instruction = res.data.result.results[0].instructions;
            tempTemplateFrom.duration = res.data.result.results[0].total_duration || 0;

            history.replace({
              pathname: `/auto-assessment/create-template/`,
              state: {
                data: {
                  ...history.location?.state?.data,
                  tempTemplateFrom: JSON.stringify(tempTemplateFrom),

                  type: 'next',
                },
              },
            });
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
        });
    }
  };

  const handleCoosenTemplateNext = () => {
    let tempAllTemplate = JSON.stringify(allTemplates);
    if (choosenTemplate) {
      let choosenTemplateId = parseInt(choosenTemplate);
      let choosenTemplateData = JSON.parse(tempAllTemplate).filter(
        (each) => each.id === choosenTemplateId
      )[0];
      choosenTemplateData.template_id = choosenTemplateData.id;
      const {
        id,
        academic_year,
        grade,
        subject,
        volume,
        chapter,
        exportType,
        access_usr_lvl,
        ...others
      } = choosenTemplateData;
      // delete choosenTemplateData['id'];

      choosenTemplateData.selectTemplate = 2;
      setTemplateFrom({
        ...templateFrom,
        ...{
          ...others,
          section: others.section.map((each) => {
            return {
              ...each,
              question: each.question.map((eachQ) => {
                return { ...eachQ, chapter: null };
              }),
            };
          }),
        },
      });
      setChooseTemplateModalOpen(false);
      setChoosenTemplate(null);
      return;
    }
    setAlert('warning', 'Please select any template');
    return;
  };

  const getQuestionDict = () => {
    let data = {};
    templateFrom.section.map((each) => {
      let questionData = [];
      each.question.map((eachQuestion) => {
        questionData.push(eachQuestion.question);
      });
      data = { ...data, [each.header]: questionData };
    });
    return data;
  };

  const handleExportOptions = (options) => {
    const marks = templateFrom?.section.some((sec) =>
      sec?.question.some((q) => q.marks < 1)
    );

    if (marks) {
      setAlert('error', 'Marks should be greater than zero');
      return;
    }
    if (totalMarks > parseInt(templateFrom.total_marks)) {
      setAlert('error', 'Added marks should not be greater than Total marks.');
      return;
    }
    if (totalMarks !== parseInt(templateFrom.total_marks)) {
      setAlert(
        'error',
        'Added marks should be equal to Total marks for generate questions.'
      );
      return;
    }
    if (!templateFrom.duration) {
      // alert.error("Duration year can't be empty");
      setAlert('error', "Duration can't be empty");
      return;
    }
    if (!templateFrom.instruction) {
      // alert.error("Instruction year can't be empty");
      setAlert('error', "Instruction can't be empty");
      return;
    }
    let tempTemplateFrom = { ...templateFrom };
    let payload = {
      ...tempTemplateFrom,
      selectTemplate: 1,
      subjects: {
        id: tempTemplateFrom?.subject?.subject?.id,
      },
      volume: getArrayValues(tempTemplateFrom.volume, 'id').join(','),
      chapter: getArrayValues(tempTemplateFrom.chapter, 'id').join(','),
      access_usr_lvl: getArrayValues(tempTemplateFrom.access_usr_lvl, 'id'),
      status: 3,
      section: tempTemplateFrom.section.map((eachSec) => {
        return {
          ...eachSec,
          question: eachSec.question.map((eachQues) => {
            return { ...eachQues, question: null };
          }),
        };
      }),
      // action: 0,
      question_dict: {},
    };

    let payloadDraft = {
      ...tempTemplateFrom,
      paper_name: tempTemplateFrom.title,
      action: 1,
      question_dict: getQuestionDict(),
      subjects: {
        id: tempTemplateFrom?.subject?.subject?.id,
      },
      volume: getArrayValues(tempTemplateFrom.volume, 'id').join(','),
      chapter: getArrayValues(tempTemplateFrom.chapter, 'id').join(','),
      access_usr_lvl: getArrayValues(tempTemplateFrom.access_usr_lvl, 'id'),
      status: 1,
    };

    let payloadPublish = {
      ...tempTemplateFrom,
      paper_name: tempTemplateFrom.title,
      action: 1,
      question_dict: getQuestionDict(),
      subjects: {
        id: tempTemplateFrom?.subject?.subject?.id,
      },
      volume: getArrayValues(tempTemplateFrom.volume, 'id').join(','),
      chapter: getArrayValues(tempTemplateFrom.chapter, 'id').join(','),
      access_usr_lvl: getArrayValues(tempTemplateFrom.access_usr_lvl, 'id'),
      status: 3,
    };

    if (templateFrom?.exportType === 'Save Template') {
      setLoading(true);
      axiosInstance
        .post(`${endpoints.assementQP.qpTemplate}`, payload)
        .then((res) => {
          if (res.data.status_code === 200) {
            // setExportModalOpen(true);
            setAlert('success', 'Successfully Saved Template');
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });

      // setAllTemplates([...allTemplates, tempTemplateFrom]);
    }
    if (templateFrom?.exportType === 'Review & Save Template') {
      if (
        templateQuestionPaperTitleModalOpen.open === false &&
        templateQuestionPaperTitleModalOpen.data === null
      ) {
        setTemplateQuestionPaperTitleModalOpen({
          open: true,
          data: { templateTitle: '', questionPaperTitle: tempTemplateFrom.title },
        });
        return;
      }
    }
    // if (
    //   templateFrom?.exportType === 'Publish & Save Template' &&
    //   templateFrom.hasOwnProperty('id') === false
    // ) {
    //   axiosInstance
    //     .post(`${endpoints.assementQP.qpTemplate}`, {
    //       ...payload,
    //       title: templateQuestionPaperTitleModalOpen.data.templateTitle,
    //     })
    //     .then((res) => {
    //       if (res.data.status_code === 200) {
    //         // setExportModalOpen(true);
    //         setAlert('success', 'Successfully Saved Template');
    //         console.log(res, 'res.data.result.id');
    //         axiosInstance
    //           .post(`${endpoints.assementQP.autoqp}`, {
    //             ...payloadPublish,
    //             choosen_template_id: res.data.result.id,
    //           })
    //           .then((resp) => {
    //             console.log(resp, 'qpTemplate res');
    //             setAlert('success', 'Successfully Saved Question Paper');
    //             setTemplateQuestionPaperTitleModalOpen({
    //               open: false,
    //               data: null,
    //             });
    //             history.replace(history?.location?.state?.data?.previousURL);
    //             history.goBack();
    //           })
    //           .catch((err) => {
    //             console.log(err, 'qpTemplate err');
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err, 'qpTemplate err');
    //     });

    // }
    // if (
    //   templateFrom?.exportType === 'Publish & Save Template' &&
    //   templateFrom.hasOwnProperty('id')
    // ) {
    //   axiosInstance
    //     .post(`${endpoints.assementQP.qpTemplate}`, {
    //       ...payload,
    //       title: templateQuestionPaperTitleModalOpen.data.templateTitle,
    //     })
    //     .then((res) => {
    //       if (res.data.status_code === 200) {
    //         // setExportModalOpen(true);
    //         setAlert('success', 'Successfully Saved Template');
    //         axiosInstance
    //           .put(`${endpoints.assementQP.autoqp}`, payloadPublish)
    //           .then((res) => {
    //             console.log(res, 'qpTemplate res');
    //             setAlert('success', 'Question Paper Updated Successfully');
    //             setTemplateQuestionPaperTitleModalOpen({
    //               open: false,
    //               data: null,
    //             });
    //             history.replace(history?.location?.state?.data?.previousURL);
    //             history.goBack();
    //           })
    //           .catch((err) => {
    //             console.log(err, 'qpTemplate err');
    //           });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err, 'qpTemplate err');
    //     });
    // }

    // if (
    //   templateFrom?.exportType === 'Publish' &&
    //   templateFrom.hasOwnProperty('id') === false
    // ) {
    //   console.log(getQuestionDict(), 'getQuestionDict');
    //   axiosInstance
    //     .post(`${endpoints.assementQP.autoqp}`, payloadPublish)
    //     .then((res) => {
    //       console.log(res, 'qpTemplate res');
    //       setAlert('success', 'Successfully Saved Question Paper');
    //       history.replace(history?.location?.state?.data?.previousURL);
    //       history.goBack();
    //     })
    //     .catch((err) => {
    //       console.log(err, 'qpTemplate err');
    //     });
    // }
    // if (templateFrom?.exportType === 'Publish' && templateFrom.hasOwnProperty('id')) {
    //   console.log(getQuestionDict(), 'getQuestionDict');
    //   axiosInstance
    //     .put(`${endpoints.assementQP.autoqp}`, payloadPublish)
    //     .then((res) => {
    //       console.log(res, 'qpTemplate res');
    //       setAlert('success', 'Question Paper Updated Successfully');
    //       history.replace(history?.location?.state?.data?.previousURL);
    //       history.goBack();
    //     })
    //     .catch((err) => {
    //       console.log(err, 'qpTemplate err');
    //     });
    // }

    // console.log(templateFrom?.exportType, 'export');

    if (
      templateFrom?.exportType === 'Draft' &&
      templateFrom.hasOwnProperty('id') === false
    ) {
      setLoading(true);
      axiosInstance
        .post(`${endpoints.assementQP.autoqp}`, payloadDraft)
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setAlert('success', 'Successfully Saved Question Paper');
          setLoading(false);
          history.replace(history?.location?.state?.data?.previousURL);
          history.goBack();
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }

    if (templateFrom?.exportType === 'Draft' && templateFrom.hasOwnProperty('id')) {
      console.log(getQuestionDict(), 'getQuestionDict');
      setLoading(true);
      axiosInstance
        .put(`${endpoints.assementQP.autoqp}`, payloadDraft)
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setAlert('success', 'Question Paper Updated Successfully');
          setLoading(false);
          history.replace(history?.location?.state?.data?.previousURL);
          history.goBack();
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }

    // Review

    if (
      templateFrom?.exportType === 'Review' &&
      templateFrom.hasOwnProperty('id') === false
    ) {
      console.log(getQuestionDict(), 'getQuestionDict');
      setLoading(true);
      axiosInstance
        .post(`${endpoints.assementQP.autoqp}`, payloadPublish)
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setAlert('success', 'Successfully Saved Question Paper');
          setLoading(false);
          history.replace(history?.location?.state?.data?.previousURL);
          history.goBack();
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }
    if (templateFrom?.exportType === 'Review' && templateFrom.hasOwnProperty('id')) {
      console.log(getQuestionDict(), 'getQuestionDict');
      setLoading(true);
      axiosInstance
        .put(`${endpoints.assementQP.autoqp}`, payloadPublish)
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setAlert('success', 'Question Paper Updated Successfully');
          setLoading(false);
          history.replace(history?.location?.state?.data?.previousURL);
          history.goBack();
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }

    //Review and Save Template

    if (
      templateFrom?.exportType === 'Review & Save Template' &&
      templateFrom.hasOwnProperty('id') === false
    ) {
      setLoading(true);
      axiosInstance
        .post(`${endpoints.assementQP.qpTemplate}`, {
          ...payload,
          title: templateQuestionPaperTitleModalOpen.data.templateTitle,
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            // setExportModalOpen(true);
            setAlert('success', 'Successfully Saved Template');
            console.log(res, 'res.data.result.id');
            axiosInstance
              .post(`${endpoints.assementQP.autoqp}`, {
                ...payloadPublish,
                choosen_template_id: res.data.result.id,
              })
              .then((resp) => {
                console.log(resp, 'qpTemplate res');
                setAlert('success', 'Successfully Saved Question Paper');
                setTemplateQuestionPaperTitleModalOpen({
                  open: false,
                  data: null,
                });
                setLoading(false);
                history.replace(history?.location?.state?.data?.previousURL);
                history.goBack();
              })
              .catch((err) => {
                console.log(err, 'qpTemplate err');
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }
    if (
      templateFrom?.exportType === 'Review & Save Template' &&
      templateFrom.hasOwnProperty('id')
    ) {
      console.log(templateQuestionPaperTitleModalOpen, 'modal');
      setLoading(true);
      axiosInstance
        .post(`${endpoints.assementQP.qpTemplate}`, {
          ...payload,
          title: templateQuestionPaperTitleModalOpen.data.templateTitle,
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            // setExportModalOpen(true);
            setAlert('success', 'Successfully Saved Template');
            axiosInstance
              .put(`${endpoints.assementQP.autoqp}`, payloadPublish)
              .then((res) => {
                console.log(res, 'qpTemplate res');
                // console.log(payloadPublish, 'true');
                setAlert('success', 'Question Paper Updated Successfully');
                setTemplateQuestionPaperTitleModalOpen({
                  open: false,
                  data: null,
                });
                setLoading(false);
                history.replace(history?.location?.state?.data?.previousURL);
                history.goBack();
              })
              .catch((err) => {
                console.log(err, 'qpTemplate err');
                setLoading(false);
              });
          }
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
          setLoading(false);
        });
    }
  };

  function handleBeforeUnload(event) {
    if (
      !checkObjectsEqual(
        templateFrom,
        history.location?.state?.data?.tempTemplateFrom
          ? JSON.parse(history.location?.state?.data?.tempTemplateFrom)
          : getDefaultValue()
      )
    ) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [templateFrom, history.location.state, checkObjectsEqual]);

  useEffect(() => {
    console.log(
      {
        templateFrom,
        questionHistory,
        allTemplates,
        chooseTemplateModalOpen,
        choosenTemplate,
        history,
        currentStep,
        questionCountConfig,
      },
      'alllog'
    );
  });

  const getAcademicYear = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          {
            setAcademicYearList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };
  const getGradeList = () => {
    // setGradeList(constantValue.grades);
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          {
            setGradeList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };
  const getSubjectList = () => {
    // setSubjectList(constantValue.subjects);
    if (templateFrom.grade !== null) {
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
          params: { grade: templateFrom.grade?.id },
        })
        .then((result) => {
          if (result.data.status_code === 200) {
            {
              setSubjectList(result.data.result.results);
            }
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  };
  const getVolumeList = () => {
    if (templateFrom.subject !== null && templateFrom.academic_year !== null) {
    }
    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`, {
        params: {
          // academic_year: templateFrom.academic_year.id,
          // grade_sub_mapping: templateFrom.subject.id,
        },
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          {
            setVolumeList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };
  const getChapterList = () => {
    if (
      templateFrom.subject !== null &&
      templateFrom.academic_year !== null &&
      templateFrom.volume.length > 0
    ) {
      axiosInstance
        .get(`${endpoints.centralManagement.volumeChapter}`, {
          params: {
            // academic_year: templateFrom.academic_year.id,
            // grade_sub_mapping: templateFrom.subject.id,
            // volume_id: getArrayValues(templateFrom.volume, 'id').join(','),
            academic_year: templateFrom.academic_year.id,
            grade_subject_mapping: templateFrom.subject.id,
            volumes: getArrayValues(templateFrom.volume, 'id').join(','),
          },
        })
        .then((result) => {
          if (result.status === 200) {
            {
              setChapterListPage1(result.data.chapter);
            }
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  };
  const getAccessLabelList = () => {
    axiosInstance
      .get(`${endpoints.user.getUserLevel}`, {
        params: {
          exclude_student: true,
        },
      })
      .then((res) => {
        setAccessLabelList(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBloomsDifficultyTypeList = () => {
    // setBloomList(constantValue.bloomsLevel);
    axiosInstance
      .get(`${endpoints.assementQP.bloomsDifficultyTypeSubType}`, {
        params: {
          //  type_id:
        },
      })
      .then((result) => {
        console.log(result, 'resulttt');
        if (result.status === 200) {
          {
            setBloomList(result.data.blooms);
            setDifficultyList(result.data.difficulty);
            setTypeList(result.data.type);
            setSubTypeList(result.data.sub_types);
          }
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };
  const getBloomsDifficultyTypeList1 = (gs_mapping, chapter) => {
    if (gs_mapping && chapter) {
      axiosInstance
        .get(
          `${endpoints.assementQP.bloomsDifficultyTypeSubTypeAccordingSubjectChapter}`,
          {
            params: {
              gs_mapping: gs_mapping,
              chapter: chapter,
            },
          }
        )
        .then((result) => {
          console.log(result, 'resulttt');
          if (result.status === 200) {
            {
              console.log(result, 'resulttteeeee');
              setChapterListPage3(result?.data?.chapters);
              setBloomList(result?.data?.blooms);
              setDifficultyList(result?.data?.difficulty);
              setTypeList(result?.data?.types);
              setSubTypeList(result?.data?.subtypes);
              if (result?.data?.question_count?.length > 0) {
                let data = [];
                result.data.question_count.map((each) => {
                  [...Array(each.question_count).keys()].map((item) => {
                    data.push(
                      JSON.stringify({
                        chapter: {
                          id: each.chapters.id,
                          chapter_name: each.chapters.chapter_name,
                        },
                        bloom: {
                          id: each.blooms.id,
                          category_name: each.blooms.category_name,
                        },
                        difficult: {
                          id: each.difficulty.id,
                          level_name: each.difficulty.level_name,
                        },
                        type: {
                          id: each.types.id,
                          type_name: each.types.type_name,
                        },
                        subType: {
                          id: each.subtypes.id,
                          type_name: each.subtypes.type_name,
                        },
                      })
                    );
                  });
                });
                setQuestionCountConfig({
                  total_questions: result.data.total_questions,
                  data: data,
                });
              } else {
                setQuestionCountConfig({
                  total_questions: result.data.total_questions,
                  data: [],
                });
              }
            }
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  };

  const onChangePage1 = (keyName, newValue) => {
    let tempTemplateFromDefault = getDefaultValue();
    let tempTemplateFrom = { ...templateFrom };

    let confirm = false;
    if (
      (['academic_year', 'grade', 'subject']?.includes(keyName) &&
        ![null, undefined, [].toString()].includes(
          tempTemplateFrom[keyName]?.toString()
        )) ||
      (['volume', 'chapter']?.includes(keyName) &&
        tempTemplateFrom[keyName].length > newValue.length)
    ) {
      confirm = window.confirm(
        'All changes will be lost. Do you want to start from the beginning?????'
      );
    }
    // if (
    //   ['volume', 'chapter']?.includes(keyName) &&
    //   tempTemplateFrom[keyName].length>newValue.length
    // ) {
    //   confirm = window.confirm(
    //     'All changes will be lost. Do you want to start from the beginning?????'
    //   );
    // }
    if (
      confirm &&
      ((['academic_year', 'grade', 'subject']?.includes(keyName) &&
        ![null, undefined, [].toString()].includes(
          tempTemplateFrom[keyName]?.toString()
        )) ||
        (['volume', 'chapter']?.includes(keyName) &&
          tempTemplateFrom[keyName].length > newValue.length))
    ) {
      tempTemplateFromDefault = {
        ...tempTemplateFromDefault,
        [keyName]: newValue,
        ...(['grade']?.includes(keyName)
          ? {
              academic_year: tempTemplateFrom.academic_year,
            }
          : {}),

        ...(['subject']?.includes(keyName)
          ? {
              academic_year: tempTemplateFrom.academic_year,
              grade: tempTemplateFrom.grade,
            }
          : {}),
        ...(['volume']?.includes(keyName)
          ? {
              academic_year: tempTemplateFrom.academic_year,
              grade: tempTemplateFrom.grade,
              subject: tempTemplateFrom.subject,
            }
          : {}),
        ...(['chapter']?.includes(keyName)
          ? {
              academic_year: tempTemplateFrom.academic_year,
              grade: tempTemplateFrom.grade,
              subject: tempTemplateFrom.subject,
              volume: tempTemplateFrom.volume,
            }
          : {}),
      };
      history.replace({
        pathname: `/auto-assessment/create-template/`,
        state: {
          data: {
            ...history.location?.state?.data,
            currentStep: currentStep,
            type: 'clear partial data',
            tempTemplateFrom: JSON.stringify(tempTemplateFromDefault),
          },
        },
      });
    } else {
      setTemplateFrom({
        ...templateFrom,
        [keyName]: newValue,
        ...(['academic_year'].includes(keyName)
          ? {
              grade: null,
              subject: null,
              volume: [],
              chapter: [],
            }
          : {}),
        ...(['grade'].includes(keyName)
          ? {
              subject: null,
              volume: [],
              chapter: [],
            }
          : {}),

        ...(['subject'].includes(keyName) ? { volume: [], chapter: [] } : {}),
        ...(['volume'].includes(keyName) ? { chapter: [] } : {}),
      });
    }
  };
  const checkCorrectQuestionCombination = (updatedResponseData, existResponseData) => {
    let flag = true;
    let myUpdateResponseData = [...updatedResponseData];
    existResponseData.map((each) => {
      if (myUpdateResponseData.includes(JSON.stringify(each))) {
        let index = myUpdateResponseData.indexOf(JSON.stringify(each));
        myUpdateResponseData.splice(index, 1);
      } else {
        flag = false;
      }
    });
    console.log(myUpdateResponseData);
    return flag;
  };

  const onChangePage3 = (keyName, sectionIndex, questionIndex, newValue) => {
    console.log(templateFrom, 'alllog onChangePage3 1');
    let myTemplateForm = Object.assign({}, templateFrom);
    // debugger;
    if (keyName === 'subType' && isPreviousPageTemplate() === false) {
      let tempUpdatedSection = [...JSON.parse(JSON.stringify(myTemplateForm.section))];
      tempUpdatedSection[sectionIndex].question[questionIndex][keyName] = newValue;
      let currentQuestionCombination =
        tempUpdatedSection[sectionIndex].question[questionIndex];
      currentQuestionCombination = {
        chapter: {
          id: currentQuestionCombination.chapter.id,
          chapter_name: currentQuestionCombination.chapter.chapter_name,
        },
        bloom: {
          id: currentQuestionCombination.bloom.id,
          category_name: currentQuestionCombination.bloom.category_name,
        },
        difficult: {
          id: currentQuestionCombination.difficult.id,
          level_name: currentQuestionCombination.difficult.level_name,
        },
        type: {
          id: currentQuestionCombination.type.id,
          type_name: currentQuestionCombination.type.type_name,
        },
        subType: {
          id: currentQuestionCombination.subType?.id,
          type_name: currentQuestionCombination.subType?.type_name,
        },
      };

      let allQuestionsCombinations = tempUpdatedSection
        .flatMap((each) => each.question)
        .filter((eachQ) => eachQ.subType !== null)
        .map((each) => {
          return {
            chapter: {
              id: each.chapter.id,
              chapter_name: each.chapter.chapter_name,
            },
            bloom: {
              id: each.bloom.id,
              category_name: each.bloom.category_name,
            },
            difficult: {
              id: each.difficult.id,
              level_name: each.difficult.level_name,
            },
            type: {
              id: each.type.id,
              type_name: each.type.type_name,
            },
            subType: {
              id: each.subType.id,
              type_name: each.subType.type_name,
            },
          };
        });
      const combinationCount = questionCountConfig?.data.reduce(
        (accumulator, currentValue) => {
          if (currentValue === JSON.stringify(currentQuestionCombination)) {
            return accumulator + 1;
          }
          return accumulator;
        },
        0
      );
      let checkCorrectQuestionCombinationRes = checkCorrectQuestionCombination(
        questionCountConfig?.data,
        allQuestionsCombinations
      );
      console.log(
        {
          checkCorrectQuestionCombinationRes,
          combinationCount,
          questionCountConfig: questionCountConfig?.data,
          currentQuestionCombination,
        },
        'alllog onChangePage3 checkCorrectQuestionCombinationRes'
      );
      if (!checkCorrectQuestionCombinationRes) {
        setAlert(
          'error',
          `Only ${combinationCount} questions are available based on the selected combination.`
        );
        let updatedSection = [...JSON.parse(JSON.stringify(myTemplateForm.section))];
        updatedSection[sectionIndex].question[questionIndex][keyName] = null;
        setTemplateFrom({
          ...templateFrom,
          section: updatedSection,
        });
        return;
      }
    }
    let updatedSection = [...JSON.parse(JSON.stringify(myTemplateForm.section))];
    if (keyName === 'exportType') {
      setTemplateFrom({
        ...templateFrom,
        [keyName]: newValue,
      });
      return;
    }
    updatedSection[sectionIndex].question[questionIndex][keyName] = newValue;
    if (keyName === 'chapter') {
      if (templateFrom?.selectTemplate !== 2) {
        updatedSection[sectionIndex].question[questionIndex].bloom = null;
        updatedSection[sectionIndex].question[questionIndex].difficult = null;
        updatedSection[sectionIndex].question[questionIndex].type = null;
        updatedSection[sectionIndex].question[questionIndex].subType = null;
      }
    }
    if (keyName === 'bloom') {
      updatedSection[sectionIndex].question[questionIndex].difficult = null;
      updatedSection[sectionIndex].question[questionIndex].type = null;
      updatedSection[sectionIndex].question[questionIndex].subType = null;
    }
    if (keyName === 'difficult') {
      updatedSection[sectionIndex].question[questionIndex].type = null;
      updatedSection[sectionIndex].question[questionIndex].subType = null;
    }
    if (keyName === 'type') {
      updatedSection[sectionIndex].question[questionIndex].subType = null;
    }
    setTemplateFrom({
      ...templateFrom,
      section: updatedSection,
    });
  };
  console.log(templateFrom?.section, 'updated_section');

  const checkNextPage = (page) => {
    let tempTemplateFrom = { ...templateFrom };
    let tempQuestionHistory = null;
    let allQuestionPresent = true;

    if (page === 1) {
      if (!templateFrom.title.trim().length) {
        // alert.error("Academic year can't be empty");
        setAlert('error', "Title can't be empty");
        return;
      }
      if (
        history.location?.state?.data?.previousURL === '/auto-assessment/question-paper'
      ) {
        if (!templateFrom.academic_year) {
          // alert.error("Academic year can't be empty");
          setAlert('error', "Academic year can't be empty");
          return;
        }
        if (!templateFrom.grade) {
          // alert.error("Grade year can't be empty");
          setAlert('error', "Grade can't be empty");
          return;
        }
        if (!templateFrom.subject) {
          // alert.error("Subject year can't be empty");
          setAlert('error', "Subject can't be empty");
          return;
        }
        if (!templateFrom.volume.length) {
          // alert.error("Volume year can't be empty");
          setAlert('error', "Volume can't be empty");
          return;
        }
        if (!templateFrom.chapter.length) {
          // alert.error("Chapter year can't be empty");
          setAlert('error', "Chapter can't be empty");
          return;
        }
        if (!templateFrom.access_usr_lvl.length) {
          // alert.error("Total Marks year can't be empty");
          setAlert('error', "User Access Level can't be empty");
          return;
        }
      }
      if (!templateFrom.total_marks) {
        // alert.error("Total Marks year can't be empty");
        setAlert('error', "Total Marks can't be empty");
        return;
      }
      if (templateFrom.total_marks <= 0) {
        setAlert('error', 'Total Marks should be greater than zero');
        return;
      }
      if (!templateFrom.duration) {
        // alert.error("Duration year can't be empty");
        setAlert('error', "Duration can't be empty");
        return;
      }
      if (templateFrom.duration <= 0) {
        setAlert('error', 'Total Duration should be greater than zero');
        return;
      }
      if (!templateFrom.instruction) {
        // alert.error("Instruction year can't be empty");
        setAlert('error', "Instruction can't be empty");
        return;
      }
      let tempSections = tempTemplateFrom?.section.map((eachSection, index) => {
        if (eachSection.header === '') {
          eachSection.header = `Section ${String.fromCharCode(index + 65)}`;
        }
        return eachSection;
      });
      // getBloomsDifficultyTypeList1(
      //   tempTemplateFrom?.subject?.id,
      //   getArrayValues(tempTemplateFrom?.chapter, 'id')?.join(',')
      // );
      tempTemplateFrom = { ...tempTemplateFrom, section: tempSections };
    }

    if (page === 2) {
      const sectionDescription = templateFrom.section.some(
        (sec) => sec?.description?.trim().length < 1
      );
      const sectionTitle = templateFrom.section.some(
        (sec) => sec?.header?.trim().length < 1
      );
      if (!templateFrom?.section.length) {
        // alert.error("At least 1 section should be present");
        setAlert('error', 'At least 1 section should be present');
        return;
      }
      if (sectionTitle) {
        // alert.error("Section Name field can't be empty");
        setAlert('error', "Section Name field can't be empty");
        return;
      }
      if (sectionDescription) {
        // alert.error("Description field can't be empty");
        setAlert('error', "Description field can't be empty");
        return;
      }
      if (templateFrom.section.some((Sec) => Sec.totalQuestion <= 0)) {
        setAlert('error', 'Total Questions must be greater than 0');
        return;
      }
      if (
        isPreviousPageTemplate() === false &&
        templateFrom.section.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.totalQuestion),
          0
        ) > questionCountConfig?.total_questions
      ) {
        setAlert(
          'error',
          `Only ${questionCountConfig.total_questions} questions are available based on the selected filters.`
        );
        return;
      }
    }

    if (page === 3) {
      const checkAllFields = templateFrom?.section.some((sec) =>
        sec?.question.some(
          (q) =>
            !q.bloom ||
            !q.difficult ||
            !q.type ||
            !q.subType ||
            (isPreviousPageTemplate() ? false : !q.chapter)
        )
      );

      // const marks = templateFrom?.section.some((sec) =>
      //   sec?.question.some((q) => q.marks < 1)
      // );

      // if (marks) {
      //   setAlert('error', 'Marks should be greater than zero');
      //   return;
      // }

      if (checkAllFields) {
        setAlert('error', 'All fields for the question must be filled');
        return;
      }

      // if (totalMarks > parseInt(templateFrom.total_marks)) {
      //   setAlert('error', 'Added marks should not be greater than Total marks.');
      //   return;
      // }
      // if (totalMarks !== parseInt(templateFrom.total_marks)) {
      //   setAlert(
      //     'error',
      //     'Added marks should be equal to Total marks for generate questions.'
      //   );
      //   return;
      // }
      let questionLength = false;
      templateFrom.section.map((section) => {
        if (!section.question.length) {
          questionLength = true;
        }
      });
      if (questionLength) {
        setAlert('error', 'Each section should have at least 1 questions.');
        return;
      }
      const {
        academic_year,
        grade,
        subject,
        volume,
        chapter,
        ...others
      } = tempTemplateFrom;
      if (
        history.location?.state?.data?.previousURL !== '/auto-assessment/question-paper'
      ) {
        console.log(others, 'rest');
        if (others.hasOwnProperty('id')) {
          setBool(false);
          setLoading(true);
          axiosInstance
            .put(`${endpoints.assementQP.qpTemplate}`, others)
            .then((res) => {
              console.log(res, 'qpTemplate res');
              if (res.data.status_code === 200) {
                // setExportModalOpen(true);
                setLoading(false);
                setAlert('success', 'Template Updated Successfully ');
                history.replace(history?.location?.state?.data?.previousURL);
                history.goBack();
              }
            })
            .catch((err) => {
              console.log(err, 'qpTemplate err');
              setLoading(false);
              // setExportModalOpen(true);
            });
        } else {
          setBool(false);
          setLoading(true);
          axiosInstance
            .post(`${endpoints.assementQP.qpTemplate}`, others)
            .then((res) => {
              console.log(res, 'qpTemplate res');
              if (res.data.status_code === 200) {
                // setExportModalOpen(true);
                setLoading(false);
                setAlert('success', 'Successfully Saved Template');
                history.replace(history?.location?.state?.data?.previousURL);
                history.goBack();
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err, 'qpTemplate err');
              // setExportModalOpen(true);
            });
        }
        return;
      }

      let payloadQuestionPaper = {
        ...tempTemplateFrom,
        selectTemplate: 1,
        subjects: {
          id: tempTemplateFrom.subject.subject.id,
        },
        // volume: null,
        // chapter: null,
        volume: getArrayValues(tempTemplateFrom.volume, 'id').join(','),
        chapter: getArrayValues(tempTemplateFrom.chapter, 'id').join(','),
        action: 0,
        question_dict: {},
      };
      setLoading(true);
      axiosInstance
        .post(endpoints.assementQP.autoqp, payloadQuestionPaper)
        .then((response) => {
          console.log(response, 'autoqp');
          if (response.status === 200) {
            console.log(response.data, 'dataqp');
            let sectionArray = Object.keys(response.data);

            sectionArray.map((eachArr) => {
              if (response.data[eachArr].includes('No Data')) {
                allQuestionPresent = false;
              }
            });
            if (!allQuestionPresent) {
              setAlert(
                'error',
                'Some questions are not found, Please change the criteria for "No Data" questions.'
              );
            }
            tempTemplateFrom = {
              ...tempTemplateFrom,
              questionGenerate: allQuestionPresent
                ? !tempTemplateFrom?.questionGenerate
                : tempTemplateFrom?.questionGenerate,
              section: tempTemplateFrom.section.map((each, sectionIndex) => {
                return {
                  ...each,
                  question: each?.question.map((item, questionIndex) => {
                    return {
                      ...item,
                      question: response.data[each.header][questionIndex],
                      marks: response.data[each.header][questionIndex]?.question_mark,
                      // constantValue.allQuestions[
                      //   Math.floor(Math.random() * constantValue.allQuestions.length)
                      // ],
                    };
                  }),
                };
              }),
            };
            if (allQuestionPresent) {
              tempQuestionHistory = [[...tempTemplateFrom.section]];
            }
            setLoading(false);
            console.log(allQuestionPresent, 'allQuestionPresent');
            window.scrollTo(0, 0);
            history.replace({
              pathname: `/auto-assessment/create-template/`,
              state: {
                data: {
                  ...history.location?.state?.data,
                  currentStep:
                    page !== 4
                      ? questionHistory.length
                        ? 4
                        : allQuestionPresent
                        ? page + 1
                        : page
                      : page,
                  // currentStep: page !== 3 ? page + 1 : 3,
                  tempTemplateFrom: JSON.stringify(tempTemplateFrom),
                  ...(tempQuestionHistory
                    ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
                    : {}),
                  type: 'next',
                },
              },
            });
          } else {
            setLoading(false);
            setAlert('error', response.data.error_message);
            return;
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, 'err');
        });
    }

    console.log(tempTemplateFrom, 'tempTemplateFrom');
    if (page !== 3) {
      history.replace({
        pathname: `/auto-assessment/create-template/`,
        state: {
          data: {
            ...history.location?.state?.data,
            currentStep:
              page !== 4 ? (questionHistory.length && page >= 2 ? 4 : page + 1) : page,
            // currentStep: page !== 3 ? page + 1 : 3,
            tempTemplateFrom: JSON.stringify(tempTemplateFrom),
            ...(tempQuestionHistory
              ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
              : {}),
            type: 'next',
          },
        },
      });
    }
  };

  const changeQuestionGenerate = () => {
    let tempTemplateFrom = { ...templateFrom };
    tempTemplateFrom = {
      ...tempTemplateFrom,
      questionGenerate: !tempTemplateFrom?.questionGenerate,
    };
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
          // ...(tempQuestionHistory
          //   ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
          //   : {}),
          type: 'next',
        },
      },
    });
  };

  const regenerate = () => {
    const checkAllFields = templateFrom?.section.some((sec) =>
      sec?.question.some(
        (q) => !q.chapter || !q.bloom || !q.difficult || !q.type || !q.subType || !q.marks
      )
    );

    if (checkAllFields) {
      setAlert('error', 'All fields for the question must be filled');
      return;
    }

    // if (totalMarks > parseInt(templateFrom.total_marks)) {
    //   setAlert('error', 'Added marks should be greater than Total marks');
    //   return;
    // }
    // if (totalMarks !== parseInt(templateFrom.total_marks)) {
    //   setAlert(
    //     'error',
    //     'Added marks should be equal to Total marks for generate questions.'
    //   );
    //   return;
    // }

    let tempTemplateFrom = { ...templateFrom };
    let tempQuestionHistory = [...questionHistory];
    let allQuestionPresent = true;
    // tempTemplateFrom = {
    //   ...tempTemplateFrom,
    //   questionGenerate: !tempTemplateFrom?.questionGenerate,
    //   currentPaper: tempQuestionHistory?.length + 1,
    //   section: tempTemplateFrom.section.map((each) => {
    //     return {
    //       ...each,
    //       question: each?.question.map((item, index) => {
    //         return {
    //           ...item,
    //           question:
    //             constantValue.allQuestions[
    //               Math.floor(Math.random() * constantValue.allQuestions.length)
    //             ],
    //         };
    //       }),
    //     };
    //   }),
    // };
    let payload = {
      ...tempTemplateFrom,
      selectTemplate: 1,
      subjects: {
        id: tempTemplateFrom.subject.subject.id,
      },
      // volume: null,
      // chapter: null,
      volume: getArrayValues(tempTemplateFrom.volume, 'id').join(','),
      chapter: getArrayValues(tempTemplateFrom.chapter, 'id').join(','),
      action: 0,
      question_dict: {},
    };
    setLoading(true);
    axiosInstance
      .post(endpoints.assementQP.autoqp, payload)
      .then((response) => {
        console.log(response, 'autoqp');
        if (response.status === 200) {
          console.log(response.data, 'dataqp');
          let sectionArray = Object.keys(response.data);

          sectionArray.map((eachArr) => {
            if (response.data[eachArr].includes('No Data')) {
              allQuestionPresent = false;
            }
          });
          if (!allQuestionPresent) {
            setAlert(
              'error',
              'Some questions are not found, Please change the criteria for "No Data" questions.'
            );
          }
          tempTemplateFrom = {
            ...tempTemplateFrom,
            questionGenerate: allQuestionPresent
              ? !tempTemplateFrom?.questionGenerate
              : tempTemplateFrom?.questionGenerate,
            section: tempTemplateFrom.section.map((each, sectionIndex) => {
              return {
                ...each,
                question: each?.question.map((item, questionIndex) => {
                  return {
                    ...item,
                    question: response.data[each.header][questionIndex],
                    // constantValue.allQuestions[
                    //   Math.floor(Math.random() * constantValue.allQuestions.length)
                    // ],
                  };
                }),
              };
            }),
          };
          if (allQuestionPresent) {
            tempQuestionHistory = [...tempQuestionHistory, tempTemplateFrom?.section];
          }
          setLoading(false);
          window.scrollTo(0, 0);
          history.replace({
            pathname: `/auto-assessment/create-template/`,
            state: {
              data: {
                ...history.location?.state?.data,
                // currentStep: page !== 4 ? page + 1 : page,
                tempTemplateFrom: JSON.stringify(tempTemplateFrom),
                ...(tempQuestionHistory
                  ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
                  : {}),
                type: 'next',
              },
            },
          });
        } else {
          setLoading(false);
          setAlert('error', response.data.error_message);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, 'err');
      });

    // tempQuestionHistory = [...tempQuestionHistory, tempTemplateFrom?.section];

    // window.scrollTo(0, 0);
    // history.replace({
    //   pathname: `/auto-assessment/create-template/`,
    //   state: {
    //     data: {
    //       ...history.location?.state?.data,
    //       // currentStep: page !== 4 ? page + 1 : page,
    //       tempTemplateFrom: JSON.stringify(tempTemplateFrom),
    //       ...(tempQuestionHistory
    //         ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
    //         : {}),
    //       type: 'next',
    //     },
    //   },
    // });
  };

  const regenerateQuestion = (currentQuestion = {}) => {
    console.log({ currentQuestion }, 'currentQuestion');

    let tempTemplateFrom = { ...templateFrom };
    let tempQuestionHistory = [...questionHistory];
    const payload = {
      question: currentQuestion?.question,
      question_ids: getArrayValues(
        getArrayValues(
          getArrayValues(templateFrom?.section, 'question')?.flat(),
          'question'
        ),
        'id'
      )
        ?.filter((each) => each)
        ?.join(','),
    };
    setLoading(true);
    axiosInstance
      .post(endpoints.assementQP.regenerateQuestion, payload)
      .then((response) => {
        console.log(response, 'autoqp');
        if (response.data.status_code === 200) {
          console.log(response.data, 'dataqp');

          tempTemplateFrom = {
            ...tempTemplateFrom,

            section: tempTemplateFrom.section.map((each, sectionIndex) => {
              return {
                ...each,
                question: each?.question.map((item, questionIndex) => {
                  return {
                    // ...item,
                    // question: response.data[each.header][questionIndex],
                    ...(currentQuestion?.sectionIndex === sectionIndex &&
                    currentQuestion?.questionIndex === questionIndex
                      ? { ...item, question: response.data.result[0] }
                      : { ...item }),
                  };
                }),
              };
            }),
          };

          // tempQuestionHistory = [...tempQuestionHistory, tempTemplateFrom?.section];
          setLoading(false);
          window.scrollTo(0, 0);
          history.replace({
            pathname: `/auto-assessment/create-template/`,
            state: {
              data: {
                ...history.location?.state?.data,
                // currentStep: page !== 4 ? page + 1 : page,
                tempTemplateFrom: JSON.stringify(tempTemplateFrom),
                ...(tempQuestionHistory
                  ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
                  : {}),
                type: 'next',
              },
            },
          });
        } else {
          setLoading(false);
          setAlert('error', response.data.error_message);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, 'err');
      });
  };

  const regenerateQuestionManual = (data = {}) => {
    console.log({ data }, 'regenerateQuestionManual');
    let tempTemplateFrom = { ...templateFrom };
    let tempQuestionHistory = [...questionHistory];

    tempTemplateFrom = {
      ...tempTemplateFrom,

      section: tempTemplateFrom.section.map((each, sectionIndex) => {
        return {
          ...each,
          question: each?.question.map((item, questionIndex) => {
            return {
              // ...item,
              // question: response.data[each.header][questionIndex],
              ...(data?.sectionIndex === sectionIndex &&
              data?.questionIndex === questionIndex
                ? { ...item, question: data?.question }
                : { ...item }),
            };
          }),
        };
      }),
    };

    // tempQuestionHistory = [...tempQuestionHistory, tempTemplateFrom?.section];
    console.log({ tempQuestionHistory, tempTemplateFrom }, 'regenerateQuestionManual');

    window.scrollTo(0, 0);
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          // currentStep: page !== 4 ? page + 1 : page,
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
          ...(tempQuestionHistory
            ? { tempQuestionHistory: JSON.stringify(tempQuestionHistory || []) }
            : {}),
          type: 'next',
        },
      },
    });
  };

  const handlePaperForward = () => {
    let tempTemplateFrom = { ...templateFrom };
    tempTemplateFrom = {
      ...tempTemplateFrom,
      currentPaper: tempTemplateFrom?.currentPaper + 1,
      section: questionHistory[tempTemplateFrom?.currentPaper],
    };

    window.scrollTo(0, 0);
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
          type: 'next',
        },
      },
    });
  };
  const handlePaperBackward = () => {
    let tempTemplateFrom = { ...templateFrom };
    tempTemplateFrom = {
      ...tempTemplateFrom,
      currentPaper: tempTemplateFrom?.currentPaper - 1,
      section: questionHistory[tempTemplateFrom?.currentPaper - 2],
    };

    window.scrollTo(0, 0);
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
          type: 'next',
        },
      },
    });
  };

  const clearPage = (page) => {
    let tempTemplateFrom = { ...templateFrom };
    if (page === 1) {
      tempTemplateFrom = {
        ...tempTemplateFrom,
        title: '',
        academic_year: null,
        grade: null,
        subject: null,
        selectTemplate: 1,
        template_id: null,
        volume: [],
        chapter: [],
        total_marks: null,
        duration: null,
        instruction: '',
        access_usr_lvl: [],
      };
    }

    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          currentStep: page,
          type: 'clear data',
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
        },
      },
    });
  };

  const clearAllData = () => {
    let tempTemplateFrom = getDefaultValue();
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          currentStep: 1,
          type: 'clear data',
          tempTemplateFrom: JSON.stringify(tempTemplateFrom),
        },
      },
    });
  };

  // const handleDecrement = (sectionIndex, questionIndex) => {
  //   console.log(sectionIndex, questionIndex, 'sectionIndex,questionIndex');
  //   let myTemplateForm = { ...templateFrom };
  //   myTemplateForm = {
  //     ...myTemplateForm,
  //     questionGenerate: false,
  //     section: myTemplateForm.section.map((eachSection, secIndex) => {
  //       if (sectionIndex !== secIndex) {
  //         return { ...eachSection };
  //       } else {
  //         let myQuestion = [...eachSection?.question];
  //         myQuestion.splice(questionIndex, 1);
  //         return {
  //           ...eachSection,
  //           totalQuestion: parseInt(eachSection.totalQuestion) - 1,
  //           question: myQuestion,
  //         };
  //       }
  //     }),
  //   };
  //   setTemplateFrom({ ...myTemplateForm });
  // };

  const handleDecrement = (sectionIndex, questionIndex) => {
    console.log(sectionIndex, questionIndex, 'sectionIndex,questionIndex');

    setTemplateFrom((prevTemplateForm) => {
      // Check if there is more than one question in the section
      if (prevTemplateForm.section[sectionIndex]?.question.length > 1) {
        const updatedSections = prevTemplateForm.section.map((eachSection, secIndex) => {
          if (sectionIndex !== secIndex) {
            return { ...eachSection };
          } else {
            let myQuestion = [...eachSection?.question];
            myQuestion.splice(questionIndex, 1);
            return {
              ...eachSection,
              totalQuestion: parseInt(eachSection.totalQuestion) - 1,
              question: myQuestion,
            };
          }
        });

        const myTemplateForm = {
          ...prevTemplateForm,
          questionGenerate: false,
          section: updatedSections,
        };

        return { ...myTemplateForm };
      } else {
        // Handle the case where there is only one question in the section (optional)
        // console.log('Cannot delete the last question in the section');
        setAlert('error', 'Each section should have atleast one question');
        return prevTemplateForm;
      }
    });
  };

  const handleIncrement = (index) => {
    let myTemplateForm = JSON.parse(JSON.stringify({ ...templateFrom }));
    if (
      myTemplateForm.section.flatMap((each) => each.question).length + 1 >
      questionCountConfig?.total_questions
    ) {
      setAlert(
        'error',
        `Only ${questionCountConfig.total_questions} questions are available based on the selected filters.`
      );
      return;
    }
    myTemplateForm = {
      ...myTemplateForm,
      questionGenerate: false,
      section: myTemplateForm.section.map((eachSection, sectionIndex) => {
        return sectionIndex !== index
          ? { ...eachSection }
          : {
              ...eachSection,
              totalQuestion: parseInt(eachSection.totalQuestion) + 1,
              question: [
                ...eachSection?.question,
                {
                  chapter: null,
                  bloom: null,
                  difficult: null,
                  type: null,
                  subType: null,
                  marks: 0,
                },
              ],
            };
      }),
    };
    setTemplateFrom({ ...myTemplateForm });
  };

  const handleAddMore = () => {
    let myTemplateForm = { ...templateFrom };
    const sectionData = [
      ...myTemplateForm.section,
      {
        header: `Section ${String.fromCharCode(myTemplateForm?.section?.length + 65)}`,
        description: '',
        totalQuestion: 0,
        question: [
          {
            chapter: null,
            bloom: null,
            difficult: null,
            type: null,
            typeID: null,
            subType: null,
            marks: 0,
          },
        ],
      },
    ];
    setTemplateFrom({ ...myTemplateForm, section: sectionData });
  };
  // const handleDelete = (index) => {
  //   // let myTemplateForm = { ...templateFrom };
  //   // const updatedSections = myTemplateForm?.section.filter((_, i) => i !== index);
  //   // setTemplateFrom({ ...myTemplateForm, section: updatedSections });
  //   setTemplateFrom((prevTemplateForm) => {
  //     const updatedSections = prevTemplateForm.section.filter((_, i) => i !== index);
  //     updatedSections.forEach((section, i) => {
  //       section.header = `Section ${i + 1}`;
  //     });
  //     console.log(updatedSections, 'templateForm');
  //     return { ...prevTemplateForm, section: updatedSections };
  //   });
  // };

  const handleDelete = (index) => {
    setTemplateFrom((prevTemplateForm) => {
      // Check if there are more than 1 sections before allowing deletion
      if (prevTemplateForm.section.length > 1) {
        const updatedSections = prevTemplateForm.section.filter((_, i) => i !== index);

        // Update headers for remaining sections
        updatedSections.forEach((section, i) => {
          section.header = `Section ${String.fromCharCode(i + 65)}`;
        });

        console.log(updatedSections, 'templateForm');

        return { ...prevTemplateForm, section: updatedSections };
      } else {
        // Handle the case where there is only one section (optional)
        setAlert('error', 'Quesiton Paper should have atleast one section');
        return prevTemplateForm;
      }
    });
  };

  const goToStep = (step) => {
    window.scrollTo(0, 0);
    history.replace({
      pathname: `/auto-assessment/create-template/`,
      state: {
        data: {
          ...history.location?.state?.data,
          currentStep: step,
          type: 'back',
        },
      },
    });
  };

  const calculateTotalMarks = () => {
    let totalMarks = 0;

    templateFrom.section.forEach((section) => {
      section.question.forEach((question) => {
        totalMarks += parseFloat(question.marks) || 0; // Convert to number
      });
    });

    return totalMarks;
  };
  const totalMarks = calculateTotalMarks();

  // if (totalMarks > templateFrom.total_marks) {
  //   setAlert("error","Added marks should be greater than Total marks")
  // }

  const fileUploadChangeHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setBulkUploadQuestionCriteriaModalOpen({
        ...bulkUploadQuestionCriteriaModalOpen,
        data: { ...bulkUploadQuestionCriteriaModalOpen.data, selectedFiles: file },
      });
    } else {
      setAlert('error', 'Allowed only xlsx file');
    }
  };

  const downloadBulkUploadSampleFormat = () => {
    setLoading(true);
    axiosInstance
      .post(
        endpoints.assementQP.bulkTemplateDataDownload,
        {
          gs_mapping: templateFrom?.subject?.id,
          chapter: getArrayValues(templateFrom?.chapter, 'id')?.join(','),
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((responce) => {
        console.log(responce, 'responce');
        setLoading(false);
        generateCsv(responce.data, 'assessment_questions', 'xlsx');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    console.log(getArrayValues(templateFrom?.section, 'totalQuestion'));
    setLoading(true);
    axiosInstance
      .post(
        endpoints.assementQP.bulkQuestionFormatDownload,
        {
          total_questions: getArrayValues(templateFrom?.section, 'totalQuestion')?.reduce(
            (accumulator, currentValue) => {
              return accumulator + parseInt(currentValue);
            },
            0
          ),
        },
        {
          responseType: 'arraybuffer',
        }
      )
      .then((responce) => {
        console.log(responce, 'responce');
        setLoading(false);
        generateCsv(responce.data, 'upload-template', 'xlsx');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const bulkUploadQuestionCriteriaSubmit = () => {
    let payload = new FormData();
    payload.append('file', bulkUploadQuestionCriteriaModalOpen?.data?.selectedFiles);
    payload.append('chapter', getArrayValues(templateFrom?.chapter, 'id')?.join(','));
    payload.append('gs_mapping', templateFrom?.subject?.id);
    payload.append(
      'total_questions',
      getArrayValues(templateFrom?.section, 'totalQuestion')?.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseInt(currentValue);
        },
        0
      )
    );
    setLoading(true);
    axiosInstance
      .post(endpoints.assementQP.bulkQuestionFormatUpload, payload, {
        responseType: 'arraybuffer',
        // validateStatus: function (status) {
        //   return status == 201;
        // },
      })
      .then((responce) => {
        console.log(responce, 'responce');
        if (responce.status === 200) {
          const jsonString = new TextDecoder('utf-8').decode(
            new Uint8Array(responce.data)
          );
          const data = JSON.parse(jsonString);
          console.log(data, 'aaaaaaaaa');

          let resData = data.result.map((each) => {
            return {
              chapter: each.chapters,
              bloom: each.blooms,
              difficult: each.difficulty,
              type: each.types,
              typeID: null,
              subType: each.subtypes,
              marks: null,
            };
          });
          console.log({ responce, templateFrom }, 'alllog responce');
          let tempTemplateFrom = JSON.parse(JSON.stringify({ ...templateFrom }));
          tempTemplateFrom = {
            ...tempTemplateFrom,

            section: tempTemplateFrom.section.map((each, sectionIndex) => {
              let tempQues = resData.splice(0, parseInt(each?.totalQuestion));
              return {
                ...each,
                question: tempQues,
              };
            }),
          };
          setTemplateFrom({ ...tempTemplateFrom });
          setBulkUploadQuestionCriteriaModalOpen({
            open: false,
            data: {
              selectedFiles: null,
            },
          });
          setAlert('success', data?.message);
        }
        if (responce.status === 201) {
          generateCsv(responce.data, 'Upload_Template_Error_Report', 'xlsx');
          setAlert('error', 'Please check error logs.');
        }
        setLoading(false);
      })
      .catch((err) => {
        const jsonString = new TextDecoder('utf-8').decode(
          new Uint8Array(err?.response.data)
        );
        const data = JSON.parse(jsonString);
        console.log(err, 'responce error', err?.response, data);
        setLoading(false);
        setAlert('error', data?.message);
      });
  };

  let exportModal = null;
  if (exportModalOpen) {
    exportModal = (
      <Modal
        open={exportModalOpen}
        click={() => setExportModalOpen(false)}
        small
        style={isMobile ? { padding: '15px', width: 280 } : { padding: '15px' }}
      >
        <React.Fragment>
          <Card elevation={0}>
            <CardContent>{templateFrom?.exportType} Successfully</CardContent>
          </Card>
        </React.Fragment>
      </Modal>
    );
  }

  let chooseTemplateModal = null;
  if (chooseTemplateModalOpen && allTemplates.length) {
    chooseTemplateModal = (
      <Modal
        open={chooseTemplateModalOpen}
        click={() => handleChooseTemplatesModal()}
        large
        style={isMobile ? { width: 280, overflowX: 'hidden' } : { overflowX: 'hidden' }}
      >
        <React.Fragment>
          <Grid
            container
            spacing={1}
            justify='space-between'
            style={{ position: 'relative' }}
          >
            <Grid
              item
              xs={12}
              style={{
                position: 'sticky',
                top: -2,
                backgroundColor: 'white',
                padding: '10px 0px ',
                zIndex: '1',
                boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.55)',
                backgroundColor: 'rgb(31 124 237)',
              }}
            >
              <Grid container spacing={2} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography
                    className='font-size-16 font-weight-600'
                    style={{ marginLeft: '15px', color: 'white' }}
                  >
                    Choose Template
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    style={{ marginRight: '15px', color: 'white' }}
                    onClick={() => handleChooseTemplatesModal()}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: '5px 15px 20px' }}>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                style={{ overflow: 'auto', overflowX: 'hidden' }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tabs
                      value={chooseTemplateTab}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='scrollable'
                      scrollButtons='auto'
                      style={{ marginBottom: -5, marginTop: -7 }}
                      onChange={(event, newValue) => setChooseTemplateTab(newValue)}
                    >
                      <Tab label='Eduvate Template' value={1} style={{ fontSize: 13 }} />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component='fieldset' style={{ width: '100%' }}>
                      <RadioGroup
                        value={parseInt(choosenTemplate) || null}
                        onChange={(e) => setChoosenTemplate(e.target.value)}
                      >
                        <Grid container spacing={2}>
                          {allTemplates?.map((eachTemplate, index) => {
                            sectionCount = 0;
                            return (
                              <Grid key={index} item lg={4} md={6} sm={6} xs={12}>
                                <FormControlLabel
                                  className={classes.chooseTemplateModalOptions}
                                  value={eachTemplate?.id}
                                  control={<Radio color='primary' />}
                                  onMouseEnter={() => handleMouseEnter(eachTemplate?.id)}
                                  onMouseLeave={() => handleMouseLeave(index)}
                                  // style={{ backgroundColor: 'red' }}
                                  label={
                                    <>
                                      {eachTemplate?.id === hoverStates ? (
                                        <div
                                          type='button'
                                          style={{
                                            width: 160,
                                            minHeight: 203,
                                            border: '1px solid black',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: '4%',
                                            whiteSpace: 'pre-wrap',
                                            overflowWrap: 'break-word',
                                            // marginLeft: '5%',
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontSize: 11,
                                              marginLeft: '30px',
                                            }}
                                          >
                                            {eachTemplate?.title.length > 62
                                              ? `${eachTemplate?.title.substr(0, 62)}...`
                                              : eachTemplate?.title}
                                          </Typography>

                                          <Typography
                                            style={{
                                              color: 'grey',
                                              fontSize: 10,
                                              marginLeft: '30px',
                                            }}
                                          >
                                            {moment(eachTemplate?.created_at).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </Typography>

                                          <Divider
                                            style={{
                                              backgroundColor: 'darkgrey',
                                              marginTop: '2%',
                                              marginBottom: '2%',
                                            }}
                                          />

                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justify: 'space-between',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justify: 'space-around',
                                                marginBottom: '5%',
                                                marginTop: '2%',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justify: 'space-evenly',
                                                  width: '50%',
                                                }}
                                              >
                                                <img
                                                  src={DurationIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '8%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  {eachTemplate?.duration} Mins
                                                </Typography>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  width: '50%',
                                                  justify: 'space-evenly',
                                                }}
                                              >
                                                <img
                                                  src={DifficultyIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '8%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  Average
                                                </Typography>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justify: 'space-around',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justify: 'space-evenly',
                                                  width: '50%',
                                                  marginRight: '1%',
                                                }}
                                              >
                                                <img
                                                  src={MarksIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '8%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  {eachTemplate?.total_marks} Marks
                                                </Typography>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  width: '50%',
                                                  justify: 'space-evenly',
                                                }}
                                              >
                                                <img
                                                  src={QuestionCountIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '8%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  {eachTemplate?.total_questions} Qns
                                                </Typography>
                                              </div>
                                            </div>
                                          </div>

                                          <Grid
                                            container
                                            style={{
                                              marginTop: '4%',
                                              display: 'flex',
                                              justify: 'center',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {eachTemplate?.section?.map((sec, index) => (
                                              <Grid
                                                item
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                }}
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  {'Sec '}
                                                  {(sectionCount += 1)}
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    fontSize: 10,
                                                    color: 'grey',
                                                  }}
                                                >
                                                  {` (${sec.totalQuestion} Qns)`}
                                                </Typography>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            type='button'
                                            style={{
                                              width: 160,
                                              minHeight: 155,
                                              border: '1px solid black',
                                              backgroundColor: 'lightblue',
                                              display: 'flex',
                                              justify: 'center',
                                              flexDirection: 'column',
                                              paddingTop: '5%',
                                              boxShadow:
                                                'inset 0 -30px 20px rgba(0, 0, 0, 0.5)',
                                            }}
                                          >
                                            <img
                                              style={{
                                                marginLeft: '25%',
                                                // filter: "blur(0.2px)",
                                              }}
                                              src={templatePreviewImage}
                                              width={80}
                                              height={110}
                                            />
                                            <div
                                              style={{
                                                height: '38px',
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  // zIndex: 2,
                                                  color: 'white',
                                                  fontSize: 10,
                                                  padding: '2%',
                                                  whiteSpace: 'pre-wrap',
                                                  overflowWrap: 'break-word',
                                                  lineHeight: 1.5,
                                                }}
                                              >
                                                {eachTemplate?.title.length > 45
                                                  ? `${eachTemplate?.title.substr(
                                                      0,
                                                      45
                                                    )}...`
                                                  : eachTemplate?.title}
                                              </Typography>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justify: 'space-between',
                                              border: '1px solid grey',
                                              padding: '2%',
                                              // height: 50,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justify: 'space-around',
                                                marginBottom: '2%',
                                                marginTop: '2%',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justify: 'space-evenly',
                                                  width: '50%',
                                                }}
                                              >
                                                <img
                                                  src={DurationIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '5%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  {eachTemplate?.duration} Mins
                                                </Typography>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  width: '50%',
                                                  justify: 'space-evenly',
                                                }}
                                              >
                                                <img
                                                  src={DifficultyIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '5%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  Average
                                                </Typography>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justify: 'space-around',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justify: 'space-evenly',
                                                  width: '50%',
                                                }}
                                              >
                                                <img
                                                  src={MarksIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '5%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  {eachTemplate?.total_marks} Marks
                                                </Typography>
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  width: '50%',
                                                  justify: 'space-evenly',
                                                }}
                                              >
                                                <img
                                                  src={QuestionCountIcon}
                                                  width='14px'
                                                  height='14px'
                                                  style={{ marginRight: '5%' }}
                                                />
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  {eachTemplate?.total_questions} Qns
                                                </Typography>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '2%' }}>
                  <Pagination
                    showFirstButton
                    showLastButton
                    page={alltemplatePageData.currentPage}
                    count={alltemplatePageData.totalPage}
                    onChange={handleAllTemplatePagination}
                  />
                </Grid>
              </Grid>
              <Divider orientation='vertical' style={{ marginLeft: '-2%' }} />
              <Grid item xs={12} sm={5} md={5} style={{ overflow: 'auto' }}>
                <div style={{ paddingTop: '20px', textAlign: 'center' }}>
                  {choosenTemplate ? (
                    <>
                      {/* <QuestionPreview
                          templateFrom={
                            allTemplates.filter(
                              (eachTemplate) =>
                                eachTemplate.id === parseInt(choosenTemplate)
                            )[0]
                          }
                          currentStep={3}
                        /> */}
                      <ChooseTemplate
                        templateFrom={
                          allTemplates.filter(
                            (eachTemplate) =>
                              eachTemplate.id === parseInt(choosenTemplate)
                          )[0]
                        }
                        currentStep={3}
                      />
                    </>
                  ) : (
                    <>
                      <Typography className='font-size-12'>
                        Select any template for preview
                      </Typography>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: 'sticky',
                bottom: -2,
                backgroundColor: 'white',
                padding: '10px 15px ',
                boxShadow: '0px -3px 5px 1px rgba(0,0,0,0.55)',
              }}
            >
              <Grid container spacing={1} justify='flex-end'>
                <Grid item>
                  <Button
                    variant='outlined'
                    size='small'
                    color='primary'
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => handleChooseTemplatesModal()}
                  >
                    {' '}
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => handleCoosenTemplateNext()}
                  >
                    {' '}
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  let questionPreviewModal = null;
  if (questionPreviewModalOpen) {
    questionPreviewModal = (
      <Modal
        open={questionPreviewModalOpen}
        click={() => setQuestionPreviewModalOpen(false)}
        medium
        style={isMobile ? { padding: '0px 15px ', width: 280 } : { padding: '0px 15px' }}
      >
        <React.Fragment>
          <Grid
            container
            spacing={1}
            justify='space-between'
            style={{ position: 'relative' }}
          >
            <Grid
              item
              xs={12}
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '10px 0px ',
              }}
            >
              <Grid container spacing={2} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography className='font-size-16'>
                    <strong>Template Preview</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() => setQuestionPreviewModalOpen(false)}
                  >
                    <Close style={{ height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 10, paddingBottom: 20 }}>
              <Grid item xs={12} className={classes.questionPreviewTableStyle}>
                {/* <QuestionPreview
                  templateFrom={templateFrom}
                  currentStep={currentStep}
                  isQuestionPaper={true}
                /> */}
                <QuestionPreview_V2
                  templateFrom={templateFrom}
                  currentStep={currentStep}
                  isQuestionPaper={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  let templateQuestionPaperTitleModal = null;
  if (templateQuestionPaperTitleModalOpen.open) {
    templateQuestionPaperTitleModal = (
      <Modal
        open={templateQuestionPaperTitleModalOpen.open}
        click={() => setTemplateQuestionPaperTitleModalOpen({ open: false, data: null })}
        medium
        style={
          isMobile ? { padding: '10px 15px ', width: 280 } : { padding: '10px 15px' }
        }
      >
        <React.Fragment>
          <Grid
            container
            spacing={1}
            justify='space-between'
            // style={{ position: 'relative' }}
            className={classes.modal}
          >
            <Grid
              item
              xs={12}
              style={
                {
                  // position: 'sticky',
                  // top: 0,
                  // backgroundColor: 'white',
                  // padding: '10px 0px ',
                }
              }
            >
              <Grid container spacing={2} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography className='font-size-16'>
                    <strong>Set Title</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() =>
                      setTemplateQuestionPaperTitleModalOpen({ open: false, data: null })
                    }
                  >
                    <Close style={{ height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    className={[classes.number, classes.textField]}
                    label='Template Title'
                    margin='dense'
                    fullWidth
                    onChange={(e) => {
                      setTemplateQuestionPaperTitleModalOpen({
                        ...templateQuestionPaperTitleModalOpen,
                        data: {
                          ...templateQuestionPaperTitleModalOpen.data,
                          templateTitle: e.target.value,
                        },
                      });
                    }}
                    required
                    value={templateQuestionPaperTitleModalOpen.data.templateTitle || ''}
                    variant='outlined'
                  />
                  {/* <QuestionPreview templateFrom={templateFrom} currentStep={currentStep} /> */}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={[classes.number, classes.textField]}
                    label='Question Paper Title'
                    margin='dense'
                    fullWidth
                    onChange={(e) => {
                      setTemplateQuestionPaperTitleModalOpen({
                        ...templateQuestionPaperTitleModalOpen,
                        data: {
                          ...templateQuestionPaperTitleModalOpen.data,
                          questionPaperTitle: e.target.value,
                        },
                      });
                    }}
                    required
                    value={
                      templateQuestionPaperTitleModalOpen.data.questionPaperTitle || ''
                    }
                    variant='outlined'
                    disabled={
                      templateQuestionPaperTitleModalOpen.data.questionPaperTitle.length
                    }
                  />
                  {/* <QuestionPreview templateFrom={templateFrom} currentStep={currentStep} /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify='flex-end'>
                <Grid item>
                  <Button
                    variant='outlined'
                    size='small'
                    color='primary'
                    style={{ height: '30px', padding: '0 10px !important' }}
                    onClick={() => {}}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color='primary'
                    size='small'
                    variant='contained'
                    style={{ height: '30px', padding: '0 10px !important' }}
                    onClick={() => {
                      if (
                        templateQuestionPaperTitleModalOpen.data.questionPaperTitle.trim()
                          .length < 1
                      ) {
                        setAlert('error', 'Please enter question paper title');
                        return;
                      }
                      if (
                        templateQuestionPaperTitleModalOpen.data.templateTitle.trim()
                          .length < 1
                      ) {
                        setAlert('error', 'Please enter template title');
                        return;
                      }
                      handleExportOptions();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  let bulkUploadQuestionCriteriaModal = null;
  if (bulkUploadQuestionCriteriaModalOpen?.open) {
    bulkUploadQuestionCriteriaModal = (
      <Modal
        open={bulkUploadQuestionCriteriaModalOpen?.open}
        click={() =>
          setBulkUploadQuestionCriteriaModalOpen({
            open: false,
            data: {
              selectedFiles: null,
            },
          })
        }
        small={!isMobile && 768}
        medium={isMobile && 768}
        // style={
        //   width < 768 ? { padding: "15px", width: 280 } : { padding: "15px" }
        // }
      >
        <div style={{ padding: '15px' }}>
          <Typography variant='body1' style={{ margin: '0 0 15px' }}>
            Bulk Upload Question Criteria
          </Typography>
          <Grid container>
            <Grid item md={12} xs={12}>
              <input
                style={{ display: 'none' }}
                id='outlined-button-file'
                type='file'
                key={bulkUploadQuestionCriteriaModalOpen?.data?.selectedFiles}
                accept='.xlsx'
                onChange={(e) => {
                  fileUploadChangeHandler(e);
                }}
              />
              <label
                htmlFor='outlined-button-file'
                style={{ marginBottom: '0px', width: '100%' }}
              >
                <div
                  className={classes.fileUploadDiv}
                  htmlFor='outlined-button-file'
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'clip',
                  }}
                >
                  <Button component='span' className={classes.buttonStyle}>
                    Choose file
                  </Button>
                  <Typography
                    style={{
                      color: '#66788A',
                      marginLeft: '5px',
                      fontSize: '14px',
                    }}
                  >
                    {bulkUploadQuestionCriteriaModalOpen?.data?.selectedFiles
                      ? bulkUploadQuestionCriteriaModalOpen?.data?.selectedFiles.name
                      : 'No file chosen'}
                  </Typography>
                </div>
              </label>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '5px 0px 15px',
                }}
              >
                <span
                  style={{
                    fontSize: '11px',
                    color: '#66788A',
                    paddingLeft: '10px',
                  }}
                >
                  Upload File xlsx format only
                </span>
                <a
                  style={{
                    fontSize: '11px',
                    paddingRight: '10px',
                  }}
                  className={classes.downloadFormat}
                  // href='#'
                  onClick={() => downloadBulkUploadSampleFormat()}
                >
                  Download Sample Format
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                style={{ backgroundColor: 'transparent' }}
                onClick={() =>
                  setBulkUploadQuestionCriteriaModalOpen({
                    open: false,
                    data: {
                      selectedFiles: null,
                    },
                  })
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  console.log(
                    { bulkUploadQuestionCriteriaModalOpen },
                    'bulkUploadQuestionCriteriaModalOpen'
                  );
                  bulkUploadQuestionCriteriaSubmit();
                  // handleDecrement(
                  //   deleteQuestionModalOpen?.sectionIndex,
                  //   deleteQuestionModalOpen?.questionIndex
                  // );
                  // setDeleteQuestionModalOpen({
                  //   open: false,
                  //   sectionIndex: null,
                  //   questionIndex: null,
                  // });
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Layout>
        {loading ? <Loading message='Loading...' /> : null}
        <div className={`${classes.mainContainer} `}>
          <Typography variant='body1' style={{ fontWeight: '600' }}>
            Auto Assessment
          </Typography>
          <Divider className={classes.divider} />
          <Grid container direction='row' spacing={1} style={{ marginTop: 10 }}>
            <Grid item md={7} sm={12} lg={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stepper
                    style={{ padding: '5px 10px' }}
                    activeStep={currentStep - 1}
                    alternativeLabel
                    className={classes.stepper}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>
                          <Typography
                            className='font-size-12'
                            style={{ marginTop: '-8px' }}
                          >
                            {label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  {(currentStep === 3 || currentStep === 4) &&
                    templateFrom?.section.some((sec) =>
                      sec?.question.some((q) => !q.question)
                    ) &&
                    !isPreviousPageTemplate() && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bulkUploadQuestionCriteriaModalOpen.open}
                              onChange={(e) =>
                                setBulkUploadQuestionCriteriaModalOpen({
                                  open: e.target.checked,
                                  data: { selectedFiles: null },
                                })
                              }
                              name='checkedB'
                              color='primary'
                              size='small'
                              style={{ padding: '3px' }}
                            />
                          }
                          label='Bulk Upload'
                          style={{ margin: 0 }}
                        />
                      </>
                    )}
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ padding: '15px 15px' }}>
                    {currentStep === 1 && (
                      <>
                        <Page1
                          dataList={{
                            academicYearList,
                            gradeList,
                            subjectList,
                            volumeList,
                            chapterList: chapterListPage1,
                            accessLabelList,
                          }}
                          templateFrom={templateFrom}
                          currentStep={currentStep}
                          checkNextPage={checkNextPage}
                          clearPage={clearPage}
                          handleChooseTemplatesModal={handleChooseTemplatesModal}
                          clearAllData={clearAllData}
                          setBool={setBool}
                          history={history}
                          onChange={(keyName, newValue) =>
                            onChangePage1(keyName, newValue)
                          }
                          // onChange={(keyName, newValue) => {
                          //   setTemplateFrom({
                          //     ...templateFrom,
                          //     [keyName]: newValue,
                          //     ...(['academic_year'].includes(keyName)
                          //       ? {
                          //           grade: null,
                          //           subject: null,
                          //           volume: [],
                          //           chapter: [],
                          //         }
                          //       : {}),
                          //     ...(['grade'].includes(keyName)
                          //       ? {
                          //           subject: null,
                          //           volume: [],
                          //           chapter: [],
                          //         }
                          //       : {}),

                          //     ...(['subject'].includes(keyName)
                          //       ? { volume: [], chapter: [] }
                          //       : {}),
                          //     ...(['volume'].includes(keyName) ? { chapter: [] } : {}),
                          //   });
                          // }}
                        />
                      </>
                    )}
                    {currentStep === 2 && (
                      <>
                        <Page2
                          templateFrom={templateFrom}
                          handleAddMore={handleAddMore}
                          setTemplateFrom={setTemplateFrom}
                          goToStep={goToStep}
                          handleDelete={handleDelete}
                          checkNextPage={checkNextPage}
                          currentStep={currentStep}
                          history={history}
                          questionCountConfig={questionCountConfig}
                          onChange={(keyName, newValue) => {
                            setTemplateFrom({
                              ...templateFrom,
                              [keyName]: newValue,
                            });
                          }}
                        />
                      </>
                    )}

                    {currentStep === 3 && (
                      <>
                        <Page3
                          dataList={{
                            bloomList,
                            difficultyList,
                            typeList,
                            subTypeList,
                            chapterList: chapterListPage3,
                          }}
                          templateFrom={templateFrom}
                          questionCountConfig={questionCountConfig}
                          handleIncrement={handleIncrement}
                          handleDecrement={handleDecrement}
                          handleAddMore={handleAddMore}
                          setTemplateFrom={setTemplateFrom}
                          goToStep={goToStep}
                          handleDelete={handleDelete}
                          checkNextPage={checkNextPage}
                          currentStep={currentStep}
                          totalMarks={totalMarks}
                          questionHistory={questionHistory}
                          regenerate={regenerate}
                          regenerateQuestion={regenerateQuestion}
                          regenerateQuestionManual={regenerateQuestionManual}
                          history={history}
                          isPreviousPageTemplate={isPreviousPageTemplate}
                          onChange={(keyName, sectionIndex, questionIndex, newValue) => {
                            onChangePage3(keyName, sectionIndex, questionIndex, newValue);
                          }}
                        />
                      </>
                    )}
                    {currentStep === 4 && (
                      <>
                        <Page3
                          dataList={{
                            bloomList,
                            difficultyList,
                            typeList,
                            subTypeList,
                            chapterList: chapterListPage3,
                          }}
                          templateFrom={templateFrom}
                          questionCountConfig={questionCountConfig}
                          handleIncrement={handleIncrement}
                          handleDecrement={handleDecrement}
                          handleAddMore={handleAddMore}
                          setTemplateFrom={setTemplateFrom}
                          goToStep={goToStep}
                          handleDelete={handleDelete}
                          checkNextPage={checkNextPage}
                          currentStep={currentStep}
                          totalMarks={totalMarks}
                          questionHistory={questionHistory}
                          regenerate={regenerate}
                          regenerateQuestion={regenerateQuestion}
                          regenerateQuestionManual={regenerateQuestionManual}
                          changeQuestionGenerate={changeQuestionGenerate}
                          handlePaperForward={handlePaperForward}
                          handlePaperBackward={handlePaperBackward}
                          constantValue={constantValue}
                          handleExportOptions={handleExportOptions}
                          history={history}
                          isPreviousPageTemplate={isPreviousPageTemplate}
                          onChange={(keyName, sectionIndex, questionIndex, newValue) => {
                            onChangePage3(keyName, sectionIndex, questionIndex, newValue);
                          }}
                        />
                      </>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} sm={12} lg={4}>
              <Paper style={{ padding: '15px 15px' }}>
                <Preview
                  templateFrom={templateFrom}
                  currentStep={currentStep}
                  setQuestionPreviewModalOpen={setQuestionPreviewModalOpen}
                  dataList={{
                    bloomList,
                    difficultyList,
                    typeList,
                    subTypeList,
                  }}
                  isQuestionPaper={true}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        {exportModal}
        {questionPreviewModal}
        {chooseTemplateModal}
        {templateQuestionPaperTitleModal}
        {bulkUploadQuestionCriteriaModal}
      </Layout>
    </>
  );
};

CreateTemplate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(styles)(CreateTemplate);
