import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import {
  IconButton,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextField,
  DialogTitle,
} from '@material-ui/core';

export default function AddUserDialog(props) {
  const [userLevelName, setUserLevelName] = useState('');
  const [userLevelId, setUserLevelId] = useState('');
  const { setAlert } = useContext(AlertNotificationContext);
  const [idChange, setIdChange] = useState(false);
  const [levelDesc, setLevelDesc] = useState('');
  const [Designation, setDesignation] = useState(['']);
  const [editDesignation, setEditDesignation] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [addEditDesignation, setAddEditDesignation] = useState('');

  const handleName = (e) => {
    setUserLevelName(e.target.value);
  };

  const handleId = (e) => {
    setUserLevelId(e.target.value);
    setIdChange(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialog(false);
  };

  useEffect(() => {
    if (props?.isEdit) {
      const getEditDesignation = props?.editData?.designation?.map(
        (item) => item?.designation
      );
      const getEditDesignationId = props?.editData?.designation?.map((item) => ({
        id: item?.id,
      }));

      setUserLevelName(props?.editData?.level_name);
      setLevelDesc(props?.editData?.description);
      setDesignation(getEditDesignation);
      setEditDesignation(getEditDesignationId);
    }
    if (props?.isEdit === false) {
      setUserLevelName('');
      setLevelDesc('');
      setDesignation(['']);
      setEditDesignation([]);
      setAddEditDesignation('');
    }
  }, [props]);

  useEffect(() => {
    if (props?.isEdit) {
      const editDesignationObject = editDesignation?.map((desig, index) => ({
        ...desig,
        designation: Designation[index],
      }));
      setEditDesignation(editDesignationObject);
    }
  }, [Designation]);

  const handleDesc = (e) => {
    setLevelDesc(e.target.value);
  };

  const handleDesignation = (index, value) => {
    const newDesignation = [...Designation];
    newDesignation[index] = value;
    setDesignation(newDesignation);

    if (props?.isEdit) {
      setAddEditDesignation(value);
    }
  };

  const handleEditDesignation = (value) => {
    setAddEditDesignation(value);
  };

  const handleAddDesignation = () => {
    if (Designation?.length < 8) {
      setDesignation([...Designation, '']);
    }

    if (props?.isEdit) {
      setEditDialog(true);
    }
  };

  const handleRemoveDesignation = (index) => {
    const newDesignation = [...Designation];
    newDesignation.splice(index, 1);
    setDesignation(newDesignation);

    if (props?.isEdit) {
      const editNewDesignation = [...editDesignation];
      editNewDesignation.splice(index, 1);
      setEditDesignation(editNewDesignation);
    }
  };

  const handleEditSubmit = () => {
    setEditDialog(false);
    let data = {
      user_level_id: props?.editData?.id,
      designation: addEditDesignation,
    };
    axiosInstance
      .post(`${endpoints.user.addDesignationInEdit}`, data)
      .then((res) => {
        setAddEditDesignation('');
        if (res.status === 200) {
          setAlert('success', res.data.message);
        }
        if (res.status === 400) {
          setAlert('error', res.data.message);
        }
        props.handleClose();
      })
      .catch((error) => {
        setAlert('error', 'failed to Add');
      });
  };

  const handleSubmit = () => {
    let data = [];
    if (props.isEdit) {
      data = {
        level_name: userLevelName,
        description: levelDesc,
        designation: editDesignation,
      };
    }
    if (!props.isEdit) {
      data = {
        level_name: userLevelName,
        description: levelDesc,
        designation: Designation,
      };
    }
    if (props.isEdit) {
      axiosInstance
        .put(`/central-admin/${props?.editData?.id}/user_level/`, data)
        .then((result) => {
          setUserLevelName('');
          if (result.data.status_code === 200) {
            setAlert('success', 'Added Successfully');
          }
          if (result.data.status_code === 400) {
            setAlert('error', result.data.message);
          }
          props.handleClose();
          setIdChange(false);
        })
        .catch((error) => {
          setAlert('error', 'failed to Add');
        });
    } else {
      axiosInstance
        .post(`${endpoints.user.addUserLevel}`, data)
        .then((result) => {
          setUserLevelName('');
          setAlert('success', 'Added Successfully');
          props.handleClose();
        })
        .catch((error) => {
          setAlert('error', 'failed to Add');
        });
    }
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        // style={{ minHeight: '80px' }}
      >
        <DialogTitle id='alert-dialog-title'>{'Add User Level'}</DialogTitle>

        <DialogContent style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <TextField
            id='domainname'
            label='User Level Name'
            variant='outlined'
            size='small'
            style={{ width: '100%' }}
            value={userLevelName}
            inputProps={{ maxLength: 30 }}
            title='User Level Name'
            name='domainname'
            onChange={handleName}
          />
          <TextField
            id='domainname'
            label='User Level Description'
            variant='outlined'
            size='small'
            style={{ width: '100%', margin: '20px 0' }}
            value={levelDesc}
            inputProps={{ maxLength: 80 }}
            title='User Level Description'
            name='domainname'
            onChange={handleDesc}
          />
          <>
            {Designation?.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextField
                  id='designationName0'
                  label='User Level Designation'
                  variant='outlined'
                  size='small'
                  style={{ width: '100%' }}
                  value=''
                  inputProps={{ maxLength: 80 }}
                  title='User Level Designation'
                  name='designationName0'
                  onChange={(e) => handleDesignation(0, e.target.value)}
                />
                <IconButton onClick={handleAddDesignation}>
                  <AddCircle color='primary' />
                </IconButton>
              </div>
            ) : (
              Designation.map((desig, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: Designation.length > 1 ? '5px 5px 0 0' : '',
                  }}
                  key={index}
                >
                  <TextField
                    id={`designationName${index}`}
                    label='User Level Designation'
                    variant='outlined'
                    size='small'
                    style={{ width: '100%' }}
                    value={desig}
                    inputProps={{ maxLength: 80 }}
                    title='User Level Designation'
                    name={`designationName${index}`}
                    onChange={(e) => handleDesignation(index, e.target.value)}
                  />
                  {Designation.length > 1 && (
                    <IconButton
                      onClick={() => handleRemoveDesignation(index)}
                      style={{ fontSize: '30px' }}
                    >
                      <RemoveCircle color='primary' />
                    </IconButton>
                  )}
                  {index === Designation.length - 1 && (
                    <IconButton onClick={handleAddDesignation}>
                      <AddCircle color='primary' />
                    </IconButton>
                  )}
                </div>
              ))
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              props?.isEdit && addEditDesignation?.length > 0
                ? handleEditSubmit
                : handleSubmit
            }
            color='primary'
            variant='contained'
            size='small'
            style={{ color: 'white' }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        // style={{ minHeight: '80px' }}
      >
        <DialogTitle id='alert-dialog-title'>{'Add Designation'}</DialogTitle>

        <DialogContent style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <TextField
            id={`designationName`}
            label='User Level Designation'
            variant='outlined'
            size='small'
            style={{ width: '100%' }}
            value={addEditDesignation}
            inputProps={{ maxLength: 80 }}
            title='User Level Designation'
            name={`designationName`}
            onChange={(e) => handleEditDesignation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditSubmit}
            color='primary'
            variant='contained'
            size='small'
            style={{ color: 'white' }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
