import React, { useEffect, useState } from 'react';
import MyTinyEditor from '../../assesment/assessment-new/create-question-paper-new/tiny-mce-new';
import cuid from 'cuid';
import ReactQuillEditor from '../../../components/reactQuill';

const Descriptive = ({
  currentQuestion,
  isSubQuestion,
  handleQuestionChanges,
  subQuestionIndex,
  isEditQuestion,
}) => {
  const [descriptiveQuestion, setDescriptiveQuestion] = useState({
    question: currentQuestion?.question_answer[0]?.question,
    answer: currentQuestion?.question_answer[0]?.answer,
  });
  const [questionUpdated, setQuestionUpdated] = useState(isEditQuestion);
  const handleDescriptiveContent = (isQuestion) => {
    // if (isSubQuestion) {
    //   return;
    // } else {
    if (isQuestion) {
      return descriptiveQuestion?.question;
    } else {
      return Array.isArray(descriptiveQuestion?.answer)
        ? descriptiveQuestion?.answer?.toString()
        : descriptiveQuestion?.answer;
    }
    // }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    // if (source!=='user') {
    //   return
    // }
    // setQuestionUpdated(false);
    if (editor?.id?.includes('Descriptive_answer')) {
      setDescriptiveQuestion({ ...descriptiveQuestion, answer: content });
      if (isSubQuestion) {
        handleQuestionChanges(
          { ...descriptiveQuestion, answer: content },
          subQuestionIndex
        );
      } else {
        handleQuestionChanges({ ...descriptiveQuestion, answer: content });
      }
    } else {
      setDescriptiveQuestion({ ...descriptiveQuestion, question: content });
      if (isSubQuestion) {
        handleQuestionChanges(
          { ...descriptiveQuestion, question: content },
          subQuestionIndex
        );
      } else {
        handleQuestionChanges({ ...descriptiveQuestion, question: content });
      }
    }
    // setTimeout(() => {
    //   setQuestionUpdated(true);
    // }, 100);
  };

  useEffect(() => {
    if (questionUpdated) {
      if (isSubQuestion) {
        handleQuestionChanges(descriptiveQuestion, subQuestionIndex);
      } else {
        handleQuestionChanges(descriptiveQuestion);
      }
    }
  }, [questionUpdated]);
  return (
    <div className='row'>
      <div className='col-12 pb-3'>
        <div className='th-16 th-fw-600 th-black pb-2'>
          {isSubQuestion ? `Sub-Question ${subQuestionIndex + 1}` : 'Question'}
        </div>
        <div className='th-editor urpal'>
          {/* <MyTinyEditor
            id={`Descriptive_question+${cuid()}`}
            content={handleDescriptiveContent(true)}
            handleEditorChange={handleEditorChange}
            setOpenEditor={true}
            placeholder='Question goes here...'
          /> */}
          <div className='py-2 w-100 font-weight-normal'>
            <ReactQuillEditor
              id={`Descriptive_question+${cuid()}`}
              value={handleDescriptiveContent(true)}
              onChange={(content, delta, source, editor) =>
                handleEditorChange(content, delta, source, {
                  ...editor,
                  id: `Descriptive_question+${cuid()}`,
                })
              }
              placeholder='Question goes here...'
            />
          </div>
        </div>
      </div>
      <div className='col-12 pt-3'>
        <div className='th-16 th-fw-600 th-black pb-2'> Answer</div>
        <div className='th-editor'>
          {/*<MyTinyEditor
            id={`Descriptive_answer+${cuid()}`}
            content={handleDescriptiveContent(false)}
            handleEditorChange={handleEditorChange}
            setOpenEditor={true}
            placeholder='Answer goes here...'
          />*/}
          <ReactQuillEditor
            id={`Descriptive_answer+${cuid()}`}
            value={handleDescriptiveContent(false)}
            onChange={(content, delta, source, editor) =>
              handleEditorChange(content, delta, source, {
                ...editor,
                id: `Descriptive_answer+${cuid()}`,
              })
            }
            placeholder='Question goes here...'
          />
        </div>
      </div>
    </div>
  );
};

export default Descriptive;
