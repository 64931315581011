import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  IconButton,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Menu,
} from '@material-ui/core';
import Layout from '../../Layout';
// import { Paper } from "../../../utility/ui";
import styles from './style';
// import { constantValue } from "../../../utility/helper/getConstant";
import { constantValue } from '../../../getConstant';
// import { useAlert } from "../../../utility/customHooks";
// import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';

// import checkObjectsEqual from "../../../utility/helper/checkObjectEqual";
import { checkObjectsEqual, getArrayValues } from '../../../utility-functions/index';
// import useWindowDimensions from "../../../utility/customHooks/useWindowDimensions";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loading from '../../../components/loader/loader';
// import QuestionPreview from './preview/questionPreview';
import {
  Close,
  Equalizer,
  Schedule,
  AccessTime,
  SignalCellularAlt,
  Receipt,
  Visibility,
  Edit,
  Delete,
  MoreVert,
  Description,
} from '@material-ui/icons';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Tooltip } from '@mui/material';
import Modal from '../../../components/commonModal';
import DurationIcon from '../../../assets/images/duration.png';
import DifficultyIcon from '../../../assets/images/difficulty.png';
import MarksIcon from '../../../assets/images/marks.png';
import QuestionCountIcon from '../../../assets/images/questionCount.png';
// import { scales } from "chart.js";
// import ChooseTemplate from './preview/chooseTemplate';
import '../CreateTemplate/general.scss';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { Pagination, PaginationItem } from '@material-ui/lab';
import moment from 'moment';
import QuestionPreview from '../CreateTemplate/preview/questionPreview';
import ChooseTemplate from '../CreateTemplate/preview/chooseTemplate';
import DeleteDialog from '../../../components/deleteDialog';
import templatePreviewImage from '../../../assets/images/template_preview.png';
import TrueFalse from '../../assesment/assessment-new/create-question-paper-new/questiontype/truefalse';

const CreateTemplate = ({ classes }) => {
  const history = useHistory();
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const [deleteQuestionPaperModalOpen, setDeleteQuestionPaperModalOpen] = useState({
    open: false,
    data: null,
  });

  const [filterData, setFilterDate] = useState({
    keyword: '',
    minMarks: null,
    maxMarks: null,
  });

  const [alltemplatePageData, setAllTemplatePageData] = useState({
    currentPage: 1,
    pageSize: 8,
    totalPage: null,
    totalCount: null,
  });
  const [allTemplates, setAllTemplates] = useState(null);
  const [isLocation, setIsLocation] = useState(false);
  const [questionPreviewModalOpen, setQuestionPreviewModalOpen] = useState({
    open: false,
    data: null,
  });
  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState({
    open: false,
    data: null,
  });
  useEffect(() => {
    console.log({ allTemplates, alltemplatePageData, filterData }, 'alllog');
  });

  useEffect(() => {
    getAllTemplate();
  }, []);

  const getAllTemplate = (pageNo) => {
    // setAllTemplates([...constantValue.alltemplates]);
    setIsLocation(false);
    setLoading(true);
    axiosInstance
      .get(`${endpoints.assementQP.qpTemplate}`, {
        params: {
          page: pageNo !== undefined ? pageNo : alltemplatePageData.currentPage,
          page_size: alltemplatePageData.pageSize,
          keywords: filterData.keyword,
          min_marks: filterData.minMarks,
          max_marks: filterData.maxMarks,
        },
      })
      .then((res) => {
        console.log(res, 'qpTemplate res');
        if (res.data.status_code === 200) {
          setAllTemplatePageData({
            ...alltemplatePageData,
            currentPage: pageNo !== undefined ? pageNo : alltemplatePageData.currentPage,
            totalCount: res.data.result.count,
            totalPage: Math.ceil(res.data.result.count / alltemplatePageData.pageSize),
          });
          let tempAllTemplate = res.data.result.results.map((each) => {
            let tempEachAllTemplate = {
              ...each,
              academic_year: {
                id: each?.academic_year?.id,
                session_year: each?.academic_year?.year,
              },
              subject: {
                id: each?.subjects[0]?.grade_subject_mapping,
                subject: each?.subjects[0],
              },
              volume: each?.volume === '' ? [] : each?.volume,
              chapter: each?.chapter === '' ? [] : each?.chapter,
              section: each?.questions_config,
              // subject: each?.subjects[0],
              duration: each?.total_duration,
              instruction: each?.instructions,
            };
            delete tempEachAllTemplate.questions_config;
            delete tempEachAllTemplate.subjects;
            delete tempEachAllTemplate.total_duration;
            delete tempEachAllTemplate.instructions;
            // console.log(subjectList.length&&subjectList?.filter(eachSubject=>eachSubject.subject.id===each.subjects[0].id)[0],"subbbbb");
            return { ...tempEachAllTemplate };
          });
          console.log(tempAllTemplate, 'tempAllTemplate');
          setAllTemplates(tempAllTemplate);

          //   setAllTemplates(res.data.result.results);
        } else {
          console.log('error erroe');
          setAlert('error', res.data.message);
          setAllTemplates(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'qpTemplate err');
        setLoading(false);
      });
    if (history?.location?.state) {
      let state = { ...history?.location?.state };
      delete state.data;
      history.replace({ ...history?.location, state });
    }
  };

  useEffect(() => {
    let data = history?.location?.state?.data;
    if (data) {
      setFilterDate(data?.filterData);
      setAllTemplatePageData({ ...alltemplatePageData, currentPage: data?.page });
      setIsLocation(true);
    }
  }, [history.location.state]);

  useEffect(() => {
    if (isLocation) {
      getAllTemplate();
    }
  }, [isLocation]);

  const handleAllTemplatePagination = (event, value) => {
    setAllTemplatePageData({
      ...alltemplatePageData,
      currentPage: value,
    });
    getAllTemplate(value);
  };

  const deleteTemplate = (templateId) => {
    if (templateId) {
      axiosInstance
        .delete(`${endpoints.assementQP.qpTemplate}`, {
          params: {
            id: templateId,
          },
        })
        .then((res) => {
          console.log(res, 'qpTemplate res');
          setDeleteTemplateModalOpen({ open: false, data: null });
          getAllTemplate();
        })
        .catch((err) => {
          console.log(err, 'qpTemplate err');
        });
    }
  };

  let questionPreviewModal = null;
  if (questionPreviewModalOpen.open) {
    questionPreviewModal = (
      <Modal
        open={questionPreviewModalOpen.open}
        click={() => setQuestionPreviewModalOpen({ open: false, data: null })}
        medium
        style={isMobile ? { padding: '0px 15px ', width: 280 } : { padding: '0px 15px' }}
      >
        <React.Fragment>
          <Grid
            container
            spacing={1}
            justify='space-between'
            style={{ position: 'relative' }}
          >
            <Grid
              item
              xs={12}
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '10px 0px ',
              }}
            >
              <Grid container spacing={2} justify='space-between' alignItems='center'>
                <Grid item>
                  <Typography className='font-size-16'>
                    <strong>Template Preview</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size='small'
                    onClick={() =>
                      setQuestionPreviewModalOpen({ open: false, data: null })
                    }
                  >
                    <Close style={{ height: 18, width: 18 }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 10, paddingBottom: 20 }}>
              <Grid item xs={12} className={classes.questionPreviewTableStyle}>
                {/* <QuestionPreview
                  templateFrom={questionPreviewModalOpen?.data}
                  currentStep={4}
                  isQuestionPaper={true}
                /> */}
                <ChooseTemplate
                  templateFrom={questionPreviewModalOpen?.data}
                  currentStep={3}
                  isQuestionPaper={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  let deleteTemplateModal = null;
  if (deleteTemplateModalOpen.open) {
    deleteTemplateModal = (
      <DeleteDialog
        showModal={deleteTemplateModalOpen.open}
        hideModalHandler={() => setDeleteTemplateModalOpen({ open: false, data: null })}
        dialogText='Are You Sure To Delete Template ?'
        // successMethod={deleteTemplate(deleteTemplateModalOpen.data)}
        successMethod={() => {
          deleteTemplate(deleteTemplateModalOpen.data);
        }}
      />
    );
  }

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpenIndex(null);
  };

  return (
    <>
      <Layout>
        {loading ? <Loading message='Loading...' /> : null}
        <div className={`${classes.mainContainer}`}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
            spacing={1}
          >
            <Grid item>
              <Typography style={{ fontWeight: '600' }}>
                Auto Assessment - Templates
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={() => {
                  history.replace({
                    pathname: '/auto-assessment/template',
                    state: {
                      data: {
                        filterData: filterData,
                        page: alltemplatePageData?.currentPage,
                      },
                    },
                  });
                  history.push({
                    pathname: `/auto-assessment/create-template/`,
                    state: {
                      data: {
                        previousURL: window.location.pathname,
                      },
                    },
                  });
                }}
              >
                Create Template
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container direction='row' alignItems='center' spacing={1}>
            <Grid item sm={3} md={2} xs={6}>
              <TextField
                className={[classes.textField]}
                label='Keywords'
                margin='dense'
                fullWidth
                onChange={(e) => {
                  setFilterDate({ ...filterData, keyword: e.target.value });
                }}
                value={filterData.keyword || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item sm={3} md={2} xs={6}>
              <TextField
                className={[classes.textField, classes.number]}
                label='Min Marks'
                margin='dense'
                type='number'
                fullWidth
                onKeyDown={(e) => {
                  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                }}
                onChange={(e) => {
                  setFilterDate({ ...filterData, minMarks: e.target.value });
                }}
                InputProps={{ inputProps: { min: 0 } }}
                value={filterData.minMarks || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item sm={3} md={2} xs={6}>
              <TextField
                className={[classes.textField, classes.number]}
                label='Max Marks'
                margin='dense'
                type='number'
                fullWidth
                onKeyDown={(e) => {
                  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
                }}
                onChange={(e) => {
                  setFilterDate({ ...filterData, maxMarks: e.target.value });
                }}
                InputProps={{ inputProps: { min: filterData.minMarks || 0 } }}
                value={filterData.maxMarks || ''}
                variant='outlined'
              />
            </Grid>
            <Grid item>
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={() => {
                  handleAllTemplatePagination(null, 1);
                  //   getAllTemplate(1);
                }}
              >
                Filter
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              {allTemplates && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.teblerow}>
                        <TableCell className={classes.tablecell} width={70}>
                          Sl. No.
                        </TableCell>
                        <TableCell className={classes.tablecell}>
                          Template Title
                        </TableCell>
                        <TableCell className={classes.tablecell}>Created On</TableCell>
                        <TableCell className={classes.tablecell}>Marks</TableCell>
                        <TableCell className={classes.tablecell} width={120}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allTemplates &&
                        allTemplates.map((eachTemplate, index) => {
                          return (
                            <TableRow className={classes.teblerow}>
                              <TableCell className={classes.tablecell} width={70}>
                                {index + 1}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {eachTemplate.title}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {moment(eachTemplate.created_at).format('DD.MM.YYYY')}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {eachTemplate.total_marks}
                              </TableCell>
                              <TableCell className={classes.tablecell} width={120}>
                                <Grid
                                  container
                                  direction='row'
                                  justify='center'
                                  alignItems='center'
                                  wrap='nowrap'
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Tooltip title='View'>
                                      <IconButton
                                        size='small'
                                        onClick={() => {
                                          setQuestionPreviewModalOpen({
                                            open: true,
                                            data: eachTemplate,
                                          });
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Visibility style={{ height: 18, width: 18 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title='Edit'>
                                      <IconButton
                                        size='small'
                                        onClick={() => {
                                          history.replace({
                                            pathname: '/auto-assessment/template',
                                            state: {
                                              data: {
                                                filterData: filterData,
                                                page: alltemplatePageData?.currentPage,
                                              },
                                            },
                                          });
                                          history.push({
                                            pathname: `/auto-assessment/create-template/`,
                                            state: {
                                              data: {
                                                currentStep: 1,
                                                tempTemplateFrom: JSON.stringify(
                                                  eachTemplate
                                                ),
                                                type: 'next',
                                                previousURL: window.location.pathname,
                                              },
                                            },
                                          });
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Edit style={{ height: 18, width: 18 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title='Delete'>
                                      <IconButton
                                        size='small'
                                        // onClick={() => {deleteTemplate(eachTemplate.id)}}
                                        onClick={() => {
                                          setDeleteTemplateModalOpen({
                                            open: true,
                                            data: eachTemplate.id,
                                          });
                                        }}
                                        style={{ padding: 0 }}
                                      >
                                        <Delete style={{ height: 18, width: 18 }} />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Grid container justify='center'>
                            <Pagination
                              showFirstButton
                              showLastButton
                              size='small'
                              page={alltemplatePageData.currentPage}
                              count={alltemplatePageData.totalPage}
                              onChange={(event, value) =>
                                handleAllTemplatePagination(event, value)
                              }
                            />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid> */}
            <Grid container spacing={2} style={{ marginTop: '1%', marginLeft: '2%' }}>
              {allTemplates &&
                allTemplates.length > 0 &&
                allTemplates?.map((eachTemplate, index) => {
                  // {
                  //   console.log(eachTemplate);
                  // }
                  return (
                    <Grid key={index} item lg={3} md={4} sm={6} xs={12}>
                      <div className={classes.chooseTemplateModalOptions}>
                        <div
                          type='button'
                          style={{
                            width: 190,
                            height: 175,
                            border: '1px solid black',
                            backgroundColor: 'lightblue',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: '1%',
                            boxShadow: 'inset 0 -30px 20px rgba(0, 0, 0, 0.5)',
                            // paddingBottom: '1%',
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <IconButton
                              aria-label='more'
                              aria-controls='long-menu'
                              aria-haspopup='true'
                              size='small'
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                padding: '0',
                              }}
                              onClick={(e) => {
                                handleMenuClick(e, index);
                              }}
                            >
                              <MoreVert
                                style={{ height: 25, width: 25, color: 'white' }}
                              />
                            </IconButton>
                            {menuOpenIndex === index ? (
                              <Menu
                                id='long-menu'
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {},
                                }}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleMenuClose();
                                    console.log(eachTemplate, 'click');
                                    setQuestionPreviewModalOpen({
                                      open: true,
                                      data: eachTemplate,
                                    });
                                  }}
                                >
                                  View
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleMenuClose();
                                    history.replace({
                                      pathname: '/auto-assessment/template',
                                      state: {
                                        data: {
                                          filterData: filterData,
                                          page: alltemplatePageData?.currentPage,
                                        },
                                      },
                                    });
                                    history.push({
                                      pathname: `/auto-assessment/create-template/`,
                                      state: {
                                        data: {
                                          currentStep: 1,
                                          tempTemplateFrom: JSON.stringify(eachTemplate),
                                          type: 'next',
                                          previousURL: window.location.pathname,
                                        },
                                      },
                                    });
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleMenuClose();
                                    setDeleteTemplateModalOpen({
                                      open: true,
                                      data: eachTemplate.id,
                                    });
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            ) : null}
                          </div>
                          <img
                            style={{
                              marginLeft: '25%',
                            }}
                            src={templatePreviewImage}
                            width={100}
                            height={130}
                          />
                          <Tooltip
                            title={
                              eachTemplate?.title.length > 75 ? eachTemplate?.title : ''
                            }
                            arrow
                          >
                            <Typography
                              style={{
                                color: 'white',
                                fontSize: 11,
                                padding: '4%',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                lineHeight: 1.5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {eachTemplate?.title}
                            </Typography>
                          </Tooltip>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justify: 'space-between',
                            border: '1px solid grey',
                            padding: '2%',
                            width: '190px',
                            backgroundColor: 'white',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justify: 'space-around',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justify: 'space-evenly',
                                width: '100%',
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: 11,
                                  marginRight: '3%',
                                }}
                              >
                                Created at:
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: 11,
                                }}
                              >
                                {moment(eachTemplate?.created_at).format('DD/MM/YYYY')}
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                              marginBottom: '2%',
                              marginTop: '2%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justify: 'space-evenly',
                                width: '50%',
                              }}
                            >
                              <img
                                src={DurationIcon}
                                width='14px'
                                height='14px'
                                style={{ marginRight: '5%' }}
                              />
                              <Typography
                                style={{
                                  fontSize: 11,
                                }}
                              >
                                {eachTemplate?.duration} Mins
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '50%',
                                justify: 'space-evenly',
                              }}
                            >
                              <img
                                src={QuestionCountIcon}
                                width='14px'
                                height='14px'
                                style={{ marginRight: '5%' }}
                              />
                              <Typography
                                style={{
                                  fontSize: 11,
                                }}
                              >
                                {eachTemplate?.total_questions} Qns
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justify: 'space-around',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justify: 'space-evenly',
                                width: '50%',
                              }}
                            >
                              <img
                                src={MarksIcon}
                                width='14px'
                                height='14px'
                                style={{ marginRight: '5%' }}
                              />
                              <Typography
                                style={{
                                  fontSize: 11,
                                }}
                              >
                                {eachTemplate?.total_marks} Marks
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ) */}
                      {/* } */}
                      {/* </>
                      }
                    /> */}
                    </Grid>
                  );
                })}
              {allTemplates && allTemplates.length === 0 && (
                <Grid item xs={12}>
                  <div
                    style={{
                      width: '100%',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #000',
                      borderRadius: '10px',
                    }}
                  >
                    <Description style={{ opacity: '0.5' }} />
                    <Typography variant='caption'>No Data Found</Typography>
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container justify='center' style={{ marginTop: '2%' }}>
              {allTemplates && allTemplates.length > 0 && (
                <Pagination
                  showFirstButton
                  showLastButton
                  size='small'
                  page={alltemplatePageData.currentPage}
                  count={alltemplatePageData.totalPage}
                  onChange={(event, value) => handleAllTemplatePagination(event, value)}
                />
              )}
            </Grid>
          </Grid>
        </div>
        {questionPreviewModal}
        {deleteTemplateModal}
      </Layout>
    </>
  );
};

CreateTemplate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default withStyles(styles)(CreateTemplate);
